<template>
    <div class="flex justify-between grid-items-center mt-50px mb-70px">
        <div class="info">
            <div>
                <img class="h-70px mr-50px" src="@/assets/images/pc/footer/footer-1.png" @click="openPage('https://www.pagcor.ph/index.php')" />
                <img class="h-70px" src="@/assets/images/pc/footer/footer-2.png" @click="openPage('https://www.gaming-curacao.com')" />
            </div>
            <p>{{ $t('resp1') }}</p>
        </div>
        <div class="flex">
            <img class="w-280px mr-40px" src="@/assets/images/mobile/home/pp1.webp" />
            <img class="w-280px" src="@/assets/images/mobile/home/pp2.webp" />
        </div>
    </div>

    <div class="word">
        <h5>{{ $t('resp2') }}</h5>
        <p class="whitespace-pre">{{ $t('resp3') }}</p>
    </div>

    <div class="word">
        <h5>{{ $t('resp4') }}</h5>
        <p class="whitespace-pre">{{ $t('resp5') }}</p>
    </div>

    <div class="word">
        <h5>{{ $t('resp6') }}</h5>
        <p class="whitespace-pre">{{ $t('resp7') }}</p>
    </div>
</template>

<script setup>
function openPage(target) {
    window.open(target)
}
</script>

<style lang="less" scoped>
.info {
    width: calc(100% - 680px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p{
        font-size: 16px;
        color: #97A7B6;
        line-height: 30px;
        margin-top: 50px;
    }
}
.word{
    margin-bottom: 50px;
    h5{
        font-size: 20px;
        color: #FFB25A;
        margin-bottom: 10px;
        position: relative;
        padding-left: 12px;
        &::after{
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            width: 4px;
            height: 20px;
            background: #FFB25A;
            border-radius: 2px;
        }
    }
    p{
        line-height: 30px;
        color: #97A7B6;
    }
}
</style>