<template>
    <div class="swiperBox">
        <Title :title="$t('home29')" :subTitle="$t('home30')" class="mt-54px" />

        <swiper
          direction="horizontal"
          :loop="true"
          :autoplay="{ disableOnIntercation: false, autoplay: false, delay: 2400 }"
          slidesPerView="auto"
          :spaceBetween="0"
          :centeredSlides="true"
          :modules="modules"
        >
          <swiper-slide v-for="(item, key) in list" :key="key">
            <h4>{{ item.title }}</h4>
            <p>{{ item.text }}</p>
            <div class="bot">
                <el-rate v-model="value" disabled />
                <span>{{ item.user }}</span>
            </div>
          </swiper-slide>
        </swiper>
      </div>
  </template>

<script setup>
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';
import Title from '../components/title.vue'

const modules = [Autoplay];

const { t } = useI18n();

const list = computed(() => {
    return [
        {
            title: t('home31'),
            text: t('home32'),
            "user": "min***uan1"
        },
        {
            title: t('home33'),
            text: t('home34'),
            "user": "dun***enha"
        },
        {
            title: t('home35'),
            text: t('home36'),
            "user": "bin***20"
        },
        {
            title: t('home37'),
            text: t('home38'),
            "user": "duy***"
        },
        {
            title: t('home39'),
            text: t('home40'),
            "user": "tie***ong2"
        },
        {
            title: t('home41'),
            text: t('home42'),
            "user": "lon***ong"
        },
        {
            title: t('home43'),
            text: t('home44'),
            "user": "abb***8"
        },
        {
            title: t('home45'),
            text: t('home46'),
            "user": "bio***112"
        },
        {
            title: t('home47'),
            text: t('home48'),
            "user": "anh***td"
        },
        {
            title: t('home49'),
            text: t('home50'),
            "user": "qua***"
        },
    ]
})

const value = ref(5)
</script>

<style lang="less" scoped>
.swiper-slide {
    width: 800px;
    height: 528px;
    transition: all 0.3s ease-in-out;
    background: linear-gradient(180deg,#212637, #313647);
    border-radius: 20px;
    box-shadow: 0px 6px 10px 0px rgba(0,0,0,0.16); 
    position:relative;
    h4{
        font-size: 32px;
        text-align: center;
        margin: 30px 0 20px;
    }
    p{
        font-size: 24px;
        width: 80%;
        margin: 0 auto;
        color: #97A7B6;
        line-height: 40px;
    }
    .bot{
        position: absolute;
        bottom: 40px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .el-rate{
        :deep(.el-rate__item){
            margin-right: 10px;
        }
        :deep(.el-rate__icon) {
            font-size: 40px!important;
        }
    }
    span{
        color: #97A7B6;
        font-size: 16px;
        margin-top: 60px;
    }
}
.swiper-slide-prev {
    transform: scale(0.9);
}
.swiper-slide-next {
    transform: scale(0.9);
}

</style>

