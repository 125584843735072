<template>
    <el-carousel height="340px" trigger="click">
        <el-carousel-item v-for="(item, index) in dataListFilter" :key="index">
            <img :src="item" class="w-100%" >
        </el-carousel-item>
    </el-carousel>
</template>

<script setup>
import { ref, computed } from 'vue'
import {useI18n} from "vue-i18n";

const {locale} = useI18n()

const dataListFilter = computed(() => {
  return list.value[locale.value]
});

const list = ref({
    zh_CN: [
        require("@/assets/images/pc/banner/banner-zh-1.webp"),
        require("@/assets/images/pc/banner/banner-zh-2.webp"),
        require("@/assets/images/pc/banner/banner-zh-3.webp"),
    ],
    zh_TW: [
        require("@/assets/images/pc/banner/banner-tw-1.webp"),
        require("@/assets/images/pc/banner/banner-tw-2.webp"),
        require("@/assets/images/pc/banner/banner-tw-3.webp"),
    ],
    en_US: [
        require("@/assets/images/pc/banner/banner-en-1.webp"),
        require("@/assets/images/pc/banner/banner-en-2.webp"),
        require("@/assets/images/pc/banner/banner-en-3.webp"),
    ],
    en_MAS: [
        require("@/assets/images/pc/banner/banner-mas-1.webp"),
        require("@/assets/images/pc/banner/banner-mas-2.webp"),
        require("@/assets/images/pc/banner/banner-mas-3.webp"),
    ],
    vi_VN: [
        require("@/assets/images/pc/banner/banner-vn-1.webp"),
        require("@/assets/images/pc/banner/banner-vn-2.webp"),
        require("@/assets/images/pc/banner/banner-vn-3.webp"),
    ],
})
</script>

<style lang="less" scoped>
:deep(.el-carousel__button){
    width: 35px;
    height: 10px;
    border-radius: 5px;
    background: linear-gradient(180deg,#ffffff, #b6cbdd 48%, #f1f5f9 82%, #f9fbfd);
    box-shadow: 0px 2px 2px 0px rgba(34,115,230,0.29); 
}
:deep(.el-carousel__indicator.is-active button) {
    background: linear-gradient(180deg,#f9f9f9, #3f98ff 48%, #8cd2ff 82%, #8cd2ff);
}
</style>