<template>
  <div class="bg-white">
    <template v-for="(value,key) in dataList" :key="key">
      <div v-if="key === 'part_header'" class="relative">
        <img src="@/assets/images/mobile/charity/header.webp" class="w-full"/>
        <img src="@/assets/images/mobile/charity/love.svg" class="absolute right-20px bottom-0 translate-y-80%"/>
        <div class="absolute left-20px top-50% -translate-y-50%">
          <img src="@/assets/images/mobile/charity/platform_logo.webp" class="h-22px"/>
          <div class="text-white text-22px whitespace-pre">{{ t('charity1') }}</div>
        </div>
      </div>
      <div v-else-if="key === 'part_video'" class="mt-10px px-20px -mb-150px">
        <div class="text-16px text-#14151A whitespace-pre font-bold">{{ value.title }}</div>
        <div class="text-12px text-#0A0D12 mt-10px leading-18px">{{ value.desc }}</div>
        <video :src="activeVideo.video" :poster="activeVideo.poster" playsinline
               preload="metadata" :autoplay="true" :muted="true"
               controls class="w-full rounded-10px justify-between my-10px">
        </video>
        <div class="flex justify-between w-full items-center">
          <div v-for="(sub_item,index) in value.dataList" class="basis-[31.5%]" :key="index">
            <div class="relative w-full h-fit">
              <img :src="sub_item.poster"
                   @click="activeVideo = sub_item" class="w-full transition"
                   :class="{'scale-110':activeVideo.poster === sub_item.poster}"/>
              <img src="@/assets/images/mobile/home/play_btn.png"
                   class="absolute top-50% left-50% -translate-x-50% -translate-y-50% pointer-events-none"/>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="key === 'part_help'" class="bg-#F5F8FF  text-center pt-150px">
        <div class="px-20px">
          <img src="@/assets/images/mobile/charity/platform_logo2.webp" class="h-30px mt-40px"/>
          <div class="mt-22px text-#0A0D12 text-16px">{{ value.title }}</div>
          <div class="mt-10px text-#707070 text-14px">{{ value.desc }}</div>
          <div class="h-2px w-30px bg-#75C531 mx-auto mt-10px"></div>
          <div class="flex justify-around mt-31px">
            <div v-for="(sub_item ,index) in value.dataList" :key=index class="basis-[32%]">
              <img :src="sub_item.img" class="h-30px"/>
              <div class="mt-12px text-#103BAC font-bold">{{ sub_item.name }}</div>
              <div class="h-1px w-8px bg-#0A0D12 mx-auto my-5px"></div>
            </div>
          </div>
          <div class="mt-20px text-#0A0D12 text-12px">{{ value.content }}</div>
        </div>
        <div class="relative mt-155px">
          <img src="@/assets/images/mobile/charity/tree.webp" class="w-full"/>
          <div class="flex justify-between mt-30px px-20px -translate-y-50% absolute top-0">
            <div v-for="(sub_item,index) in value.dataList2" :key="index" class="bg-white basis-49% text-left relative">
              <img :src="sub_item.img" class="w-full"/>
              <img :src="sub_item.img2" class="-translate-y-50%"/>
              <div class="px-10px min-h-110px text-12px text-#0a0d12 leading-20px">{{ sub_item.name }}</div>
              <div class="bg-#F9A51A h-6px w-full absolute -bottom-6px">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="key === 'part_photo'" class="px-20px pb-50px bg-#F5F8FF">
        <div class="flex justify-between pt-30px">
          <div class="w-150px">
            <img src="@/assets/images/mobile/charity/platform_logo2.webp" class="h-20px"/>
            <div class="mt-20px">{{ value.title }}</div>
          </div>
          <img src="@/assets/images/mobile/charity/c_o1.svg" class="h-110px"/>
        </div>
        <div class="flex flex-wrap justify-between max-h-382px overflow-auto mt-50px">
          <div v-for="index in 15" :key="index" class="basis-31.5%">
            <img :src="require(`@/assets/images/mobile/charity/d2_${index}.png`)" class="w-full mt-10px"/>
          </div>
        </div>
      </div>
      <div v-else-if="key === 'part_target'" class="text-center px-20px mb-57px">
        <div class="-translate-y-50% ">
          <img src="@/assets/images/mobile/charity/love.svg"/>
        </div>
        <div class="text-#75C531 mt-15px text-16px">{{ value.title }}</div>
        <div class="text-#707070 mt-14px">{{ value.desc }}</div>
        <div class="flex flex-wrap justify-between mt-42px">
          <img :src="sub_item" v-for="(sub_item,index) in value.dataList" :key="index" class="h-116px mt-10px"/>
        </div>
      </div>
      <div v-else-if="key === 'part_sponsor'"
           :style="{backgroundImage:`url(${require('@/assets/images/mobile/charity/bg.svg')})`}" class="text-center bg-no-repeat bg-cover">
        <img src="@/assets/images/mobile/charity/platform_logo2.webp" class="h-24px mt-50px"/>
        <div class="whitespace-pre-line mt-30px px-20px">{{ $t('charity15') }}</div>
        <div class="flex justify-center mt-26px">
          <img src="@/assets/images/mobile/charity/team1.webp" class="mr-20px w-46px"/>
          <div>
            <div>Valencia CF</div>
            <div class="text-#707070 text-12px">{{ value.desc }}</div>
          </div>
        </div>
        <img src="@/assets/images/mobile/charity/team2.webp" class="w-full px-47px block"/>
      </div>
    </template>
  </div>
</template>
<script setup>
import {useI18n} from "vue-i18n";
import {computed, onMounted, ref} from "vue";

const {t} = useI18n()
const activeVideo = ref({})
const dataList = computed(() => {
  return {
    part_header: {},
    part_video: {
      title: t('charity2'),
      desc: t('charity3'),
      dataList: [
        {
          poster: require('@/assets/images/mobile/home/chat3.webp'),
          video: 'https://pic.li3zq.com/video/public-spirited2.mp4?t_' + new Date().getTime()
        },
        {
          poster: require('@/assets/images/mobile/home/chat1.webp'),
          video: 'https://pic.li3zq.com/video/public-spirited3.mp4?t_' + new Date().getTime()
        },
        {
          poster: require('@/assets/images/mobile/home/chat2.webp'),
          video: 'https://pic.li3zq.com/video/public-spirited1.mp4?t_' + new Date().getTime()
        },
      ]
    },
    part_help: {
      title: t('charity4'),
      desc: t('charity5'),
      dataList: [
        {name: t('charity6'), img: require('@/assets/images/mobile/charity/c_logo1.webp')},
        {name: t('charity7'), img: require('@/assets/images/mobile/charity/c_logo2.webp')},
        {name: t('charity8'), img: require('@/assets/images/mobile/charity/c_logo3.webp')},
      ],
      content: t('charity9'),
      dataList2: [
        {
          name: t('charity10'),
          img: require('@/assets/images/mobile/charity/c_detail1.webp'),
          img2: require('@/assets/images/mobile/charity/c_detail_logo1.svg')
        },
        {
          name: t('charity11'),
          img: require('@/assets/images/mobile/charity/c_detail2.webp'),
          img2: require('@/assets/images/mobile/charity/c_detail_logo2.svg')
        },
      ]
    },
    part_photo: {
      title: t('charity12'),
      dataList: [
        require('@/assets/images/mobile/charity/c_logo1.webp')
      ]
    },
    part_target: {
      title: t('charity13'),
      desc: t('charity14'),
      dataList: [
        require('@/assets/images/mobile/charity/t1.png'),
        require('@/assets/images/mobile/charity/t2.png'),
        require('@/assets/images/mobile/charity/t3.png'),
        require('@/assets/images/mobile/charity/t4.png'),
      ]
    },
    part_sponsor: {
      title: t('charity15'),
      desc: t('charity16')
    }
  }
})
onMounted(() => {
  activeVideo.value = dataList.value.part_video.dataList[0]
})
</script>
