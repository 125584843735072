import {createApp} from 'vue'
import App from './App.vue'
import i18n from "@/script/i18n";
import ElementPlus from 'element-plus'
import VueLazyload from "vue-lazyload";
import 'vue-fullpage.js/dist/style.css'
import VueFullPage from 'vue-fullpage.js'
import 'uno.css'

createApp(App).use(VueLazyload).use(VueFullPage).use(ElementPlus).use(i18n).mount('#app')
// import VConsole from "vconsole";
// import { initPlugin } from 'vue-vconsole-devtools'
// initPlugin(new VConsole()); // 需要在创建Vue根实例前调用
