<template>
    <div class="bg">
        <div class="top">
            <img class="img cursor-pointer" src="@/assets/images/pc/footer/footer-1.png" @click="openPage('https://www.pagcor.ph/index.php')" />
            <img class="img cursor-pointer" src="@/assets/images/pc/footer/footer-2.png" @click="openPage('https://www.gaming-curacao.com')" />
            <img class="img" src="@/assets/images/pc/footer/footer-3.png" />
            <img class="img" src="@/assets/images/pc/footer/footer-4.png" />
        </div>

        <p>{{ $t('footer1') }}</p>
        <p>{{ $t('footer2') }}</p>
        <p>{{ $t('footer3') }}</p>

        <el-divider />

        <p class="!color-#fff">{{ $t('footer4') }}</p>
    </div>
</template>

<script setup>
function openPage(target) {
    window.open(target)
}
</script>

<style lang="less" scoped>
.bg{
    width: 100%;
    height: 352px;
    background-image: url('@/assets/images/pc/footer/footer-bg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
}
.top {
    display: flex;
    justify-content: center;
    padding-top: 62px;
    margin-bottom: 40px;
    .img{
        width: 140px;
        margin-right: 68px;
        &:last-child{
            margin-right: 0;
        }
    }
}
p{
    line-height: 28px;
    text-align: center;
    color: #97A7B6;
    font-size: 14px;
    margin: 0
}

.el-divider--horizontal{
    background-color: #97a7b60f;
    border-top-color: #97a7b600;
}
</style>