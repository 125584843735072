<template>
  <div class="app-bg color-#fff">
    <div class="header sticky top-0">
      <div class="inner">
        <img src="@/assets/images/pc/logo.png" class="logo">
        <div
            class="nav"
            v-for="item in tabs"
            :key="item.value"
            :class="{'active': item.value === activeTab?.value}"
            @click="handleTab(item)">
          {{ item.label }}
        </div>
        <LangItem/>
      </div>
    </div>
    <div class="mx-auto w-1350px !h-[calc(100%-80px)]"
         :class="{'!w-100%': activeTab?.value === 'sponsor' || activeTab?.value === 'charity'}">
      <component :is="activeTab?.component"/>
    </div>
    <Footer :class="{'mt-100px': activeTab?.value !== 'charity'}" v-if="activeTab?.value !== 'sponsor'"/>
  </div>
</template>
<script setup>
import 'element-plus/dist/index.css'
import {ref, computed, onMounted} from "vue";
import {useI18n} from "vue-i18n";
import LangItem from './components/lang-item.vue'
import Home from './home/index.vue'
import PlatformOverview from './home/components/platform-overview.vue'
import Charity from './charity/index.vue'
import PolybasicGame from './home/components/polybasic-game.vue'
import Sponsor from './sponsor/index.vue'
import Discount from './discount/index.vue'
import Response from './response/index.vue'
import UserExperience from './UserExperience.vue'
import Footer from './footer/index.vue'
import qs from "qs";

const {t} = useI18n()

const tabs = computed(() => [
  {
    label: t('mobile2'),
    value: 'home',
    component: Home
  },
  {
    label: t('pc3'),
    value: 'platform',
    component: PlatformOverview
  },
  {
    label: t('pc4'),
    value: 'sponsor',
    component: Sponsor
  },
  {
    label: t('pc5'),
    value: 'charity',
    component: Charity
  },
  {
    label: t('pc6'),
    value: 'discount',
    component: Discount
  },
  {
    label: t('pc7'),
    value: 'game',
    component: PolybasicGame
  },
  {
    label: t('pc8'),
    value: 'response',
    component: Response
  },
  {
    label: t('pc9'),
    value: 'serve',
    component: UserExperience
  }
])
const activeTab = ref(tabs.value[0])

const handleTab = (item) => {
  activeTab.value = item
  document.getElementById('app').scrollTop = 0
}

onMounted(() => {
 let queryData =  qs.parse(window.location.search.substring(1));
  const page = queryData.target
  if (page) {
    activeTab.value = tabs.value.find(item => item.value === page)
  }
})
</script>
<style lang="less" scoped>
.app-bg {
  background-color: #171C28;
  position: initial !important;
  height: initial !important;
}

.header {
  width: 100%;
  height: 80px;
  z-index: 22;
  top: 0;
  background: linear-gradient(180deg, #2a3467, #1d2448 88%, #252e5b);

  .inner {
    width: 1350px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo {
      height: 38px;
    }

    .nav {
      height: 100%;
      line-height: 80px;
      color: #fff;
      font-size: 18px;
      cursor: pointer;
      text-transform: uppercase;

      &.active {
        color: #F9A51A;
        position: relative;

        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 100%;
          height: 6px;
          background-image: url("@/assets/images/pc/header/nav-hover.png");
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
        }
      }
    }
  }
}

</style>
<style>
@import url("//unpkg.com/element-ui@2.9.1/lib/theme-chalk/index.css");

.fp-watermark {
  display: none;
}
</style>
