import {createI18n} from 'vue-i18n'
import qs from "qs";
import {Locale} from "vant";
import zhCN from '@/assets/lang/zh.js'
import enUS from '@/assets/lang/en.js'
import enMS from '@/assets/lang/ms.js'
import zhTW from '@/assets/lang/tw.js'
import viVN from '@/assets/lang/vn.js'

const message = {
    zh_CN: {...zhCN},
    en_US: {...enUS},
    zh_TW: {...zhTW},
    en_MAS: {...enMS},
    vi_VN: {...viVN},
}

let parse = qs.parse(window.location.search.substring(1));
let lang = parse.lang;
if (!lang) lang = getNavigatorLocale()

const i18n = createI18n({
    locale: lang, // 设置语言类型
    legacy: false, // 如果要支持compositionAPI，此项必须设置为false;
    globalInjection: true, // 全局注册$t方法
    messages: message,
})

const messages = {
    'zh-CN': {
        vanPicker: {
            confirm: '关闭', // 将'确认'修改为'关闭'
        },
    },
};

Locale.add(messages);

function getNavigatorLocale() {
    let lang;
    if (navigator.language === 'zh' || navigator.language === 'zh-CN' || navigator.language === 'zh-SG' || navigator.language === 'zh-MO' || navigator.language === 'zh-Hant') {
        lang = 'zh_CN';
    } else if (navigator.language === 'zh-HK' || navigator.language === 'zh-TW') {
        lang = 'zh_TW'
    } else if (navigator.language === 'ms' || navigator.language === 'mg' || navigator.language === 'ms-MY' || navigator.language === 'ms-BN') {
        lang = 'en_MAS'
    } else if (navigator.language === 'vi' || navigator.language === 'vi-VN') {
        lang = 'vi_VN'
    } else {
        lang = 'en_US';
    }
    return lang;
}

export default i18n
