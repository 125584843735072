<template>
  <div class="overflow-auto flex nav-container  scroll-smooth" ref="navContainer">
    <div v-for="(item,index) in gameListArr" :key="index"
         class="nav-item flex flex-col flex-shrink-0 justify-center items-center bg-[var(--masPrimary)] w-[56px] h-[60px] rounded-[8px] my-[10px] bg-#1B1E2F"
         @click="itemClick(item,$event.currentTarget)" :class="{active: activeTab === item.id}"
         ref="tabItem">
      <img :src="activeTab === item.id ? item.imageActive: item.image" class="icon w-[30px] my-[0] m-auto"/>
      <div class="text-white text-center text-12px whitespace-nowrap">
        {{ item.name }}
      </div>
    </div>
  </div>
</template>
<script setup>
import {useI18n} from "vue-i18n";
import {computed, ref} from "vue";

const activeTab = ref(2)
const {t} = useI18n()
const navContainer = ref(null)
const gameListArr = computed(()=>[
  {
    id: 0, name: t('game4'),
    image: require('@/assets/images/mobile/game/game_sport.svg'),
    imageActive: require('@/assets/images/mobile/game/game_sport_active.webp'),
  },
  {
    id: 1, name: t('game5'),
    image: require('@/assets/images/mobile/game/game_casino.svg'),
    imageActive: require('@/assets/images/mobile/game/game_casino_active.webp'),
  },
  {
    id: 2, name: t('game6'),
    image: require('@/assets/images/mobile/game/game_esport.svg'),
    imageActive: require('@/assets/images/mobile/game/game_esport_active.webp'),
  },
  {
    id: 3, name: t('game7'),
    image: require('@/assets/images/mobile/game/game_slot.svg'),
    imageActive: require('@/assets/images/mobile/game/game_slot_active.webp'),
  },
  {
    id: 4, name: t('game8'),
    image: require('@/assets/images/mobile/game/game_lottery.svg'),
    imageActive: require('@/assets/images/mobile/game/game_lottery_active.webp'),
  },
  {
    id: 5, name: t('game9'),
    image: require('@/assets/images/mobile/game/game_fish.svg'),
    imageActive: require('@/assets/images/mobile/game/game_fish_active.webp'),
  },
  {
    id: 6, name: t('game10'),
    image: require('@/assets/images/mobile/game/game_chess.svg'),
    imageActive: require('@/assets/images/mobile/game/game_chess_active.webp'),
  }
])
const emit = defineEmits(['change'])

function itemClick(sendItem, item) {
  if (item) {
    let container = navContainer;
    var itemOffsetLeft = item.offsetLeft;
    var centerLeft = (container.value.offsetWidth - item.offsetWidth) / 2;
    let res = itemOffsetLeft - centerLeft;
    activeTab.value = sendItem.id;
    container.value.scrollLeft = res;
  }
  emit('change', sendItem.id)
}

</script>
<style lang="less" scoped>
.nav-container {
  overflow-scrolling: touch;

  .nav-item {
    & + .nav-item {
      margin-left: 7px;
    }
  }

  .active {
    background-color: #462FCD;

    .icon {
      animation: dynamic-scale 0.3s ease-in-out;
      //animation-delay: 200ms;
    }
  }
}
</style>
