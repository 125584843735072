<template>
    <Title :title="$t('mobile3')" :subTitle="$t('home6')" class="mb-20px" style="margin-top: 2%;" />

    <div class="box">
        <swiper :pagination="{clickable: true}" :modules="modules" class="mySwiper">
            <swiper-slide>
                <div class="flex grid-items-center">
                    <img src="@/assets/images/pc/home/platform-overview-1.webp" class="w-620px -mt-70px">
                    <div class="word">
                        <h4>{{ $t('home7') }}</h4>
                        <p>{{ $t('platform2') }}</p>
                    </div>
                </div>
            </swiper-slide>
            <swiper-slide>
                <div class="flex grid-items-center">
                    <img src="@/assets/images/pc/home/platform-overview-2.webp" class="w-620px -mt-70px">
                    <div class="word">
                        <h4>{{ $t('platform3') }}</h4>
                        <p>{{ $t('platform4') }}</p>
                    </div>
                </div>
            </swiper-slide>
            <swiper-slide>
                <div class="flex grid-items-center">
                    <img src="@/assets/images/pc/home/platform-overview-3.webp" class="w-620px -mt-70px">
                    <div class="word">
                        <h4>{{ $t('home11') }}</h4>
                        <p>{{ $t('platform5') }}</p>
                    </div>
                </div>
            </swiper-slide>
        </swiper>
    </div>
</template>

<script setup>
import Title from '../../components/title.vue'
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';

const modules = [Pagination];
</script>

<style lang="less" scoped>
.box{
    overflow: hidden;
    padding-bottom: 34px;
    .swiper{
        background: linear-gradient(180deg,#212637, #313647);
        border-radius: 20px;
        overflow: initial;
        margin-top: 70px;
    }
    :deep(.swiper-pagination){
        bottom: -32px;
        .swiper-pagination-bullet{
            width: 40px;
            height: 10px;
            background: linear-gradient(180deg,#616d87, #97a7b6 45%, #363f61);
            border-radius: 5px;
            box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.09); 
            opacity: 1;
        }
        .swiper-pagination-bullet-active{
            background: linear-gradient(180deg,#609fff, #3b48ff 48%, #408bf6 82%, #2970d6);
        }
    }
    .word{
        width: calc(100% - 620px);
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 60px;
        h4{
            font-size: 22px;
            margin-bottom: 20px;
        }
        p{
            font-size: 16px;
            line-height: 24px;
            width: 100%;
            white-space: break-spaces;
        }
    }
}
</style>