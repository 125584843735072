<template>
    <div class="bg-#fff color-#000" :class="lang">
        <div class="relative">
            <img src="@/assets/images/pc/charity/banner.webp" class="w-100%" />
            <div class="banner-title">
                <img src="@/assets/images/pc/logo.png" class="w-266px" />
                <h5 class="font-size-40px color-#fff mt-10px">{{ $t('charity18') }}</h5>
            </div>
        </div>

        <div class="mt-30px pb-30px relative">
            <div class="inner relative">
                <img src="@/assets/images/pc/charity/love.png" class="w-100px absolute -top-100px right-120px" />
                <h2 class="font-size-34px color-#14151A mb-30px title-1">{{ $t('charity17') }}</h2>
                <p class="font-size-18px mb-30px subtitle-1">{{ $t('charity3') }}</p>
                <CharitySwiper />
            </div>
            <img src="@/assets/images/pc/charity/element-1.png" class="w-130px absolute -bottom-10px left-0 z-1" />
        </div>

        <div class="bg-#F5F8FF pt-34px pb-120px bg-1 relative">
            <div class="inner text-center">
                <img src="@/assets/images/pc/logo-2.png" class="w-190px mb-30px" />
                <h2 class="font-size-36px font-bold mb-22px">{{ $t('charity4') }}</h2>
                <p class="font-size-18px color-#707070 mb-42px">{{ $t('charity5') }}</p>
                <div class="w-70px h-1px bg-#75C531 mx-auto mb-70px"></div>

                <div class="contribute-col">
                    <div>
                        <img src="@/assets/images/pc/charity/contribute-1.png" class="w-105px" />
                        <p class="font-size-36px color-#103BAC font-bold mb-30px mt-10px">{{  $t('charity6') }}</p>
                        <p class="font-size-18px">—</p>
                    </div>
                    <div>
                        <img src="@/assets/images/pc/charity/contribute-2.png" class="w-105px" />
                        <p class="font-size-36px color-#103BAC font-bold mb-30px mt-10px">{{  $t('charity7') }}</p>
                        <p class="font-size-18px">—</p>
                    </div>
                    <div>
                        <img src="@/assets/images/pc/charity/contribute-3.png" class="w-105px" />
                        <p class="font-size-36px color-#103BAC font-bold mb-30px mt-10px">{{  $t('charity8') }}</p>
                        <p class="font-size-18px">—</p>
                    </div>
                </div>

                <p class="font-size-18px my-60px">{{ $t('charity9') }}</p>

                <div class="contribute-crad">
                    <div class="mr-56px">
                        <img src="@/assets/images/pc/charity/img-1.webp" class="w-100%" />
                        <img class="icon" src="@/assets/images/pc/charity/contribute-4.png" />
                        <p>
                            {{ $t('charity10') }}
                        </p>
                    </div>
                    <div>
                        <img src="@/assets/images/pc/charity/img-2.webp" class="w-100%" />
                        <img class="icon" src="@/assets/images/pc/charity/contribute-5.png" />
                        <p>
                            {{ $t('charity11') }}
                        </p>
                    </div>
                </div>
            </div>

            <img src="@/assets/images/pc/charity/element-2.png" class="w-130px absolute -bottom-90px right-0 z-1" />
        </div>

        <div class="bg-#F5F8FF pt-78px pb-120px relative">
            <div class="w-990px mx-auto text-center">
                <div class="flex grid-items-center justify-between mb-100px">
                    <div class="flex flex-col">
                        <img src="@/assets/images/pc/logo-2.png" class="w-266px mb-50px" />
                        <p class="text-18px text-left leading-24px">{{ $t('charity12') }}</p>
                    </div>
                    <img src="@/assets/images/pc/charity/contribute-6.png" class="w-440px" />
                </div>
                <div class="flex flex-wrap justify-between charity-list">
                    <div v-for="item in 24" :key="item">
                        <img class="w-320px mb-10px" :src="require('@/assets/images/pc/charity/charity-' + item + '.webp')" >
                    </div>
                </div>
            </div>

            <img src="@/assets/images/pc/charity/element-1.png" class="w-130px absolute top-250px left-0 z-1" />
            <img src="@/assets/images/pc/charity/element-2.png" class="w-130px absolute -bottom-150px right-0 z-1" />
        </div>

        <div class="text-center pt-90px pb-170px relative">
            <div class="inner">
                <img src="@/assets/images/pc/charity/love.png" class="w-90px" />
                <h2 class="font-size-36px color-#75C531 mt-47px">{{ $t('charity13') }}</h2>
                <p class="w-884px color-#707070 font-size-18px mt-20px mb-60px mx-auto line-height-34px">{{ $t('charity14') }}</p>

                <div class="flex mb-26px mx-auto justify-center">
                    <img src="@/assets/images/pc/charity/img-3.webp" class="h-310px mr-26px" />
                    <img src="@/assets/images/pc/charity/img-4.webp" class="h-310px" />
                </div>
                <div class="flex mx-auto justify-center">
                    <img src="@/assets/images/pc/charity/img-5.webp" class="h-310px mr-26px" />
                    <img src="@/assets/images/pc/charity/img-6.webp" class="h-310px" />
                </div>
            </div>

            <img src="@/assets/images/pc/charity/element-1.png" class="w-130px absolute top-43px left-0 z-1" />
        </div>

        <div class="cooperate relative">
            <img src="@/assets/images/pc/logo-2.png" class="w-376px mb-80px" />
            <p class="font-size-28px color-#707070 line-height-40px whitespace-pre-line">{{ $t('charity15') }}</p>

            <div class="flex grid-items-center justify-center mt-90px">
                <img src="@/assets/images/team1.webp" class="w-120px mr-40px" />
                <div>
                    <p class="font-size-40px font-bold">Valencia CF</p>
                    <p class="font-size-30px color-#707070">{{ $t('charity16') }}</p>
                </div>
            </div>

            <img src="@/assets/images/team2.webp" class="w-748px mt-50px -mb-10px" />

            <img src="@/assets/images/pc/charity/element-1.png" class="w-130px absolute -bottom-100px left-0" />
        </div>
    </div>
</template>

<script setup>
import CharitySwiper from './components/charity-swiper.vue'
import {useI18n} from "vue-i18n";
import { computed } from 'vue';

const {locale} = useI18n()

const lang = computed(() => {
    return locale.value
})
</script>

<style lang="less" scoped>
.inner{
    width: 1200px;
    margin: 0 auto;
}
.banner-title {
    position: absolute;
    bottom: 25%;
    left: 50%;
    transform: translateX(-50%);
    width: 1200px;
}
.contribute-col {
    display: flex;
    justify-content: center;
    > div{
        width: 30%;
    }
    > div:nth-child(2){
        margin: 0 1%;
    }
}
.contribute-crad{
    display: flex;
    justify-content: center;
    div {
        width: 414px;
        background-color: #fff;
        position: relative;
        border-bottom: 16px #F9A51A solid;
        .icon{
            width: 105px;
            top: 300px;
            left: 0;
            position: absolute;
        }
        p{
            padding: 0 30px;
            margin: 90px 0 66px;
            text-align: left;
            font-size: 18px;
            line-height: 24px
        }
    }
}

.bg-1{
    background-image: url("@/assets/images/pc/charity/bg-1.webp");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center bottom;
}

.cooperate{
    background-image: url("@/assets/images/pc/charity/bg-2.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center bottom;
    padding-top: 130px;
    text-align: center;
}

.charity-list{
    max-height: 885px;
    overflow-y: auto;
    position: relative;
    .play{
        width: 36px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.zh_CN .title-1,
.zh_TW .title-1,
.zh_CN .subtitle-1,
.zh_TW .subtitle-1{
    padding-left: 80px;
}
</style>
