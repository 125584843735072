<template>
  <div>
    <home-title :desc="part_about.desc" :title="part_about.title" class="pt-14px"></home-title>
    <div v-for="(sub_item,index) in part_about.dataList" :key="index"
         class="flex justify-between text-white text-center bg-#1B1E2F pr-10px items-center my-23px rounded-6px">
      <img :src="sub_item.img" class="w-150px mr-8px"/>
      <div class="flex flex-col">
        <div class="text-13px pt-5px">{{ sub_item.name }}</div>
        <div class="text-11px mt-10px text-#97A7B6 line-clamp-5 leading-16px">{{ sub_item.desc }}</div>
        <van-button @click="showDetail=true;activeItem=sub_item"
                    class="bg-#3B48FF border-0 text-white px-20px py-3px rounded-full h-24px ml-auto mt-5px">
          {{ t('platform1') }}
        </van-button>
      </div>
    </div>
    <!-- 圆角弹窗（居中） -->
    <van-popup v-model:show="showDetail" class="bg-#1B1E2F overflow-y-initial rounded-10px -translate-y-40%">
      <img :src="activeItem.imgAbout" class="h-183px absolute -top-73px pointer-events-none"/>
      <div class="pt-73px px-20px">
        <div class="w-fit ml-auto mr-10px">
          <div class="text-white w-fit ml-auto">{{ activeItem.name }}</div>
          <div class="bg-#3B48FF h-7px w-96px mt-3px"></div>
        </div>
        <div class="max-h-40vh my-23px text-11px text-#97A7B6 leading-22px overflow-auto">{{ activeItem.content }}</div>
      </div>
    </van-popup>
  </div>
</template>
<script setup>
import {computed, ref} from "vue";
import {Button as VanButton, Popup as VanPopup} from "vant";
import HomeTitle from "@/views/mobile/HomeTitle.vue";
import {useI18n} from "vue-i18n";

const {t} = useI18n()
const showDetail = ref(false)
const activeItem = ref({})
const part_about = computed(() => {
  return {
    title: t('mobile3'),
    desc: t('home6'),
    dataList: [
      {
        name: t('home7'),
        desc: t('home8'),
        content: t('platform2'),
        img: require('@/assets/images/mobile/home/about1.webp'),
        imgAbout: require('@/assets/images/mobile/home/about_in_1.webp')
      },
      {
        name: t('platform3'),
        desc: t('home10'),
        content: t('platform4'),
        img: require('@/assets/images/mobile/home/about2.webp'),
        imgAbout: require('@/assets/images/mobile/home/about_in_2.webp')
      },
      {
        name: t('home11'),
        desc: t('home12'),
        content: t('platform5'),
        img: require('@/assets/images/mobile/home/about3.webp'),
        imgAbout: require('@/assets/images/mobile/home/about_in_3.webp')
      },
    ]
  }
})
</script>
