<template>
    <div class="content">
        <img 
            v-for="item in 11" 
            :key="item" 
            :src="require(`@/assets/images/pc/discount/${lang}-${item}.webp`)" >
    </div>
</template>

<script setup>
import {computed} from "vue";
import {useI18n} from "vue-i18n";

const {locale} = useI18n()

const lang = computed(() => {
    return locale.value
})
</script>

<style lang="less" scoped>
.content{
    width: 1350px;
    margin: 0 auto;
    padding-top: 50px;
    img{
        width: 100%;
        margin-bottom: 20px;
        margin-top: -3px;
    }
}
</style>