<template>
  <van-popover v-model:show="showLang" theme="dark" :actions="actions" placement="bottom-end" @select="onSelect">
    <template #reference>
      <div class="flex items-center">
        <div class="text-white mr-5px">Language</div>
        <van-icon name="arrow-down" class="!text-#97A7B6 transition" :class="{'-rotate-180':showLang}"/>
      </div>
    </template>
  </van-popover>
</template>
<script setup>
import {Popover as VanPopover, Icon as VanIcon} from 'vant'
import {ref} from "vue";
import {useI18n} from "vue-i18n";

const showLang = ref(false)
const actions = [
  {text: '🇨🇳 中文', value: 'zh_CN'},
  {text: '🇨🇳 繁体', value: 'zh_TW'},
  {text: '🇺🇸 English', value: 'en_US'},
  {text: '🇲🇾 Malay', value: 'en_MAS'},
  {text: '🇻🇳 Tiếng Việt', value: 'vi_VN'},
];
const {locale} = useI18n()
const onSelect = (action) => {
  locale.value = action.value;
  console.log(action)
};
</script>
