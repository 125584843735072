<template>
  <div>
    <template v-for="(value,key) in dataList" :key="key">
      <div class="flex overflow-auto w-full">
        <div class="flex justify-evenly w-full mb-13px mt-23px" :class="{'flex-col':key===1}">
          <img v-for="(banner_item,index) in value.imgList" :key="index" :src="banner_item.img"
               :class="banner_item.class" @click="doOpen(banner_item.link)"/>
        </div>
      </div>
      <div class="text-#97A7B6 px-20px py-10px text-12px rounded-10px leading-22px"
           v-for="(text_item,index) in value.contentList" :key="index">
        <div class="text-#FFB25A">{{ text_item.title }}</div>
        <div>{{ text_item.content }}</div>
      </div>
    </template>
  </div>
</template>
<script setup>
import {useI18n} from "vue-i18n";
import {computed} from "vue";

function doOpen(link) {
  if(link){
    window.open(link)
  }
}

const {t} = useI18n()
const dataList = computed(() => [{
  imgList: [
    {link: 'https://www.pagcor.ph/index.php', img: require('@/assets/images/mobile/home/p1.webp'), class: 'h-40px'},
    {link: 'https://www.gaming-curacao.com/', img: require('@/assets/images/mobile/home/p2.webp'), class: 'h-40px'},
  ],
  contentList: [
    {
      title: '',
      content: t('resp1')
    }],
},
  {
    contentList: [
      {
        title: t('resp2'),
        content: t('resp3')
      },
      {
        title: t('resp4'),
        content: t('resp5')
      },
      {
        title: t('resp6'),
        content: t('resp7')
      }
    ],
    imgList: [
      {link: '', img: require('@/assets/images/mobile/home/pp1.webp'), class: 'px-40px'},
      {link: '', img: require('@/assets/images/mobile/home/pp2.webp'), class: 'px-40px mt-20px'},
    ],
  }
])


</script>
