<template>
    <Title :title="$t('mobile5')" :subTitle="$t('home4')" class="mt-54px" />

    <div class="box">
        <div class="flex justify-around relative">
            <!-- <img src="@/assets/images/pc/icon-arrowLeft.png" class="icon icon-left" @click="handleVideo(-1)">
            <img src="@/assets/images/pc/icon-arrowRight.png" class="icon icon-right" @click="handleVideo(1)"> -->
            <video 
                :key="activeVideo.poster"
                :src="activeVideo.video" 
                :poster="activeVideo.poster" 
                playsinline
                preload="metadata" 
                :autoplay="true" 
                :muted="true"
                controls 
                class="w-934px b-rd-18px">
            </video>
            <div class="video-list">
                <div v-for="(item, index) in videoList" :key="index" class="mb-16px relative" :class="{'active': activeVideo.value === item.value}" @click="activeVideo = item">
                    <img :src="item.poster" class="w-100%">
                    <div class="play"></div>
                </div>
            </div>
        </div>
        <swiper
            :slidesPerView="3"
            :spaceBetween="20"
            :navigation="true"
            :pagination="{
                clickable: true,
            }"
            :modules="modules"
            class="mySwiper charity-swiper">
            <swiper-slide v-for="item in 24" :key="item">
                <img class="w-400px" :src="require('@/assets/images/pc/charity/charity-' + item + '.webp')" >
                <p class="mt-6px mb-0 text-center">{{ charityList[item] || '' }}</p>
            </swiper-slide>
        </swiper>
    </div>
</template>

<script setup>
import {computed, ref} from "vue";
import {useI18n} from "vue-i18n";
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import Title from '../../components/title.vue'

const modules = [Navigation];

const {t} = useI18n()
const videoList = ref([
    {
        poster: require('@/assets/images/pc/charity/charity-16.webp'),
        video: 'https://pic.li3zq.com/video/public-spirited2.mp4',
        value: '1'
    },
    {
        poster: require('@/assets/images/pc/charity/charity-24.webp'),
        video: 'https://pic.li3zq.com/video/public-spirited3.mp4',
        value: '2'
    },
    {
        poster: require('@/assets/images/pc/charity/charity-15.webp'),
        video: 'https://pic.li3zq.com/video/public-spirited1.mp4',
        value: '3'
    },
])
const videoNum = ref(0)
const activeVideo = ref(videoList.value[videoNum.value])

const charityList = computed(() => {
    return {
        1: t('home52'),
        2: t('home53'),
        3: t('home54'),
        4: t('home55'),
        5: t('home56'),
        6: t('home57'),
        7: t('home58'),
        8: t('home58'),
        9: t('home58'),
        10: t('home58'),
        11: t('home58'),
        12: t('home58'),
        13: t('home59'),
        14: t('home60'),
        15: t('home61'),
        16: t('home74'),
        17: t('home59'),
        18: t('home75'),
        19: t('home76'),
        20: t('home77'),
        21: t('home77'),
        22: t('home77'),
        23: t('home77'),
        24: t('home77'),
        25: t('home77'),
    }
})

// const handleVideo = (index) =>{
//     videoNum.value += index
//     if(videoNum.value < 0){
//         videoNum.value = videoList.value.length - 1
//     }
//     if(videoNum.value > videoList.value.length - 1){
//         videoNum.value = 0
//     }
//     activeVideo.value = videoList.value[videoNum.value]
// }
</script>

<style lang="less" scoped>
@import "../../style/mySwiper.less";
.box{
    width: 100%;
    background: linear-gradient(180deg,#212637, #313647);
    border-radius: 20px;
    box-shadow: 0px 6px 10px 0px rgba(0,0,0,0.16); 
    padding: 44px 20px;
}

.video-list{
    width: 286px;
}
.video-list > div{
    cursor: pointer;
    position: relative;
    &.active::after,
    &:hover::after{
        content: '';
        width: calc(100% - 2px);
        height: calc(100% - 6px);
        border: 2px solid #FFB25A;
        border-radius: 10px;
        position: absolute;
        top: 0;
        left: 0;
    }
    &::before{
        content: '';
        width: 36px;
        height: 36px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-image: url(@/assets/images/pc/icon-play.svg);
    }
    &.active::before,
    &:hover::before{
        background-image: url(@/assets/images/pc/icon-play-hover.svg);
    }

}
.video-list > div:last-child{
    margin-bottom: 0;
}

.icon{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    &.icon-left{
        left: 24px
    }
    &.icon-right{
        right: 342px
    }
}

.charity-swiper{
    width: 1266px;
    padding-top: 90px;
    min-height: 225px;
}

.play{
    width: 36px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>