export default {
  v1: 'Professional native entertainment app',
  v2: 'Efficient, safest, and ultimate user experience',
  v3: '<b>We are delighted to announce that PP88 has reached a strategic partnership agreement with Valencia Club de Fútbol.</b><br/> As leaders in their respective fields, we believe this collaboration will bring fans an unforgettable experience. As a globally renowned sports brand, PP88 has always been committed to providing the highest quality products and services, creating endless possibilities for athletes and fans. Valencia Club de Fútbol is one of the legendary clubs in Spanish football, with a loyal fan base and outstanding team achievements. We believe that the collaboration between PP88 and Valencia Club de Fútbol will bring new breakthroughs and opportunities to the world of football. We look forward to working with Valencia Club de Fútbol to create an even more exciting experience for fans and contribute our strength to the development of football. PP88 and Valencia Club de Fútbol, creating brilliance together!',
  v4: 'Valencia Club de Fútbol, founded on March 18, 1919, is located in Valencia, the third-largest city in Spain. The club currently competes in the Spanish La Liga, and Valencia is a well-established team with a long history. Valencia Club de Fútbol is one of the renowned powerhouses in Europe, spending most of its time in La Liga. Since winning the Segunda División championship in the 1930/31 season and earning promotion to La Liga, the team has participated in La Liga for a long time. In the 1961/62 edition of the fourth International City Cup, the team scored 33 goals, setting the record for the highest number of goals in the International City Cup, including an 10-3 victory over Budapest MTK in the quarter-finals and a 7-3 victory over Barcelona in the final. The following year, they won the International City Cup again.',
  v5: 'Valencia has won 6 La Liga titles, 8 Copa del Rey titles, 3 UEFA Cup titles, 1 UEFA Cup Winners\' Cup, and 2 UEFA Super Cups. Valencia is one of the traditional top five teams in Spain and is also a member of the G-14.',
  v6: 'Spanish La Liga',
  v7: '6 titles',
  v8: 'Copa del Rey',
  v9: '8 titles',
  v10: 'Spanish Super Cup',
  v11: '1 title',
  v12: 'UEFA Cup',
  v13: '1 title',
  v14: 'UEFA Super Cup',
  v15: '2 titles',
  v16: 'Edinson Roberto Cavani Gómez',
  v17: 'Born on February 14, 1987, is a Uruguayan professional footballer who plays as a forward. He currently plays for Valencia in La Liga.',
  v18: 'Samu Castillejo',
  v19: 'Born on January 18, 1995, is a Spanish football player who plays as a right winger. He currently plays for Valencia in La Liga. He has represented the Spanish national youth football team.',
  v20: 'Mouctar Diakhaby',
  v21: 'Born on December 19, 1996, is a professional footballer who plays as a center-back. He currently plays for Valencia in La Liga. He was born in France.',
  v22: 'Domingos André Ribeiro Almeida',
  v23: 'Born on May 30, 2000, is a Portuguese professional footballer who plays as a midfielder. He currently plays for Valencia in La Liga.',
  v24: 'Eray Ervin Cömert',
  v25: 'Born on February 4, 1998, is a Swiss professional footballer who plays as a defender for Valencia in La Liga.',
  v26: 'Safer',
  v27: 'Exclusive network technology',
  v28: 'Strong defense against hijacking',
  v29: 'Richer',
  v30: 'Wide range of game products',
  v31: 'We have everything you want',
  v32: 'More stable',
  v33: 'Powerful technical team',
  v34: 'Provides the most stable products',
  v35: 'More private',
  v36: 'Triple data encryption',
  v37: 'Protects your data security',

  d3: 'In May 2019, BA88 Sports Platform reached a strategic cooperation with Borussia Dortmund in the German Bundesliga. This cooperation is a significant step for BA88 Sports Platform towards internationalization and an exciting collaboration between a young platform like BA88 Sports and an established German football club. Our goal is to enhance the brand impact of BA88 Sports and promote the Bundesliga worldwide. As an emerging force dedicated to creating top-quality products, collaborating with top partners, and shaping outstanding brand image, BA88 Sports is ready to rise and dominate the future. We believe that this cooperation between the two sides will create a bright and unobstructed future for each other. BA88 Sports and Borussia Dortmund, together for glory!',
  d4: 'Borussia Dortmund is a famous football club located in Dortmund, North Rhine-Westphalia, Germany. They are nicknamed the "Yellow and Blacks" ("Die Schwarzgelben"). The matches between Borussia Dortmund, which rose after World War II, and FC Schalke 04, located in the Ruhr industrial region (Ruhrgebiet), are extremely fierce due to their close geographical proximity and the same working-class culture. It is known as the "Ruhr Derby" or "Miner\'s Derby." The team has had excellent performance domestically in recent years and the matches against Bayern Munich are also known as the "German National Derby" by many fans. Borussia Dortmund has won 1 UEFA Champions League title, 1 UEFA Cup Winners\' Cup, 1 Intercontinental Cup, and 8 German Bundesliga titles.',
  d5: 'In 2012, Borussia Dortmund won the Bundesliga title again, achieving a record 81 points, the highest ever in Bundesliga history. They also won the DFB-Pokal final by defeating Bayern Munich 5-2, becoming the domestic double champions for the first time in the club\'s history.',
  d6: '2011',
  d7: 'German Bundesliga champions',
  d8: '2012',
  d9: 'Bundesliga (successful defense)',
  d10: '2012/13',
  d11: 'German Bundesliga runners-up (defeated by Bayern Munich)',
  d12: '2014',
  d13: 'DFL-Supercup winners (defeated Bayern Munich)',
  d14: 'Marco Reus',
  d15: 'Born on May 31, 1989, in Dortmund, Germany, Marco Reus is a German footballer who plays as a midfielder/forward for Borussia Dortmund in the Bundesliga. He is also the team captain.',
  d16: 'Giovanni Reyna',
  d17: 'Born on November 13, 2002, in Durham, England, Giovanni Reyna is an American footballer who plays as a midfielder for Borussia Dortmund in the Bundesliga.',
  d18: 'Nico Schulz',
  d19: 'Born on April 1, 1993, in Berlin, Germany, Nico Schulz is a German professional footballer who plays as a left-back. He currently plays for Borussia Dortmund in the Bundesliga.',
  d20: 'Felix Passlack',
  d21: 'Born on May 29, 1998, in Bottrop, Germany, Felix Passlack is a German professional footballer who plays as a right-back. He currently plays for Borussia Dortmund in the Bundesliga.',
  d22: 'Thomas Meunier',
  d23: 'Born on September 12, 1991, in Saint-Ode, Belgium, Thomas Meunier is a Belgian footballer who plays as a right-back/wing-back. He plays for Borussia Dortmund in the Bundesliga.',
  p3: 'Brand sponsorship',
  p4: 'All of our PP88 Sports products, customer service, software systems, network architecture, and related business matters are authorized and regulated by the Philippine government certification agency. We are an online entertainment company trusted by a large number of online players. We provide comprehensive online entertainment game services to our vast membership. We also have international entertainment experts and market strategy experts to make game usage more convenient for our members and to professionalize entertainment projects. We adhere to the belief of fairness and safety in creating a unique entertainment brand for customers online. We have a complete supervision mechanism and strict handling regulations to ensure that all members\' gaming entertainment is conducted in a fair, just, and transparent manner. We use excellent security technology to ensure flawless information transmission between players and games, using complex algorithms for data encryption to protect all player account data and ensure the correctness, fairness, and justice of the game structure. To provide our valued customers with the most convenient and fastest access to all relevant questions about online games and membership rights, we have specially established a 24-hour customer service center. Our customer service center welcomes you to come online at any time with the friendliest and most courteous service purpose to answer all your questions, whether they are inquiries, promotional opinions, compliments, or complaints.',
  p5: 'Media coverage and brand partnership',
  p6: 'Major media platforms are competing to report on the signing ceremony between PP88 Sports and Valencia Club, and these publications further enhance PP88 Sports\' brand influence in the world.',
  p7: 'Legitimate operation and government regulation',
  p8: 'About PAGCOR',
  p9: 'Philippine Amusement and Gaming Corporation (PAGCOR) is the authoritative organization that grants legal operating rights in the Philippines.',
  p10: 'PAGCOR certificate',

  h1: 'Valencia CF',
  h2: 'Borussia Dortmund',
  h3: 'Brand Introduction',

  h4: "In May 2019, the BA88 sports platform and the Bundesliga Dortmund club reached a strategic cooperation. This cooperation is a big step for BA88 sports to go international, and it is also a passionate cooperation between the young BA88 sports platform and the old Bundesliga giants. The goal of our cooperation is to enhance the brand effect of BA88 Sports and promote the Bundesliga on a global scale. When a cutting-edge force is committed to creating first-class products, cooperating with top partners, and shaping an outstanding brand image, it is ready to climb up and dominate the future. It is believed that the cooperation between the two parties will make the future of each other more open and bright.",
  h5: "Borussia Dortmund Football Club, a famous football club located in Dortmund, North Rhine-Westphalia (North Rhine-Westphalia), Germany, nicknamed ”Bumblebee“. The match between Borussia Dortmund, which rose after World War II, and Schalke 04, which is also located in the Ruhr Industrial Area (Ruhr Area) in North Rhine-Westphalia, was extremely popular due to its close geographical location and the same working-class culture. District Derby (Rourdby, Mining Derby)”; the team has performed very well in China in recent years, and the match with Bayern Munich has also been called the ”German National Derby“ by many fans. Dortmund has won 1 UEFA Champions League, 1 European Cup Winners' Cup, 1 Toyota Cup and 8 German top league titles.",
  d55: "In 2012, Borussia Dortmund won the Bundesliga title again, achieving a record 81 points, the highest ever in Bundesliga history. They also won the DFB-Pokal final by defeating Bayern Munich 5-2, becoming the domestic double champions for the first time in the club's history.",
  vt1: 'Event Venue',
  vt2: 'Valencia Club',
  vt3: 'Media Coverage',
  vt4: 'Exciting Moments',
  vt5: 'Star Players Shine',
  vt6: 'Industry\'s Best',
  vt7: 'Team Achievements',
  des1: 'Exclusive Partner for the Asia Region',
  des2: 'Official Partner',
  vp3: '<b>We are thrilled to announce that PP88 has reached a strategic cooperation agreement with Valencia Club.</b>As a globally renowned sports brand, PP88 has always been committed to providing the highest quality products and services, creating limitless possibilities for athletes and fans alike. We believe that the collaboration between PP88 and Valencia Club will bring new breakthroughs and opportunities to the football world. Together, we aim to create more exhilarating experiences for fans and contribute our efforts to the development of football. PP88 and Valencia Club, creating brilliance together!',
  vp4: 'Founded on March 18, 1919, Valencia Football Club currently competes in La Liga, the top-tier football league in Spain. Valencia is a team with a long and storied history. Since winning the Segunda División championship and earning promotion to La Liga in the 1930/31 season, the club has been a consistent presence in the league. The 1960s marked the team\'s golden era. In the 1961/62 fourth International Fair Cities Cup, the team scored 33 goals, setting a record for the most goals scored in the International Fair Cities Cup, including an 10-3 victory over Budapest MTK and a 7-3 sweep of Barcelona in the final. The following year, they secured their consecutive International Fair Cities Cup championship.',
  hp4: 'In May 2023, the BA88 Sports Platform and Borussia Dortmund Club entered into a strategic partnership. Our collaboration aims to enhance the brand impact of BA88 Sports and promote La Liga globally. As an emerging force committed to creating top-tier products and collaborating with elite partners, we believe that this cooperation will undoubtedly pave the way for a brighter future for both parties.',
  hp5: 'Borussia Dortmund is a famous football club located in Dortmund, North Rhine-Westphalia, Germany. They are nicknamed the "Yellow and Blacks" ("Die Schwarzgelben"). The matches between Borussia Dortmund, which rose after World War II, and FC Schalke 04, located in the Ruhr industrial region (Ruhrgebiet), are extremely fierce due to their close geographical proximity and the same working-class culture. It is known as the "Ruhr Derby" or "Miner\'s Derby." The team has had excellent performance domestically in recent years and the matches against Bayern Munich are also known as the "German National Derby" by many fans. Borussia Dortmund has won 1 UEFA Champions League title, 1 UEFA Cup Winners\' Cup, 1 Intercontinental Cup, and 8 German Bundesliga titles.',
  vt0: 'Brand Upgrade',
  hp6: 'The PP88 brand has evolved from BA88<br/>inheriting its essence while incorporating even more innovation to bring you a brand new experience.',
  vd2: 'Borussia Dortmund Club',

  mobile1: 'PP88 Group',
  mobile2: 'Home',
  mobile3: 'Platform Overview',
  mobile4: 'Sponsorship',
  mobile5: 'Charity',
  mobile6: 'Promotional Rewards',
  mobile7: 'Game Types',
  mobile8: 'Responsible Gambling',
  mobile9: 'User Experience',

  pc3: 'PLATFORM',
  pc4: 'SPONSOR',
  pc5: 'CHARITABLE',
  pc6: 'DISCOUNT',
  pc7: 'GAME',
  pc8: 'LICENSE',
  pc9: 'EXPERIENCE',

  home1: 'PP88 Sports possesses a legally issued license and is a legitimate company recognized by international industry associations. Please ensure you are over 18 years old before registering and entertaining!',
  home2: 'All rights reserved ©2020-2023 PP88 reserves all rights',
  home3: 'PP88 Welfare Benefits',
  home4: 'Social Contribution and Charity Activities',
  home6: 'Growth Journey - The Path to Rise',
  home7: 'PP88 Entertainment Group',
  home8: 'PP88 is the most trustworthy online entertainment city, with millions of users in Asia. Not only in Hong Kong, but we are also considered one of the top online casinos in Asia. Joining our online gambling website is very simple, just register an account on the website, and you can start enjoying your favorite online casino games.',
  home10: 'The BA88 brand has won the love and trust of a vast number of users in the past. Its achievements are not only reflected in its market performance but also in its high-quality partnerships. BA88 was once a sponsor of Borussia Dortmund Club in the Asia-Pacific region, which clearly reflects the brand\'s wide influence and its significant status in the industry.',

  home11: 'About PP88',
  home12: 'In general, NewCenturyGamingGroup, through its operation of brands such as PP88, BA88, OB88, and PP88SPORTS, provides users worldwide with an unparalleled gambling experience, while also fully demonstrating the company\'s innovative spirit, as well as its firm determination in pursuing customer satisfaction and business success.',
  home13: 'Diverse Games',
  home14: 'Multiple Games, Smooth Experience',
  home15: 'User Experience',
  home16: 'Service Advantages, Great Reputation',
  home17: 'Average Deposit Time',
  home18: 'Cooperative Payment Platforms',
  home19: 'Average Withdrawal Time',
  home20: 'Cooperative Gaming Platforms',
  home21: 'Fast Deposit and Withdrawal Transfer',
  home22: 'Latest technology self-developed financial processing system, multiple deposit channels, automatic no-transfer wallet, simplified withdrawal process, achieving truly fast deposit, withdrawal, and transfer.',
  home23: 'Massive Variety of Sports Events',
  home24: 'Every day, we offer thousands of exciting sports events such as football, basketball, eSports, tennis, snooker, etc., along with various entertainment options like live casino, lottery, chess, and electronic games.',
  home25: 'Encrypted Security Management',
  home26: 'Exclusively developed, using 128-bit encryption technology and a strict security management system, various intelligent anti-theft measures, 24-hour all-around protection of member accounts, ensuring the utmost security of member funds.',
  home27: 'Multiple Port Selection',
  home28: 'Independently developed a full suite of terminal applications, including meticulously crafted iOS and Android native apps, allowing you to follow sports hotspots anytime and anywhere, easily enjoying leisure and entertainment betting.',
  home29: 'User Reviews',
  home30: 'High Praise, Good Reputation',
  home31: 'Super Fast Withdrawal Speed',
  home32: 'The withdrawal speed at PP88 is super fast, it took about a minute for my withdrawal to arrive, which I have never experienced on other platforms, very impressive.',
  home33: 'Unimaginably High Rebates',
  home34: 'PP88 is the best platform I have used! Their rebates are the best among all the platforms I\'ve encountered. Truly deserving of the title of the highest rebate in the industry!',
  home35: 'PP88, Worthy of Trust',
  home36: 'I\'ve always been playing Baccarat on AE, and was initially worried, but after opening the AE official website and comparing with PP88\'s results, they were all the same, as were other comparisons, fair and trustworthy.',
  home37: 'Quick-Response Customer Service Deserves Praise',
  home38: 'Last time there was a problem with my account, after contacting their customer service, the issue was resolved in a short time. A genuine 5-star praise!',
  home39: 'Definitely Switch to PP88!',
  home40: 'Really happy about switching to the PP88 platform, high odds, and rich rewards. Seeing is believing, you must experience it for yourself!',
  home41: 'They make me feel like a real VIP!',
  home42: 'They provide VIPs with services far beyond expectations, and also occasionally offer exclusive rewards and benefits. This made me a loyal member of PP88!',
  home43: 'Unexpected Surprises',
  home44: 'One day I won a big prize playing slots, but I didn\'t know there was an event that offered an additional 88.88% bonus. PP88 notified me by an internal message, I love you!',
  home45: 'Unimaginably High Odds!',
  home46: 'PP88 is the best platform I\'ve tried! They have the highest payout rates and generous rewards.',
  home47: 'My Preferred Cryptocurrency Casino',
  home48: 'I can smoothly deposit and withdraw cryptocurrency on PP88. Other platforms don\'t offer the cryptocurrency option.',
  home49: 'Never Tiring of Playing',
  home50: 'PP88 offers the most game choices among all platforms! Sports betting, live casinos, electronic games, you name it. PP88 keeps me never tiring of playing.',
  home51: 'Follow Us',
  home52: 'Delivering charitable supplies to rural areas',
  home53: 'Charitable public welfare event',
  home54: 'Head of charity beneficiary',
  home55: 'Donating charitable supplies',
  home56: 'Visiting children',
  home57: 'Photo with the head of the beneficiary',
  home58: 'Visiting impoverished individuals',
  home59: 'Donating public welfare supplies',
  home60: 'Staff photo with left-behind children',
  home61: 'PP88 Group staff photo',
  home62: "Industry's highest odds in sports betting, covering all events. Options include handicap, over/under, 15-minute goals, correct score/reverse correct score, and outright winner. Innovative features allow early cash-out without waiting and a stop-loss mechanism for worry-free betting. Simple operation, fastest market opening, instant order taking, and quick settlement for constant excitement!",
  home63: "The most beautiful dealers on the net from various countries, featuring unique entertainment & professional dual modes. Enjoy an ultimate high-definition visual feast with special games like Dance Baccarat, VIP Private Tables, Squeeze Baccarat, and live play with beautiful hosts. Switch easily among multiple languages, join us for brilliance, and get lost in the fun!",
  home64: "Daily exclusive coverage of popular esports events with diverse betting options and quick settlements. Live in-game broadcasting for minute-to-minute excitement. Detailed event analysis, statistics, and real-time battle records for strategic planning from afar. Plus, unique mini-games for non-stop passion and more fun during breaks!",
  home65: "Daily exclusive coverage of popular esports events with diverse betting options and quick settlements. Live in-game broadcasting for minute-to-minute excitement. Detailed event analysis, statistics, and real-time battle records for strategic planning from afar. Plus, unique mini-games for non-stop passion and more fun during breaks!",
  home66: "A vast collection of slot games, committed to creating top-quality experiences. Games feature ingenious designs, new rules, high payout ratios, free spins, multiple cumulative jackpots, and stunning 3D graphics for an exceptional electronic gaming experience!",
  home67: "Distinctive fishing games with innovative 3D dynamic scenes and cool skills. Up to 600x odds with 4-player competitive mode, intelligent auto-targeting for fish, creating an unparalleled sense of immersion and gaming experience!",
  home68: "Diverse card games with innovative leisure entertainment like online red envelope grabbing. Fresh gameplay options like Bullfight, Dragon Tiger, Blackjack, Mahjong, and a variety of card games to choose from, showcasing your strategic skills and ensuring enjoyment!",
  home78: "Innovative gameplay, ultra-high odds, Happy Lottery size up and down index, always matching the size of the lottery, and fast opening and settlement, allowing you to fully immerse yourself in unique gameplay and experience the fun of opening lottery every 60/90 seconds!",
  home69: "APP Download",
  home70: "Exclusive native support for all platforms",
  home71: "Scan to download the APP Android & IOS",
  home72: "Register now for unlimited rewards, offers, and prizes.",
  home73: "Our Partners",
  home74: "Photo session of event participants",

  home75: "Giving welfare and love",
  home76: "Staff photo session",
  home77: "Distribution of charitable materials",

  platform1: 'Details',
  platform2: 'PP88 is the most trustworthy online entertainment city, with millions of users in Asia. Not only in Hong Kong, but we are also considered one of the top online casinos in Asia. Joining our online gambling website is very simple, just register an account on the website, and you can start enjoying your favorite online casino games. \n\nPP88 is a well-known gambling brand in Asia, especially in the Hong Kong region, and is a subsidiary of New Century Gaming Group, a forward-looking online gambling company with a broad business scope and a rich portfolio of brands, including PP88, BA88, OB88, and PP88 SPORTS, with millions of members worldwide. \n\nThe PP88 brand evolved from the BA88 brand (visit: https://BA88.COM for direct access to PP88), marking the continuous pursuit of innovation and product optimization by New Century Gaming Group.',
  platform3: 'PP88 Brand',
  platform4: 'The BA88 brand has won the love and trust of a vast number of users in the past. Its achievements are not only reflected in its market performance but also in its high-quality partnerships. BA88 was once a sponsor of Borussia Dortmund Club in the Asia-Pacific region, which clearly reflects the brand\'s wide influence and its significant status in the industry. \n\nThe upgrade to PP88 represents the new vision and strategy of New Century Gaming Group. This brand upgrade not only represents a keen insight into market dynamics but also a commitment to providing user experience and service. The various brands operated by \n\nNew Century Gaming Group, including PP88, offer users a comprehensive online gambling experience, from sports betting to virtual sports, from horse racing to online casinos, to poker and other online games. Its rich product line and high-quality service make the company stand out in the competitive gambling market and gain widespread user recognition.',
  platform5: 'In general, NewCenturyGamingGroup, through its operation of brands such as PP88, BA88, OB88, and PP88SPORTS, provides users worldwide with an unparalleled gambling experience, while also fully demonstrating the company\'s innovative spirit, and its firm determination in pursuing customer satisfaction and business success. \n\nIs PP88 legal? \nCurrently, PP88\'s headquarters is located in Manila, the capital of the Philippines, which is a global gambling industry hub. At the same time, PP88 operates under the regulation of the Philippine government, and the gambling website is also recognized by the government as a trustworthy, high-quality, and reliable gaming venue. PP88 uses a Philippine gambling license—PAGCOR (Philippine Amusement and Gaming Corporation), PAGCOR is a legally operating authoritative organization in the Philippines. \n\nPP88 also collaborates with the world\'s top network security and audit system IOVATION to ensure the safety, privacy, and confidentiality of your account information. \n\nPAGCOR\'s operation has always been at the forefront in the Asian region, and it will provide a better development prospect for the majority of licensees, while also providing a stable bridge for the gambling business in the Asia-Pacific region to connect with the global gambling market.',
  charity1: 'Here is a beautiful world!',
  charity2: 'We believe every smile \n has a story behind it.',
  charity3: 'We are committed to doing the right thing. We strive to be a force for good. Therefore, PP88 employees and the brand have always adhered to doing good every day.',
  charity4: 'Our Social Contribution',
  charity5: 'Our goal is to strive to improve the health and well-being of the communities we reach.',
  charity6: 'Disaster Relief and Aid',
  charity7: 'Supporting Healthcare and the Disabled',
  charity8: 'Caring for the Elderly and Orphans',
  charity9: 'PP88\'s donation actions have covered people in over 15 countries/regions, providing help to nursing homes, shelters, community groups, food banks, and other organizations.',
  charity10: 'To date, over 10,000 children have benefited from our programs, with 80% of them showing significant academic improvement.',
  charity11: 'We have helped improve the quality of life for over 5,000 elderly, 70% of whom report feeling happier and more socially connected.',
  charity12: 'As a company, we have a responsibility to give back to those in need.',
  charity13: 'Our goal is to bring lasting positive change to vulnerable groups.',
  charity14: 'In this rapidly changing world, our brand seeks not just commercial success but also to shoulder social responsibilities, spreading love to every corner in need of help. We believe that even the smallest act of kindness can ignite a spark of hope.',
  charity15: 'Thanks to our partners\ntheir support makes our dreams possible.',
  charity16: 'Official Partner in Asia',
  charity17: "We believe every smile has its own story behind it.",
  charity18: "Here is a beautiful world!",

  game1: 'Popular Games',
  game2: 'Wide Variety - Highly Recommended',
  game3: 'PP88 is a professional platform offering sports e-sports, live entertainment, and lottery betting. Providing our users with the best service and the most joyful time is our claim and responsibility. We also strongly hope that players maintain an entertainment mindset during the gaming process. PP88 will bring you an ultimate user experience, hyper-localized personalized service, and revolutionary betting products, allowing all players to enjoy themselves fully at PP88.',
  game4: 'Sports',
  game5: 'Casino',
  game6: 'E-Sports',
  game7: 'Slot',
  game8: 'Lottery',
  game9: 'Fishing',
  game10: 'Chess',
  resp1: 'PP88 is a famous online betting platform in Asia, holding a Philippine gambling license - PAGCOR (Philippine Amusement and Gaming Corporation), a legitimate authority in the Philippines. PP88 has been verified and obtained an international online betting association license, committing to ensuring the security and confidentiality of customer personal information.',
  resp2: 'Responsible Gambling Philosophy | PP88 Reputation Creates Brand',
  resp3: 'PP88 is a well-known and attractive online gaming platform in Asia. \nThe platform always puts players\' interests and rights first, while providing an appealing, fair, and healthy gambling environment. As a responsible gambling platform, PP88 has established a brand image for the company. \nThe concept of responsible gambling at PP88 mentions gambling, and people often only think of its negative aspects. \nHowever, this is not the case, as long as we know how to treat gambling correctly. Gambling is actually just a form of entertainment game, which can help you relax after busy work. \nIf you become overly addicted to gambling, you will be lost and become a gambling addict. \nThis has become a social problem that needs to be solved. \nTherefore, to provide a responsible gambling environment, PP88 will propose a series of questions for players to self-assess before experiencing the platform. \nHere are the questions you need to answer before experiencing the platform: \nDoes your gambling activity take up too much of your work or study time? \nHave you ever gone bankrupt because of gambling? \nHow do you recover psychologically after losing? \nHave you ever used all the family\'s property for gambling activities? \nHave you ever falsely reported the amount of gambling? \nIf you lose a large amount of money and cannot afford these debts, have you ever considered engaging in illegal activities or cheating? \nIf you win a large amount of money, do you become greedy and become more addicted to gambling activities than work or family? \nIf you have ever thought about the above questions, then you must adjust your mentality, state, and habits. \nOnly in this way can players realize how to engage in healthy gambling, not be greedy for victory, and not be frustrated by failure.',
  resp4: 'PP88 Responsible Gambling Rules',
  resp5: 'PP88 promises to always be confident and ensure to meet the three criteria set early on: honesty, transparency, and legality. \nIn addition, participants need to actively cooperate and strictly comply with the requirements set by the platform. \nTo establish a responsible gambling platform, PP88 has formulated the following rules: \nAge limit: PP88 members must be at least 18 years old to register and log in to their account to experience the game. Each participant can only create one game account for betting. \nIf multiple accounts are found, the platform will block your account and cancel all your previous winning bets. \nIn the process of participating in PP88 responsible gambling, players must be aware of the importance of keeping personal information confidential, such as account name, login password, username, associated bank accounts, etc. \nThis information is determined to be not disclosed to the outside world. Absolutely no fraudulent behavior. \nIf cheating is found in the gambling process, the platform has the right to refuse login and has the right to permanently disable your game account. \nThe platform reserves the right to refuse login and participate in all published games, if fraudulent behavior or inaccurate information is provided. \nIn the following cases, when human factors cause errors or the system encounters a malfunction, the platform will make the final decision. \nIf the website fails due to objective reasons (such as disasters or hacker intrusions), the solution is that players should always back up data for tracking, retrieval, and complaints when necessary. \nIf a dispute occurs unfortunately, players should immediately check their account information, preferably before the end of the bet. \nIf abnormal conditions are found, you can immediately contact the platform or customer service department to solve the problem.',
  resp6: '6 Tips for Responsible PP88 Betting:',
  resp7: 'After understanding the responsible betting information and rules of PP88, the platform also provides players with the following six suggestions: \nResponsible betting on PP88 is just an online entertainment betting game. Players should treat it as a form of entertainment, never indulge in it, and immediately discard the idea of winning back the bet when losing. \nResponsible PP88 betting: Players must clearly understand the game rules and gameplay. In addition, players can seek advice from experienced people or experts to increase the odds of betting. \nResponsible PP88 betting: Dear players should always keep a sober and sharp mind to reduce losses. \nResponsible PP88 betting: Players must have a good financial management strategy. Risk is inevitable, but how to limit the risk depends on the player themselves. \nResponsible PP88 betting: Put responsibility first, limit the time spent on betting to avoid affecting work and daily life. \nResponsible PP88 betting: Players must self-monitor and manage their time and money to ensure it is balanced with personal income. \nResponsible PP88 betting absolutely does not recommend players to overly indulge in online games, whether it\'s PP88 or any other betting website. By establishing a \nresponsible betting environment, all participants are very satisfied and have developed a sense of trust in the platform.',

  footer1: "This website is operated by Westward Way Tech N.V. Company, Registration Number: 168306, Registered Address: Parkeerplaats Dienst Openbare Scholen. The website's operating license number: N.V.#1168/JAZ, issued by the Government of Curaçao",
  footer2: "Authorized and regulated by Gaming Service Provider. Operations, payment, and support services related to this website are provided by legitimate and legal companies.",
  footer3: "Please ensure you are over 18 years old before registering and engaging in entertainment! Gambling can be addictive, please play responsibly.",
  footer4: " ©2023 PP88 All rights reserved"
}
