<template>
  <div>
    <template v-for="(value,key) in dataList" :key="key">
      <home-title :title="value.title" :desc="value.desc" class="pt-14px"></home-title>
      <template v-if="key === 'part_serve'">
        <div class="flex flex-wrap justify-evenly">
          <div v-for="(sub_item,index) in value.dataList" :key="index" class="basis-[40%] text-center mt-20px">
            <img :src="sub_item.img" class="mx-auto w-100px h-100px"/>
            <div class="text-#97A7B6 text-12px">{{ sub_item.name }}</div>
          </div>
        </div>
        <div class="flex flex-col mt-25px pb-10px" style="border-top:1px solid #131524">
          <div v-for="(sub_item,index) in value.dataList2" :key="index" class="text-center flex items-center mt-13px">
            <img :src="sub_item.img" class="mx-auto w-74px h-74px mr-8px"/>
            <div class="text-12px text-left">
              <div class="text-#FFB25A text-14px">{{ sub_item.name }}</div>
              <div class="text-#97A7B6 mt-6px">{{ sub_item.desc }}</div>
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="key === 'part_comment'">
        <van-swipe :autoplay="6000" class="my-swipe mb-20px" indicator-color="#FFFFFFEE">
          <van-swipe-item v-for="(sub_item,index) in value.dataList" :key="index" class="w-full">
            <div class=" bg-#1D2335 rounded-10px px-20px min-h-290px" style="border:1px solid #FFFFFF08">
              <div class="pt-20px text-#DBA84C text-18px text-center line-clamp-1">{{ sub_item.name }}</div>
              <div class="pt-31px text-white text-14px leading-33px line-clamp-3">{{ sub_item.desc }}</div>
              <div class="pt-38px pb-60px mx-auto text-center w-fit">
                <van-rate
                  v-model="sub_item.rate"
                  :size="15"
                  :count="5"
                  color="#ffd21e"
                  void-icon="star"
                  void-color="#eee"
                  readonly/>
              </div>
            </div>
          </van-swipe-item>
        </van-swipe>
      </template>
    </template>
  </div>
</template>
<script setup>
import {Swipe as VanSwipe, SwipeItem as VanSwipeItem, Rate as VanRate} from 'vant'
import HomeTitle from "@/views/mobile/HomeTitle.vue";
import {useI18n} from "vue-i18n";
import {computed} from "vue";

const {t} = useI18n()
const dataList = computed(() => {
  return {
    part_serve: {
      title: t('home15'),
      desc: t('home16'),
      dataList: [
        {name: t('home17'), img: require('@/assets/images/mobile/home/serve1.webp')},
        {name: t('home18'), img: require('@/assets/images/mobile/home/serve2.webp')},
        {name: t('home19'), img: require('@/assets/images/mobile/home/serve3.webp')},
        {name: t('home20'), img: require('@/assets/images/mobile/home/serve4.webp')},
      ],
      dataList2: [
        {
          name: t('home21'),
          desc: t('home22'),
          img: require('@/assets/images/mobile/home/serve5.webp')
        },
        {
          name: t('home23'),
          desc: t('home24'),
          img: require('@/assets/images/mobile/home/serve6.webp')
        },
        {
          name: t('home25'),
          desc: t('home26'),
          img: require('@/assets/images/mobile/home/serve7.webp')
        },
        {
          name: t('home27'),
          desc: t('home28'),
          img: require('@/assets/images/mobile/home/serve8.webp')
        },
      ]
    },
    part_comment: {
      title: t('home29'),
      desc: t('home30'),
      dataList: [{
        "name": t('home31'),
        "desc": t('home32'),
        "rate": 5,
        "fullratet": 5,
        "user": "min***uan1"
      }, {
        "name": t('home33'),
        "desc": t('home34'),
        "rate": 5,
        "fullratet": 5,
        "user": "dun***enha"
      }, {
        "name": t('home35'),
        "desc": t('home36'),
        "rate": 5,
        "fullratet": 5,
        "user": "bin***20"
      }, {
        "name": t('home37'),
        "desc": t('home38'),
        "rate": 5,
        "fullratet": 5,
        "user": "duy***"
      }, {
        "name": t('home39'),
        "desc": t('home40'),
        "rate": 5,
        "fullratet": 5,
        "user": "tie***ong2"
      }, {
        "name": t('home41'),
        "desc": t('home42'),
        "rate": 5,
        "fullratet": 5,
        "user": "lon***ong"
      }, {
        "name": t('home43'),
        "desc": t('home44'),
        "rate": 5,
        "fullratet": 5,
        "user": "abb***8"
      }, {
        "name": t('home45'),
        "desc": t('home46'),
        "rate": 5,
        "fullratet": 5,
        "user": "bio***112"
      }, {
        "name": t('home47'),
        "desc": t('home48'),
        "rate": 5,
        "fullratet": 5,
        "user": "anh***td"
      }, {
        "name": t('home49'),
        "desc": t('home50'),
        "rate": 5,
        "fullratet": 5,
        "user": "qua***"
      },]
    },
  }
})


</script>
