<template>
  <div class="mt-10px">
    <img v-for="(sub_item,index) in dataListFilter" :src="sub_item" :key="index"
         @click="doOpen('https://pp88hk.com/hdzq-all')"
         class="mb-10px w-full rounded-10px"/>
  </div>
</template>
<script setup>
import {computed} from "vue";
import {useI18n} from "vue-i18n";

function doOpen(link) {
  window.open(link)
}

const {locale} = useI18n()
const dataListFilter = computed(() => {
  return dataList[locale.value]
});
const dataList = {
  zh_CN: [
    "https://imagedelivery.net/knEeiiZgz5r1CUQ_9jx0-A/5d54c8cc-ff63-45b5-8c5c-b19420d95d00/public",
    "https://imagedelivery.net/knEeiiZgz5r1CUQ_9jx0-A/cba2ed12-025d-493c-2cde-956dd44ad200/public",
    "https://imagedelivery.net/knEeiiZgz5r1CUQ_9jx0-A/460b32cb-2bfe-4319-f7c5-b5f11fa2ce00/public",
    "https://imagedelivery.net/knEeiiZgz5r1CUQ_9jx0-A/a1826567-5e14-4912-7270-155e5ae90e00/public",
    "https://imagedelivery.net/knEeiiZgz5r1CUQ_9jx0-A/edbde9b3-7140-46d4-0269-a3406da03100/public",
    "https://imagedelivery.net/knEeiiZgz5r1CUQ_9jx0-A/b0a2e344-2529-4428-fa83-89c522342800/public",
  ],
  zh_TW: [
    "https://imagedelivery.net/knEeiiZgz5r1CUQ_9jx0-A/5d54c8cc-ff63-45b5-8c5c-b19420d95d00/public",
    "https://imagedelivery.net/knEeiiZgz5r1CUQ_9jx0-A/cba2ed12-025d-493c-2cde-956dd44ad200/public",
    "https://imagedelivery.net/knEeiiZgz5r1CUQ_9jx0-A/460b32cb-2bfe-4319-f7c5-b5f11fa2ce00/public",
    "https://imagedelivery.net/knEeiiZgz5r1CUQ_9jx0-A/a1826567-5e14-4912-7270-155e5ae90e00/public",
    "https://imagedelivery.net/knEeiiZgz5r1CUQ_9jx0-A/edbde9b3-7140-46d4-0269-a3406da03100/public",
    "https://imagedelivery.net/knEeiiZgz5r1CUQ_9jx0-A/b0a2e344-2529-4428-fa83-89c522342800/public",
  ],
  en_US: [
    "https://imagedelivery.net/knEeiiZgz5r1CUQ_9jx0-A/1bf7d648-5572-494e-a5f6-13d8b1226200/public",
    "https://imagedelivery.net/knEeiiZgz5r1CUQ_9jx0-A/5898a41d-17b3-40ad-b6ca-849dfea2d100/public",
    "https://imagedelivery.net/knEeiiZgz5r1CUQ_9jx0-A/b4c6170b-55f5-443a-e4f2-ad38ebeee200/public",
    "https://imagedelivery.net/knEeiiZgz5r1CUQ_9jx0-A/fd1c9142-01aa-474e-594f-5655d9eda100/public",
    "https://imagedelivery.net/knEeiiZgz5r1CUQ_9jx0-A/4e8db022-3ba8-47b4-18bc-4f3b7e12d100/public",
    "https://imagedelivery.net/knEeiiZgz5r1CUQ_9jx0-A/45435fb2-dba2-4ed6-85e2-1ac8a7b84200/public"
  ],
  vi_VN: [
    "https://imagedelivery.net/knEeiiZgz5r1CUQ_9jx0-A/94cc6c4d-0a89-4210-0240-1664aa68b000/public",
    "https://imagedelivery.net/knEeiiZgz5r1CUQ_9jx0-A/d363cac3-3eff-48d4-e98d-55a39ed96600/public",
    "https://imagedelivery.net/knEeiiZgz5r1CUQ_9jx0-A/b4554f97-7a87-4ede-175b-85c305f2d300/public",
    "https://imagedelivery.net/knEeiiZgz5r1CUQ_9jx0-A/13c04657-85bb-4251-3436-e8fce1cf3200/public",
    "https://imagedelivery.net/knEeiiZgz5r1CUQ_9jx0-A/6da12af5-424b-4693-d887-697b2ee08900/public",
    "https://imagedelivery.net/knEeiiZgz5r1CUQ_9jx0-A/d1b41853-54cd-43e6-f2b8-ac89c850aa00/public",
  ],
  en_MAS: [
    "https://imagedelivery.net/knEeiiZgz5r1CUQ_9jx0-A/3677e3ef-df5e-4db7-cf8c-a87b96041000/public",
    "https://imagedelivery.net/knEeiiZgz5r1CUQ_9jx0-A/a3c75ea6-d8ee-4ab9-ef87-d12d3b0c5700/public",
    "https://imagedelivery.net/knEeiiZgz5r1CUQ_9jx0-A/f7b72692-b214-4f34-4777-7ed7a14ebf00/public",
    "https://imagedelivery.net/knEeiiZgz5r1CUQ_9jx0-A/2aee420b-1e6f-4d82-3ccb-1b7dd886f500/public",
    "https://imagedelivery.net/knEeiiZgz5r1CUQ_9jx0-A/022bc078-58f0-4206-4fcd-7accdee97200/public",
    "https://imagedelivery.net/knEeiiZgz5r1CUQ_9jx0-A/654e0a99-e639-4def-e5aa-b39bd98c8e00/public",
  ]
}
</script>
