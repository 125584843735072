<template>
    <div class="relative">
        <div class="absolute top-24px left-0">
            {{ $t('home73') }}
        </div>
        <swiper
            :slidesPerView="6"
            :spaceBetween="20"
            :navigation="true"
            :pagination="{
                clickable: true,
            }"
            :modules="modules"
            class="mySwiper partner-swiper">
            <swiper-slide v-for="item in 41" :key="item">
                <div class="box">
                    <img class="h-50px" :src="require('@/assets/images/pc/partner/partner-' + item + '.png')" >
                </div>
            </swiper-slide>
        </swiper>
    </div>
</template> 

<script setup>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

const modules = [Navigation];
</script> 

<style lang="less" scoped>
@import "../../style/mySwiper.less";

.partner-swiper{
    padding-top: 58px;
}

.box{
    width: 206px;
    height: 109px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("@/assets/images/pc/partner/partner-bg.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.partner-swiper :deep(.swiper-button-next), 
.partner-swiper :deep(.swiper-button-prev){
    top: 42px
}
</style>