<template>
  <div>
    <template v-for="(value,key) in dataList" :key="key">
      <div class="pt-20px rounded-10px" v-if="key === 'part_banner'">
        <el-carousel :interval="5000" arrow="always" height="160px">
          <el-carousel-item v-for="(img,index) in value[locale]" :key="index">
            <img :src="img" class="w-full"/>
          </el-carousel-item>
        </el-carousel>
      </div>
      <template v-else>
        <home-title v-if="value.title" :title="value.title" :desc="value.desc" class="mt-10px"></home-title>
        <template v-if="key === 'part_video'">
          <div class="flex justify-between w-full items-center">
            <div v-for="(sub_item,index) in value.dataList2" class="basis-[31.5%]" :key="index">
              <div class="relative w-full h-fit">
                <img :src="sub_item.poster"
                     @click="activeVideo = sub_item" class="w-full transition"
                     :class="{'scale-110':activeVideo.poster === sub_item.poster}"/>
                <img src="@/assets/images/mobile/home/play_btn.png"
                     class="absolute top-50% left-50% -translate-x-50% -translate-y-50% pointer-events-none"/>
              </div>
            </div>
          </div>
          <video :src="activeVideo.video" :poster="activeVideo.poster" playsinline
                 preload="metadata" :autoplay="true" :muted="true"
                 controls class="w-full rounded-10px justify-between my-10px">
          </video>
          <div class="flex overflow-auto w-full">
            <div v-for="(banner_item,index) in value.dataList" :key="index" class="text-center mr-6px">
              <img :src="banner_item.img" class="h-85px"/>
              <div class="text-white text-10px">{{ banner_item.name }}</div>
            </div>
          </div>
        </template>
        <template v-else-if="key === 'part_about'">
          <el-carousel :interval="5000" arrow="never" height="168px" indicator-position="outside">
            <el-carousel-item v-for="(banner_item,index) in value.dataList" :key="index">
              <div class="flex justify-between text-white text-center bg-#1B1E2F pr-10px items-center">
                <img :src="banner_item.img" class="w-150px mr-8px"/>
                <div>
                  <div class="text-13px pt-5px">{{ banner_item.name }}</div>
                  <div class="text-11px mt-10px text-#97A7B6 line-clamp-7">{{ banner_item.desc }}</div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </template>
        <template v-else-if="key === 'part_game'">
          <game-nav @change="(imgIndex) => changeTo(imgIndex)"></game-nav>
          <van-swipe ref="mySwiper" :show-indicators="false">
            <van-swipe-item v-for="(sub_item,index) in value.dataList" :key="index">
              <img :src="sub_item.img" class="w-full"/>
            </van-swipe-item>
          </van-swipe>
        </template>
        <template v-else-if="key === 'part_serve'">
          <div class="flex flex-wrap justify-evenly">
            <div v-for="(sub_item,index) in value.dataList" :key="index" class="basis-[40%] text-center mt-20px">
              <img :src="sub_item.img" class="mx-auto w-100px h-100px"/>
              <div class="text-#97A7B6 text-12px">{{ sub_item.name }}</div>
            </div>
          </div>
          <div class="flex flex-col mt-25px" style="border-top:1px solid #131524">
            <div v-for="(sub_item,index) in value.dataList2" :key="index" class="text-center flex items-center mt-13px">
              <img :src="sub_item.img" class="mx-auto w-74px h-74px mr-8px"/>
              <div class="text-12px text-left">
                <div class="text-#FFB25A text-14px">{{ sub_item.name }}</div>
                <div class="text-#97A7B6 mt-6px">{{ sub_item.desc }}</div>
              </div>
            </div>
          </div>
        </template>
        <template v-else-if="key === 'part_comment'">
          <van-swipe :autoplay="6000" class="my-swipe mb-20px" indicator-color="#FFFFFFEE">
            <van-swipe-item v-for="(sub_item,index) in value.dataList" :key="index" class="w-full">
              <div class=" bg-#1D2335 rounded-10px px-20px min-h-290px" style="border:1px solid #FFFFFF08">
                <div class="pt-20px text-#DBA84C text-18px text-center line-clamp-1">{{ sub_item.name }}</div>
                <div class="pt-31px text-white text-14px leading-33px line-clamp-3">{{ sub_item.desc }}</div>
                <div class="pt-38px pb-60px mx-auto text-center w-fit">
                  <van-rate
                    v-model="sub_item.rate"
                    :size="15"
                    :count="5"
                    color="#ffd21e"
                    void-icon="star"
                    void-color="#eee"
                    readonly/>
                </div>
              </div>
            </van-swipe-item>
          </van-swipe>
        </template>
        <template v-else-if="key === 'part_footer'">
          <div class="flex justify-between">
            <div v-for="(sub_item,index) in value.dataList" :key="index">
              <div class="flex items-center">
                <div class="w-2px h-14px bg-#FFB25A"></div>
                <div class="text-white pl-10px">{{ sub_item.name }}</div>
              </div>
              <div class="flex mt-23px">
                <img v-for="(sub_img,index2) in sub_item.logoList" :src="sub_img" :key="index2"
                     class="mr-10px h-28px w-28px"/>
              </div>
            </div>
          </div>
          <div class="px-20px text-#97A7B6 text-center my-30px">
            <div>{{ t('home1') }}</div>
            <div class="mt-14px">{{ t('home2') }}</div>
          </div>
        </template>
      </template>
    </template>
  </div>
</template>
<script setup>
import HomeTitle from "@/views/mobile/HomeTitle.vue";
import {Swipe as VanSwipe, SwipeItem as VanSwipeItem, Rate as VanRate} from 'vant';
import GameNav from "@/views/mobile/GameNav.vue";
import {computed, onMounted, ref} from "vue";
import {useI18n} from "vue-i18n";

const {t,locale} = useI18n()
const mySwiper = ref()
const activeVideo = ref({})
const dataList = computed(() => {
  return {
    part_banner: {
      zh_CN:[
        require('@/assets/images/mobile/home/P1_cn.webp'),
        require('@/assets/images/mobile/home/P2_cn.webp'),
        require('@/assets/images/mobile/home/P3_cn.webp'),
      ],
      zh_TW:[
        require('@/assets/images/mobile/home/P1_tw.webp'),
        require('@/assets/images/mobile/home/P2_tw.webp'),
        require('@/assets/images/mobile/home/P3_tw.webp'),
      ],
      en_US:[
        require('@/assets/images/mobile/home/P1_en.webp'),
        require('@/assets/images/mobile/home/P2_en.webp'),
        require('@/assets/images/mobile/home/P3_en.webp'),
      ],
      en_MAS:[
        require('@/assets/images/mobile/home/P1_ms.webp'),
        require('@/assets/images/mobile/home/P2_ms.webp'),
        require('@/assets/images/mobile/home/P3_ms.webp'),
      ],
      vi_VN:[
        require('@/assets/images/mobile/home/P1_vn.webp'),
        require('@/assets/images/mobile/home/P2_vn.webp'),
        require('@/assets/images/mobile/home/P3_vn.webp'),
      ]
    },
    part_video: {
      title: t('mobile5'),
      desc: t('home4'),
      dataList: [
        {name: t('home52'), img: require('@/assets/images/mobile/charity/d_1.webp')},
        {name: t('home53'), img: require('@/assets/images/mobile/charity/d_2.webp')},
        {name: t('home54'), img: require('@/assets/images/mobile/charity/d_3.webp')},
        {name: t('home55'), img: require('@/assets/images/mobile/charity/d_4.webp')},
        {name: t('home56'), img: require('@/assets/images/mobile/charity/d_5.webp')},
        {name: t('home57'), img: require('@/assets/images/mobile/charity/d_6.webp')},
        {name: t('home58'), img: require('@/assets/images/mobile/charity/d_7.webp')},
        {name: t('home58'), img: require('@/assets/images/mobile/charity/d_8.webp')},
        {name: t('home58'), img: require('@/assets/images/mobile/charity/d_9.webp')},
        {name: t('home58'), img: require('@/assets/images/mobile/charity/d_10.webp')},
        {name: t('home58'), img: require('@/assets/images/mobile/charity/d_11.webp')},
        {name: t('home58'), img: require('@/assets/images/mobile/charity/d_12.webp')},
        {name: t('home59'), img: require('@/assets/images/mobile/charity/d_13.webp')},
        {name: t('home60'), img: require('@/assets/images/mobile/charity/d_14.webp')},
        {name: t('home61'), img: require('@/assets/images/mobile/charity/d_15.webp')},
      ],
      dataList2: [
        {
          poster: require('@/assets/images/mobile/home/chat3.webp'),
          video: 'https://pic.li3zq.com/video/public-spirited2.mp4?t_'+new Date().getTime()
        },
        {
          poster: require('@/assets/images/mobile/home/chat1.webp'),
          video: 'https://pic.li3zq.com/video/public-spirited3.mp4?t_'+new Date().getTime()
        },
        {
          poster: require('@/assets/images/mobile/home/chat2.webp'),
          video: 'https://pic.li3zq.com/video/public-spirited1.mp4?t_'+new Date().getTime()
        },

      ]
    },
    part_about: {
      title: t('mobile3'),
      desc: t('home6'),
      dataList: [
        {
          name: t('home7'),
          desc: t('home8'),
          img: require('@/assets/images/mobile/home/about1.webp')
        },
        {
          name: t('platform3'),
          desc: t('home10'),
          img: require('@/assets/images/mobile/home/about2.webp')
        },
        {
          name: t('home11'),
          desc: t('home12'),
          img: require('@/assets/images/mobile/home/about3.webp')
        },
      ]
    },
    part_game: {
      title: t('home13'),
      desc: t('home14'),
      dataList: [
        {name: 'xxx', img: require('@/assets/images/mobile/game/game_sport_banner.webp')},
        {name: 'xxx', img: require('@/assets/images/mobile/game/game_casino_banner.webp')},
        {name: 'xxx', img: require('@/assets/images/mobile/game/game_esport_banner.webp')},
        {name: 'xxx', img: require('@/assets/images/mobile/game/game_slot_banner.webp')},
        {name: 'xxx', img: require('@/assets/images/mobile/game/game_lottery_banner.webp')},
        {name: 'xxx', img: require('@/assets/images/mobile/game/game_fish_banner.webp')},
        {name: 'xxx', img: require('@/assets/images/mobile/game/game_chess_banner.webp')},
      ]
    },
    part_serve: {
      title: t('mobile9'),
      desc: t('home16'),
      dataList: [
        {name: t('home17'), img: require('@/assets/images/mobile/home/serve1.webp')},
        {name: t('home18'), img: require('@/assets/images/mobile/home/serve2.webp')},
        {name: t('home19'), img: require('@/assets/images/mobile/home/serve3.webp')},
        {name: t('home20'), img: require('@/assets/images/mobile/home/serve4.webp')},
      ],
      dataList2: [
        {
          name: t('home21'),
          desc: t('home22'),
          img: require('@/assets/images/mobile/home/serve5.webp')
        },
        {
          name: t('home23'),
          desc: t('home24'),
          img: require('@/assets/images/mobile/home/serve6.webp')
        },
        {
          name: t('home25'),
          desc: t('home26'),
          img: require('@/assets/images/mobile/home/serve7.webp')
        },
        {
          name: t('home27'),
          desc: t('home28'),
          img: require('@/assets/images/mobile/home/serve8.webp')
        },
      ]
    },
    part_comment: {
      title: t('home29'),
      desc: t('home30'),
      dataList: [{
        "name": t('home31'),
        "desc": t('home32'),
        "rate": 5,
        "fullratet": 5,
        "user": "min***uan1"
      }, {
        "name": t('home33'),
        "desc": t('home34'),
        "rate": 5,
        "fullratet": 5,
        "user": "dun***enha"
      }, {
        "name": t('home35'),
        "desc": t('home36'),
        "rate": 5,
        "fullratet": 5,
        "user": "bin***20"
      }, {
        "name": t('home37'),
        "desc": t('home38'),
        "rate": 5,
        "fullratet": 5,
        "user": "duy***"
      }, {
        "name": t('home39'),
        "desc": t('home40'),
        "rate": 5,
        "fullratet": 5,
        "user": "tie***ong2"
      }, {
        "name": t('home41'),
        "desc": t('home42'),
        "rate": 5,
        "fullratet": 5,
        "user": "lon***ong"
      }, {
        "name": t('home43'),
        "desc": t('home44'),
        "rate": 5,
        "fullratet": 5,
        "user": "abb***8"
      }, {
        "name": t('home45'),
        "desc": t('home46'),
        "rate": 5,
        "fullratet": 5,
        "user": "bio***112"
      }, {
        "name": t('home47'),
        "desc": t('home48'),
        "rate": 5,
        "fullratet": 5,
        "user": "anh***td"
      }, {
        "name": t('home49'),
        "desc": t('home50'),
        "rate": 5,
        "fullratet": 5,
        "user": "qua***"
      },]
    },
    part_footer: {
      dataList: [
        {
          name: t('home51'),
          logoList: [
            require('@/assets/images/mobile/home/logo1.svg'),
            require('@/assets/images/mobile/home/logo2.png'),
            require('@/assets/images/mobile/home/logo3.svg'),
          ]
        },
        {
          name: t('mobile8'),
          logoList: [
            require('@/assets/images/mobile/home/logo4.svg'),
            require('@/assets/images/mobile/home/logo5.svg'),
            require('@/assets/images/mobile/home/logo6.svg'),
          ]
        },
      ]
    }
  }
})

onMounted(() => {
  activeVideo.value = dataList.value.part_video.dataList2[0]
})

function changeTo(index) {
  mySwiper.value[0].swipeTo(index)
}
</script>
<style lang="less" scoped>
.el-carousel {
  :deep .el-carousel__container {
    button {
      height: 25px;
      width: 25px;
    }
  }
}

:deep(.el-carousel__item) {
  height: fit-content;
}

.voice-item {
  background-size: 100% 100%;
  border-radius: 15px;
  background-color: var(--masPrimary);
  text-align: center;
  margin: 0 3% 0 0;
  height: 330px;
  overflow: hidden;

  .voice-title {
    font-size: 18px;
    color: #DBA84C;
    margin: 20px 0;
  }

  .voice-content {
    font-size: 14px;
    line-height: 33px;
    box-sizing: border-box;
    padding: 0 24px;
    text-align: left;
    color: #fff;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
  }

  .voice-bottom {
    position: absolute;
    bottom: 20px;
    right: 10px;
    width: 100%;

    .voice-rate {
      padding: 15px 0;
    }

    .voice-name {
      box-sizing: border-box;
      padding-right: 24px;
      text-align: right;
      color: #fff;
    }
  }
}
</style>
