export default {
  v1: '專業的原生娛樂APP',
  v2: '高效率 最安全 最極致的使用者體驗',
  v3: '<b>我們非常高興地宣布，PP88與瓦倫西亞俱樂部達成了戰略合作協議。</b><br/>作為兩個領域中的領導者，我們相信這次合作將為球迷們帶來更加難忘的體驗。作為全球知名的體育品牌，PP88一直以來致力於提供最優質的產品和服務，為運動員和球迷們創造無限可能。瓦倫西亞俱樂部則是西班牙足球的傳奇之一，擁有眾多忠誠的球迷和傑出的球隊成績。我們相信，PP88與瓦倫西亞俱樂部的合作將為足球界帶來新的突破和機遇。我們期待著與瓦倫西亞俱樂部的合作，共同為球迷們創造更加激動人心的體驗，為足球運動的發展貢獻我們的力量。PP88與瓦倫西亞俱樂部，共創輝煌！',
  v4: '瓦倫西亞足球俱樂部，成立於1919年3月18日，位於西班牙的第三大城市瓦倫西亞，現參加西班牙足球甲級聯賽，瓦倫西亞是一支有著悠久歷史的老牌球隊。瓦倫西亞是歐洲聞名的劍旅之一，絕大部分時間都是在西甲中度過的，自從1930/31賽季奪得西乙冠軍升上西甲之後，長時間參加西甲聯賽，直到1985/86賽季意外降級，其後即以西乙冠軍身份重返西甲，自此球隊一直參加西甲聯賽。60年代為球隊最光輝的時刻，1961/62第四屆國際城市博覽會杯，球隊攻入33個進球，創造了國際城市博覽會杯比賽的進球最高紀錄，當中包括8強10:3狂勝布達佩斯MTK，決賽7:3橫掃巴塞羅那。第二年，蟬聯了國際城市博覽會杯的冠軍。',
  v5: '瓦倫西亞曾贏得6次西甲冠軍、8次西班牙國王盃冠軍、3次歐洲足協盃冠軍、1次歐洲盃賽冠軍盃冠軍和2次歐洲超霸杯。瓦倫西亞是西班牙傳統五強之一，瓦倫西亞也是G-14成員之一。',
  v6: '西班牙足球甲級聯賽',
  v7: '6次冠軍',
  v8: '西班牙國王盃',
  v9: '8次冠軍',
  v10: '西班牙超級盃',
  v11: '1次冠軍',
  v12: '歐洲聯盟杯',
  v13: '1次冠軍',
  v14: '歐洲超級盃冠軍',
  v15: '2次冠軍',
  v16: '埃丁森·卡瓦尼 Edinson Roberto Cavani Gómez',
  v17: '他出生於1987年2月14日，是一名烏拉圭職業足球運動員，司職前鋒，現效力於西甲球隊瓦倫西亞。',
  v18: '沙姆·卡斯蒂列霍 Samu Castillejo',
  v19: '出生於1995年1月18日，是一位西班牙足球運動員。在場上的位置是右邊鋒。現效力於西甲球隊瓦倫西亞。他曾代表西班牙國家青年足球隊參賽。',
  v20: '穆塔爾·迪亞卡比 Mouctar Diakhaby',
  v21: '出生於1996年12月19日，是一名職業足球運動員，司職中後衛，現效力於西甲俱樂部瓦倫西亞。出生於法國。',
  v22: '安德烈·阿爾梅達 Domingos André Ribeiro Almeida',
  v23: '出生於2000年5月30日，葡萄牙職業足球運動員，司職中場，效力於西甲球隊瓦倫西亞。',
  v24: '埃爾文·科梅爾特 Eray Ervin Cömert',
  v25: '出生於1998年2月4日，是一名瑞士職業足球運動員，擔任西甲俱樂部瓦倫西亞的後衛。',
  v26: '更安全',
  v27: '獨家網絡技術',
  v28: '超強防御劫持',
  v29: '更豐富',
  v30: '豐富的遊戲產品',
  v31: '您想要的我們都有',
  v32: '更穩定',
  v33: '強大的技術團隊',
  v34: '提供最穩定的產品',
  v35: '更私密',
  v36: '三重數據加密',
  v37: '保護您的數據安全',

  d3: '2019年5月，BA88體育平台和德甲多特蒙德俱樂部達成戰略合作，本次合作是BA88體育邁向國際化的一大步，也是年輕的BA88體育平台與老牌德甲豪門的一次激情合作。我們的合作目標是提高BA88體育品牌效應並在全球範圍內推廣德甲聯賽。當一支新銳力量致力於打造一流產品、與頂尖合作夥伴、塑造傑出品牌形象，它已準備好向上攀升，主宰未來。相信雙方此次的合作定會讓彼此的前途更加坦蕩無阻、一片光明。',
  d4: '多特蒙德足球俱樂部，位於德國北萊茵-威斯特法倫州多特蒙德市的著名足球俱樂部，綽號「大黃蜂」。二戰後崛起的多特蒙德和同處北威州魯爾工業區（魯爾區）的沙爾克04之間的比賽因緊鄰的地理位置、相同的工人階級文化而火爆異常，被稱為「魯爾區德比（魯爾德比、礦區德比）」；球隊在國內近些年成績非常好，和拜仁慕尼黑之間的比賽也被不少球迷稱作「德國國家德比」。多特蒙德曾獲得1次歐洲冠軍聯賽冠軍，1次歐洲優勝者杯冠軍，1次豐田杯冠軍和8次德國頂級聯賽冠軍。',
  d5: '2012年多特蒙德再次獲得德甲冠軍，並以81分成為德甲史上最高分的冠軍。同時也在德國足協盃決賽以5:2擊敗拜仁慕尼黑奪冠，是俱樂部歷史上首次成為國內雙冠王。',
  d6: '2011年',
  d7: '德國足球甲級聯賽，獲得冠軍',
  d8: '2012年',
  d9: '德國足球甲級聯賽，成功衛冕冠軍',
  d10: '2012/13賽季歐洲冠軍聯賽',
  d11: '德國足球甲級聯賽，對陣拜仁慕尼黑，惜敗，獲得亞軍',
  d12: '2014年',
  d13: '德國超級盃，擊敗拜仁慕尼黑獲得冠軍',
  d14: '馬爾科·羅伊斯（Marco Reus）',
  d15: '1989年5月31日出生於德國多特蒙德，德國足球運動員，場上司職中場/前鋒，效力於德甲的多特蒙德足球俱樂部，亦擔任球隊隊長',
  d16: '喬瓦尼·雷納（Giovanni Reyna）',
  d17: '2002年11月13日出生於英格蘭達勒姆，美國足球運動員，場上司職中場，現效力於德甲多特蒙德足球俱樂部',
  d18: '尼科·舒爾茨（Nico Schulz）',
  d19: '1993年4月1日出生於柏林，是一名德國職業足球運動員，場上位置為左後衛，現效力於德甲多特蒙德足球俱樂部',
  d20: '費利克斯·帕斯拉克（Felix Passlack）',
  d21: '1998年5月29日出生於德國波特洛普，德國職業足球運動員，場上司職右後衛，現效力於德甲的多特蒙德足球俱樂部',
  d22: '托馬斯·梅尼耶（Thomas Meunier）',
  d23: '1991年9月12日出生於比利時聖奧德，比利時足球運動員，司職右後衛/翼衛，效力於德甲多特蒙德足球俱樂部',
  p3: '品牌贊助',
  p4: '本公司PP88體育所有產品與客戶服務、軟體系統、網絡架構等相關業務事宜，皆由菲律賓政府認證機構所授權和監管，我們是一家深受廣大在線玩家信賴的在線娛樂公司，我們提供了完整的在線娛樂遊戲服務給我們的廣大會員，並且我們也擁有國際級的娛樂信息專家及市場策略專家，可以讓會員在遊戲使用上更加方便，讓娛樂項目能夠專業化，我們堅持以公正、安全的信念為客戶在網上打造別樹一幟的娛樂品牌。我們備有完整的監督機制及嚴肅的處理規定，以確保所有會員的遊戲娛樂都能在公平、公正、公開的狀態下進行，我們使用了極好的安全技術特性，能在玩家和遊戲之間的信息傳遞上毫無漏洞，複雜的演算法來進行數據加密，為了就是希望能保護玩家的所有帳戶數據和遊戲結構正確、公平與公正。為了讓我們尊貴的客戶可以最方便、最快速地得到所有關於線上遊戲與會員權益等相關問題，我們特別設立24小時客戶服務中心，以最親切、最禮貌的服務宗旨來為玩家解答所有問題，不管是任何疑問，推廣意見，讚揚或投訴，我們的客服中心都歡迎您隨時在線光臨。',
  p5: '媒體報導 品牌合作',
  p6: '各大媒體平台爭相報導PP88體育與瓦倫西亞俱樂部的簽約盛況，這些報導的出版進一步提升了PP88體育平台在世界上的品牌影響力。',
  p7: '合法經營 政府監管',
  p8: '關於PAGCOR',
  p9: '菲律賓博彩執照—PAGCOR（Philippine Amusement and Gaming Corporation）PAGCOR是菲律賓有合法經營權的權威組織。',
  p10: 'PAGCOR證書',

  h1: '瓦倫西亞俱樂部',
  h2: '多特蒙德俱樂部',
  h3: '品牌介紹',
  h4: "於2023年5月，BA88體育平台與多特蒙德俱樂部達成戰略合作。這次合作是BA88體育邁向國際化的一大步，也是年輕的BA88體育平台與西甲豪門的一次激情合作。我們的合作目標是提高BA88體育品牌效應並在全球範圍內推廣西甲聯賽。當一支新銳力量致力於打造一流產品、與頂尖合作夥伴、塑造傑出品牌形象，它已準備好向上攀升，主宰未來。相信雙方此次的合作定會讓彼此的前途更加坦蕩無阻、一片光明。",
  h5: "多特蒙德足球俱樂部，成立於1919年3月18日，位於西班牙的第三大城市多特蒙德，現參加西班牙足球甲級聯賽，多特蒙德是一支有著悠久歷史的老牌球隊。多特蒙德是歐洲聞名的勁旅之一，絕大部分時間都是在西甲中度過的，自從1930/31賽季奪得西乙冠軍升上西甲之後，長時間參加西甲聯賽，直到1985/86賽季意外降級，其後即以西乙冠軍身份重返西甲，自此球隊一直參加西甲聯賽。60年代為球隊最光輝的時刻，1961/62第四屆國際城市博覽會杯，球隊攻入33個進球，創造了國際城市博覽會杯比賽的進球最高紀錄，當中包括8強10:3狂勝布達佩斯MTK，決賽7:3橫掃巴塞羅那。第二年，蟬聯了國際城市博覽會杯的冠軍。",
  d55: "2012年多特蒙德再次獲得德甲冠軍，並以81分成為德甲史上最高分的冠軍。同時也在德國足協盃決賽以5:2擊敗拜仁慕尼黑奪冠，是俱樂部歷史上首次成為國內雙冠王。",
  vt1: '活動現場',
  vt2: '華倫西亞俱樂部',
  vt3: '媒體報導',
  vt4: '精彩瞬間',
  vt5: '球星閃耀',
  vt6: '業界最佳',
  vt7: '球隊成就',
  des1: '亞洲地區獨家合作夥伴',
  des2: '官方合作夥伴',
  vp3: '<b>我們非常高興地宣布，PP88與瓦倫西亞俱樂部達成了戰略合作協議。</b><br/>作為全球知名的體育品牌，PP88一直致力於提供最優質的產品和服務，為運動員和球迷創造無限可能。我們相信，PP88與瓦倫西亞俱樂部的合作將為足球界帶來新的突破和機遇。共同為球迷們創造更加激動人心的體驗，為足球運動的發展貢獻我們的力量。PP88與瓦倫西亞俱樂部，共創輝煌！',
  vp4: '瓦倫西亞足球俱樂部於1919年3月18日成立，目前參加西班牙足球甲級聯賽，是一支具有悠久歷史的老牌球隊。自從1930/31賽季贏得西乙冠軍並晉升至西甲後，該俱樂部已在聯賽中保持持續存在。20世紀60年代是該隊的黃金時代。在1961/62年的第四屆國際城市博覽會杯中，該隊攻入33個進球，創下了國際城市博覽會杯進球最高紀錄，其中包括對布達佩斯MTK的10-3大勝以及在決賽中以7-3橫掃巴塞羅那。第二年，他們連續獲得了國際城市博覽會杯冠軍。',
  hp4: '在2023年5月，BA88體育平台和多特蒙德俱樂部達成了戰略合作。我們的合作目標是提升BA88體育品牌效應並在全球範圍內推廣西甲聯賽。作為一支新興力量，我們致力於創建頂級產品並與頂尖合作夥伴合作。我們相信雙方的合作定將為彼此的未來鋪平道路，充滿光明。',
  hp5: '多特蒙德足球俱樂部，位於德國北萊茵-威斯特法倫州多特蒙德市的著名足球俱樂部，綽號「大黃蜂」。二戰後崛起的多特蒙德和同處北威州魯爾工業區（魯爾區）的沙爾克04之間的比賽因緊鄰的地理位置、相同的工人階級文化而火爆異常，被稱為「魯爾區德比（魯爾德比、礦區德比）」；球隊在國內近些年成績非常好，和拜仁慕尼黑之間的比賽也被不少球迷稱作「德國國家德比」。多特蒙德曾獲得1次歐洲冠軍聯賽冠軍，1次歐洲優勝者杯冠軍，1次豐田杯冠軍和8次德國頂級聯賽冠軍。',
  vt0: '品牌升級',
  hp6: 'PP88品牌是由BA88升級而來<br/>延續其精髓的同時融入更多創新，為您帶來全新的體驗。',
  vd2: '多特蒙德俱樂部',

  mobile1: 'PP88集團',
  mobile2: '首頁',
  mobile3: '平台概述',
  mobile4: '贊助',
  mobile5: '公益慈善',
  mobile6: '優惠獎勵',
  mobile7: '遊戲種類',
  mobile8: '責任博彩',
  mobile9: '用戶體驗',

  pc3: '平台概述',
  pc4: '贊助',
  pc5: '公益慈善',
  pc6: '優惠獎勵',
  pc7: '遊戲種類',
  pc8: '責任博彩',
  pc9: '用戶體驗',

  home1: 'PP88體育擁有頒發的合法執照，是受國際行業協會認可的合法公司。進行註冊並娛樂前，請確保您年滿18周歲！',
  home2: '版權所有 ©2020-2023 PP88保留所有權',
  home3: 'PP88公益送福利',
  home4: '社會貢獻 公益活動',
  home6: '成長歷程 崛起之路',
  home7: 'PP88娛樂集團',
  home8: 'PP88是最值得信賴的在線娛樂城，在亞洲地區擁有數百萬用戶。不僅在香港，我們也被認為是亞洲頂級的在線賭場之一。加入我們的在線博彩網站非常簡單，只需在網站上註冊賬號，即可開始享受您喜愛的在線賭場遊戲。',
  home10: 'BA88品牌在過去已經贏得了廣大用戶的喜愛和信任，它的成就不僅體現在市場表現上，也體現在其高質量的合作夥伴關係上。 BA88曾是多特蒙德俱樂部在亞太地區的讚助商，這顯然反映了該品牌的廣泛影響力和其在行業內的重要地位。',

  home11: '關於PP88',
  home12: '總的來說，NewCenturyGamingGroup通過其運營的PP88，BA88，OB88以及PP88SPORTS等品牌，為全球的用戶提供了無與倫比的博彩體驗，同時也充分展示了該公司的創新精神，以及其在追求用戶滿意度和業務成功方面的堅定決心。',
  home13: '多元遊戲',
  home14: '多種遊戲 體驗順暢',
  home15: '用戶體驗',
  home16: '服務優勢 口碑巨佳',
  home17: '平均存款時間',
  home18: '合作支付平台',
  home19: '平均取款時間',
  home20: '合作遊戲平台',
  home21: '極速存取轉款',
  home22: '最新技術自主研發的財務處理系統，多種入款渠道，自動免轉錢包，精簡出款流程，真正做到極速存，取，轉。',
  home23: '海量賽事種類',
  home24: '每天為您提供足球、籃球、電競、網球、斯諾克等千場精彩體育賽事，更有真人、彩票、棋牌、電子遊戲等多種娛樂方式可供選擇。',
  home25: '加密安全管理',
  home26: '獨家開發，採用128位加密技術和嚴格的安全管理體系，多種智能防盜措施，會員帳戶24小時全方位防護，會員資金得到最為完善的保障。',
  home27: '多端口選擇',
  home28: '自主研發了全套終端應用，更有精心打造的IOS，Android原生app，讓您隨時隨地關注賽事熱點，輕鬆休閒暢享娛樂投注。',
  home29: '用戶評價',
  home30: '好評如潮 信譽良好',
  home31: '提款速度超級快',
  home32: 'PP88的提款速度超級快，1分鐘左右我的提款就到賬了，這是我在其他平台從來沒有試過的，非常給力',
  home33: '無法想像的超高返水',
  home34: 'PP88是我用過最好的平台！他們的返水是我接觸過的平台最好的了。不愧是號稱業界最高返水的平台！',
  home35: 'PP88，值得信賴',
  home36: '我一直在玩AE的百家樂，開始還擔心，後來我開了AE官網對比PP88的結果，全部一樣的，其他對比也是，公平公正值得信任。',
  home37: '快速響應的客戶服務值得稱讚',
  home38: '上次我的賬戶出了一些問題，聯繫上他們的客服後，問題在短時間內就得到了解決。實打實的5星好評!',
  home39: '一定要轉來PP88！',
  home40: '真的很開心轉到PP88這個平台上，賠率高，獎勵又豐富。眼見為實，一定要親自體驗！',
  home41: '他們讓我感覺自己是真正的VIP！',
  home42: '他們為VIP提供了遠超期望的優質服務，還不時給予獨家獎勵和福利。我也就此成為了PP88的忠實會員！',
  home43: '意想不到的驚喜',
  home44: '那天我玩slot中了個大獎，但我不知道有一個活動可以加領88.88%的獎金，PP88發站內短信通知我，我愛你！',
  home45: '你無法想像的高賠率！',
  home46: 'PP88是我試過最好的平台！他們有著最高的賠付率和豐厚的獎勵。',
  home47: '我首選的加密貨幣賭場',
  home48: '我可以在PP88上很順利地存入和提取加密貨幣。其他平台並沒有加密貨幣這個選項。',
  home49: '百玩不厭',
  home50: 'PP88是所有平台中提供最多遊戲選擇的！體育博彩、真人賭場、電子遊戲應有盡有。PP88讓我百玩不厭。',
  home51: '關注我們',
  home52: '下鄉送慈善物資',
  home53: '慈善公益現場',
  home54: '慈善受助方負責人',
  home55: '贈予慈善物資',
  home56: '慰問兒童',
  home57: '與受助方住持合影',
  home58: '慰問貧困人員',
  home59: '贈予公益物資',
  home60: '員工與留守兒童合影',
  home61: 'PP88集團員工合影',
  home62: "行業最高賠率體育集結，賽事全覆蓋，讓球、大/小、15分鐘進球、波膽/反波膽、獨贏等玩法一應俱全；創新功能提前兌現，無需等待即可提前結算，止損機制讓您百無禁忌；操作簡單，開盤最快，秒速接單，最快結算，讓您時刻享受激情！",
  home63: "全網最美荷官，各國佳麗傾情出演，獨創娛樂&專業雙模式，極致高清視覺盛宴，特色遊戲種類：勁舞百家樂、VIP包桌、咪牌百家樂、美女主播等在線陪玩，多國語言任意切換，陪您共赴輝煌，使您流連忘返！",
  home64: "熱門電競場館每日獨家大量賽事滾球盤口，玩法多元、結算快捷，盤內賽事直播讓您分秒精彩；具體賽事分析、數據統計、實時更新的戰況記錄，使您運籌帷幄之中決勝千里之外；更有小遊戲獨特玩法，激情不間斷，比賽之餘小憩更精彩！",
  home65: "熱門電競場館每日獨家大量賽事滾球盤口，玩法多元、結算快捷，盤內賽事直播讓您分秒精彩；具體賽事分析、數據統計、實時更新的戰況記錄，使您運籌帷幄之中決勝千里之外；更有小遊戲獨特玩法，激情不間斷，比賽之餘小憩更精彩！",
  home66: "海量老虎機遊戲匯聚，堅持打造精品；遊戲採取精妙設計，創規則新玩法，超高爆率倍數，免費旋轉，多重累積獎金，精美的3D畫質，為您提供卓越極致的電子遊戲體驗！",
  home67: "多款捕魚遊戲極具特色，用於創新，3D動態場景炫酷技能，最高600倍賠率可4人同屏競技，智能掛機自動鎖定目標魚類，為您打造卓越極致的代入感和身臨其境的遊戲體驗！",
  home68: "多元化棋牌首創娛樂休閒新玩法在線搶紅包，玩法新穎；搶莊牛牛、龍虎鬥、21點、麻將、萬人、電游、捕魚等應有盡有，多款棋牌任君選、棋牌彰顯胸懷，使您樂在其中！",
  home78: "創新玩法，超高賠率，快樂彩大小升跌指數，時時彩大小對吃，極速開彩結算，令您全身心的投入特色玩法，盡情體驗每60秒/90秒開彩樂趣！",
  
  home69: 'APP下載',
  home70: "獨家原生 支持全端",
  home71: "掃碼下載APP Android & IOS",
  home72: "現在註冊以獲得無限獎勵、優惠和獎品。",
  home73: "合作夥伴",

  home74: "參加活動人員留影",
  home75: "送福利，送愛心",
  home76: "工作人員留影",
  home77: "發放慈善物資",

  platform1: '詳情',
  platform2: 'PP88是最值得信賴的在線娛樂城，在亞洲地區擁有數百萬用戶。不僅在香港，我們也被認為是亞洲頂級的在線賭場之一。加入我們的在線博彩網站非常簡單，只需在網站上註冊賬號，即可開始享受您喜愛的在線賭場遊戲。\n\nPP88是亞洲地區特別是香港地區一家知名的博彩品牌，是New Century Gaming Group旗下的一個子品牌，New Century Gaming Group 是一家具有前瞻性的在線博彩公司，擁有廣泛的業務範圍和豐富的品牌組合，包括PP88，BA88，O以及PP88 SPORTS等，數百萬會員遍布全球。\n\n其中，PP88品牌是從BA88品牌升級而來的(訪問: https://BA88.COM 可直接進入PP88) ，這標誌著New Century Gaming Group不斷追求創新和優化產品的堅定決心。',
  platform3: 'PP88品牌',
  platform4: 'BA88品牌在過去已經贏得了廣大用戶的喜愛和信任，它的成就不僅體現在市場表現上，也體現在其高質量的合作夥伴關係上。 BA88曾是多特蒙德俱樂部在亞太地區的讚助商，這顯然反映了該品牌的廣泛影響力和其在行業內的重要地位。\n\n通過升級為PP88，我們看到了New Century Gaming Group 的新視野和新戰略。這種品牌升級不僅代表了其對市場動態的敏銳洞察，也代表了其在提供用戶體驗和服務上的決心。\n\nNew Century Gaming Group 運營的各大品牌，包括PP88，為用戶提供了全方位的在線博彩體驗，從體育博彩到虛擬體育，從賽馬到在線賭場，再到撲克等在線遊戲。其豐富的產品線和高質量的服務使得公司在競爭激烈的博彩市場中脫穎而出，得到了廣大用戶的認可。',
  platform5: '總的來說，NewCenturyGamingGroup通過其運營的PP88，BA88，OB88以及PP88SPORTS等品牌，為全球的用戶提供了無與倫比的博彩體驗，同時也充分展示了該公司的創新精神，以及其在追求用戶滿意度和業務成功方面的堅定決心。\n\nPP88是否合法？\n目前，PP88的總部設在菲律賓首都馬尼拉，這是全球賭博業的中心。同時，PP88也在該國政府的監管下運營，該博彩網站還被該國政府認可為一家值得信賴、質量高且可靠的遊戲場所。\n\nPP88援用菲律賓博彩執照—PAGCOR（PhilippineAmusementandGamingCorporation），PAGCOR是菲律賓有合法經營權的權威組織。\n\nPP88還與世界頂級的網絡安全和審計系統IOVATION合作，確保您的賬戶安全隱私和信息保密。\n\nPAGCOR的運營在亞洲區域始終保持前端，也將為廣大持牌者提供更好發展前景，同時為亞太地區的博彩業務與世界博彩市場接軌提供穩定的橋樑。',
  charity1: '這裡有一個美好的世界！',
  charity2: '我們相信每個微笑都有其背後的故事。',
  charity3: '我們致力於做正確的事。我們致力於成為一股向上向善的力量。因此，PP88員工和品牌一直以來堅持天天向善。',
  charity4: '我們的社會貢獻',
  charity5: '我們的目標是努力改善所觸達社區的健康與福祉。',
  charity6: '救災幫扶',
  charity7: '助醫扶殘',
  charity8: '安老助孤',
  charity9: 'PP88捐贈行動覆蓋範圍超過15個國家/地區的人們，為養老院、避難所、社區團體、食物銀行等機構組織提供了幫助。',
  charity10: '至今，已有超過10,000名兒童通過我們的項目受益，其中80%的兒童在學業上有顯著進步。',
  charity11: '我們已經幫助了5,000多名老人改善了他們的生活質量，其中70%的老人表示感到更加快樂和有社交聯繫。',
  charity12: '作為一家企業，我們有責任回饋需要幫助的人們。',
  charity13: '我們的目標，為弱勢群體帶來持久的正面改變。',
  charity14: '在這個快速變化的世界裡，我們的品牌不僅僅追求商業成功，更承擔起社會責任，將愛心傳遞給每一個需要幫助的角落。我們相信，即使是最小的善舉，也能點亮希望的火花。',
  charity15: '感謝我們的合作夥伴\n他們的支持讓我們的夢想成為可能。',
  charity16: '亞洲區官方合作夥伴',
  charity17: "我们相信每个微笑都有其背后的故事。",
  charity18: "這裡有一個美好的世界！",

  game1: '熱門遊戲',
  game2: '品類齊全 火爆推薦',
  game3: 'PP88是專業提供體育電競、真人娛樂、彩票投注等的正規博彩網站，為每一位用戶提供最完善的服務和最快樂的時光是我們的訴求和責任。而我們也強烈希望玩家在遊戲過程中保持娛樂心態，PP88將帶給您極致的用戶體驗、超本土化的個性服務和顛覆性的博彩產品，讓所有玩家在PP88酣暢淋漓。',
  game4: '體育',
  game5: '真人',
  game6: '電競',
  game7: '老虎機',
  game8: '彩票',
  game9: '捕魚',
  game10: '棋牌',
  resp1: 'PP88 是一家在亞洲著名的在線投注平台，持有菲律賓博彩執照—PAGCOR（Philippine Amusement and Gaming Corporation），是菲律賓有合法經營權的權威組織。 PP88經過驗證並獲得國際在線投注協會的許可證，承諾確保客戶的個人信息安全隱私和信息保密。',
  resp2: '負責任博彩理念|PP88信譽造就品牌',
  resp3: 'PP88是一家亚洲知名且备受吸引的在线游戏平台。\n该平台始终将玩家的利益和权益放在首位，同时提供一个吸引人、公正和健康的博彩环境。PP88作为一家负责任的博彩平台，已经为该公司树立了品牌形象。\nPP88的负责任博彩理念\n提及博彩这两个词，人们往往只会想到其负面的方面。然而，实际情况并非如此，只要我们知道如何正确对待博彩。博彩实际上只是一种娱乐游戏，可以帮助您在繁忙的工作后放松身心。\n如果过度沉迷于博彩，您将陷入迷茫并成为一个赌博成瘾者。这已经成为一个需要解决的社会问题。\n因此，为了提供负责任的博彩环境，PP88会提出一系列问题供玩家在体验平台之前进行自我评估。以下是在体验平台之前，您需要回答的问题：\n您的博彩活动是否占用了过多的工作或学习时间？\n您是否曾因博彩而破产？\n您输掉后的心理恢复程度如何？\n您是否曾经动用家庭所有财产进行博彩活动？\n您是否曾经对博彩金额进行虚报？\n如果您输掉了大量资金并且无法承担这些债务，您是否考虑过从事非法活动或作弊？\n如果您赢得了大笔金额，您是否感到贪婪并变得对博彩活动比工作或家庭更上瘾？\n如果您曾有过对上述问题的思考，那么您必须调整自己的心态、状态和习惯。只有这样，玩家才能意识到如何进行健康的博彩，不贪婪于胜利，不受挫于失败。',
  resp4: 'PP88负责任博彩规则',
  resp5: 'PP88承诺始终自信并确保满足早期设定的三项标准：诚实、透明和合法。此外，参与者需要积极合作并严格遵守由该平台设定的要求。\n为了建立一个负责任的博彩平台，PP88制定了以下规则：\n年龄限制：PP88的会员必须年满18岁才能注册并登录账户以体验游戏。\n每个参与者只能创建一个游戏账户进行投注。如果发现您有多个账户，平台将封锁您的账户，并取消您之前的所有获胜注单。\n在参与PP88负责任博彩过程中，玩家必须意识到保密个人信息的重要性，如账户名、登录密码、用户名、关联银行账户等。这些信息被确定为不得向外界透露。\n绝对禁止欺诈行为。如果发现在博彩过程中作弊，平台有权拒绝登录，并有权永久停用您的游戏账户。\n平台保留拒绝登录和参与所有已发布游戏的权利，如果发现有欺骗行为或提供不准确的信息。\n在以下情况下，人为因素导致错误或系统遇到故障时，平台将作出最终决定。\n如果网站因客观原因（如灾害或黑客入侵）而发生故障，解决方案是玩家应始终备份数据以供跟踪、检索和必要时投诉使用。\n如果不幸发生争议，玩家应立即检查自己的账户信息，最好在投注结束之前。如果发现异常情况，可以立即联系平台或客户服务部门以解决问题。',
  resp6: '6条关于有责任的PP88投注的建议：',
  resp7: '在了解了PP88有责任的投注信息和规则之后，该平台还为玩家提供以下6个建议：\nPP88有责任的投注只是一种在线娱乐投注游戏。玩家应该把它当作一种娱乐方式，绝对不要沉迷其中并立即摒弃赢回投注的念头当输掉投注时。\n有责任的PP88投注：玩家必须明确了解游戏规则和玩法。此外，玩家可以向有经验的人或专家寻求意见，以提高投注的胜率。\n有责任的PP88投注：尊敬的玩家们应该始终保持清醒和敏锐的头脑，以减少损失。\n有责任的PP88投注：玩家必须有良好的资金管理策略。风险是不可避免的，但如何限制风险取决于玩家自己。\n有责任的PP88投注：将责任放在首位，限制参与投注的时间，以免影响工作和日常生活。\n有责任的PP88投注：玩家必须自我监督和管理时间与金钱，确保与个人收入相平衡。\n有责任的PP88投注绝对不建议玩家过度沉溺于在线游戏，无论是PP88还是其他任何投注网站。通过确立有责任的投注环境，使得所有参与者都非常满意并对该平台产生了信任感。',

  footer1: "本網站由Westward Way Tech N.V.公司運營，註冊編號：168306，註冊地址：Parkeerplaats Dienst Openbare Scholen  本網站經營牌照號：N.V.#1168/JAZ，由庫拉索政府",
  footer2: "授權及監管的Gaming Service Provider頒發。本網站運營相關的管理、支付、及支持服務等事宜，均由正規合法公司提供。",
  footer3: "進行註冊並娛樂前，請確保您年滿18周歲！  博娛易上癮，請注意節制。",
  footer4: " ©2023 PP88 版權所有"
};
