<template>
    <Title :title="$t('home13')" :subTitle="$t('home14')" class="mt-54px mb-60px" />

    <div class="box">
        <div class="tab">
            <div 
                v-for="(item, index) in tabs" 
                :key="item.icon"
                :class="{active: item.value === activeTab.value}"
                :style="{backgroundImage: item.value === activeTab.value ? `url(${require('@/assets/images/pc/home/' + item.iconActive + '.png')})` : ''}"
                @click="handleTab(item,index)">
                <span :style="{backgroundImage: `url(${require('@/assets/images/pc/home/' + item.icon + '.png')})`}"></span>
                <span>{{ item.label }}</span>
            </div>
        </div>

        <el-carousel :autoplay="false" indicator-position="none" ref="myCarousel" :loop="false" height="460px" class="mt-14px">
            <el-carousel-item v-for="(item, index) in list" :key="index">
                <div class="w-100% flex grid-items-center">
                    <img :src="item.img" class="w-700px">
                    <div class="ml-40px">
                        <img :src="item.title" class="h-146px">
                        <p class="mt-44px color-#97A7B6 text">
                            {{ item.text }}
                        </p>
                    </div>
                </div>
            </el-carousel-item>
        </el-carousel>
    </div>
</template>

<script setup>
import {computed, ref} from "vue";
import {useI18n} from "vue-i18n";
import Title from '../../components/title.vue'

const {t} = useI18n()
const myCarousel = ref(null)

const tabs = computed(() => {
    return [
        {
            label: t('game4'),
            icon: "game1",
            iconActive: 'game1-active',
            value: 1
        },
        {
            label: t('game5'),
            icon: "game2",
            iconHover: 'game2-hover',
            iconActive: 'game2-active',
            value: 2
        },
        {
            label: t('game6'),
            icon: "game3",
            iconActive: 'game3-active',
            value: 3
        },
        {
            label: t('game7'),
            icon: "game4",
            iconActive: 'game4-active',
            value: 4
        },
        {
            label: t('game8'),
            icon: "game5",
            iconActive: 'game5-active',
            value: 5
        },
        {
            label: t('game9'),
            icon: "game6",
            iconActive: 'game6-active',
            value: 6
        },
        {
            label: t('game10'),
            icon: "game7",
            iconActive: 'game7-active',
            value: 7
        },
    ]
})
const activeTab = ref(tabs.value[0])

const list = computed(() => {
    return [
        {
            img: require('@/assets/images/pc/home/polybasic-game-img1.webp'),
            title: require('@/assets/images/pc/home/polybasic-game-title1.webp'),
            text: t('home62')
        },
        {
            img: require('@/assets/images/pc/home/polybasic-game-img2.webp'),
            title: require('@/assets/images/pc/home/polybasic-game-title2.webp'),
            text: t('home63')
        },
        {
            img: require('@/assets/images/pc/home/polybasic-game-img3.webp'),
            title: require('@/assets/images/pc/home/polybasic-game-title3.webp'),
            text: t('home64')
        },
        {
            img: require('@/assets/images/pc/home/polybasic-game-img4.webp'),
            title: require('@/assets/images/pc/home/polybasic-game-title4.webp'),
            text: t('home66')
        },
        {
            img: require('@/assets/images/pc/home/polybasic-game-img5.webp'),
            title: require('@/assets/images/pc/home/polybasic-game-title5.webp'),
            text: t('home78')
        },
        {
            img: require('@/assets/images/pc/home/polybasic-game-img6.webp'),
            title: require('@/assets/images/pc/home/polybasic-game-title6.webp'),
            text: t('home67')
        },
        {
            img: require('@/assets/images/pc/home/polybasic-game-img7.webp'),
            title: require('@/assets/images/pc/home/polybasic-game-title7.webp'),
            text: t('home68')
        }
    ]
})

const handleTab = (item, index) => {
    activeTab.value = item
    myCarousel.value.setActiveItem(index)
}
</script>

<style lang="less" scoped>
.box{
    width: 100%;
    height: 622px;
    background: linear-gradient(180deg,#212637, #313647);
    border-radius: 20px;
    box-shadow: 0px 6px 10px 0px rgba(0,0,0,0.16); 
    margin-top: 40px;
    padding: 0 50px;
    .tab {
        display: flex;
        > div{
            width: calc(100% / 7);
            display: flex;
            flex-direction: column;
            align-items: center;
            cursor: pointer;
            span:first-child{
                display: block;
                width: 45px;
                height: 45px;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                margin-top: 15px;
                margin-bottom: 6px;
            }
            span:last-child{
                color: #97A7B6;
                font-size: 18px
            }
            &:hover span{
                color: #fff;
            }
            &:first-child:hover{
                span:first-child{
                    background-image: url("@/assets/images/pc/home/game1-hover.png")!important;
                }
            }
            &:nth-child(2):hover{
                span:first-child{
                    background-image: url("@/assets/images/pc/home/game2-hover.png")!important;
                }
            }
            &:nth-child(3):hover{
                span:first-child{
                    background-image: url("@/assets/images/pc/home/game3-hover.png")!important;
                }
            }
            &:nth-child(4):hover{
                span:first-child{
                    background-image: url("@/assets/images/pc/home/game4-hover.png")!important;
                }
            }
            &:nth-child(5):hover{
                span:first-child{
                    background-image: url("@/assets/images/pc/home/game5-hover.png")!important;
                }
            }
            &:nth-child(6):hover{
                span:first-child{
                    background-image: url("@/assets/images/pc/home/game6-hover.png")!important;
                }
            }
            &:nth-child(7):hover{
                span:first-child{
                    background-image: url("@/assets/images/pc/home/game7-hover.png")!important;
                }
            }

            &.active{
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                height: 148px;
                margin-top: -36px;
                span:first-child{
                    display: none;
                }
                span:last-child{
                    color: #fff;
                    margin-top: 74px;
                }
            }
        }
    }

    :deep(.el-carousel__arrow){
        display: none;
    }

    .text{
        line-height: 30px;
    }
}
</style>