<template>
  <el-dropdown trigger="click" popper-class="lang-dropdown">
        <span class="color-#fff lang-text">
            <img :src="activeLang.img" class="w-30px mr-6px">
            {{ activeLang.text }}
            <el-icon class="el-icon--right"><arrow-down/></el-icon>
        </span>
    <template #dropdown>
      <el-dropdown-menu>
        <el-dropdown-item
            v-for="item in actions"
            :key="item.value"
            @click="handleLang(item)">
          <img :src="item.img" width="30" class="mr-6px">
          {{ item.text }}
        </el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>

<script setup>
import {onMounted, ref} from 'vue'
import {ArrowDown} from '@element-plus/icons-vue'
import {useI18n} from "vue-i18n";

const activeLang = ref({})
const actions = [
  {
    text: '中文',
    value: 'zh_CN',
    img: require('@/assets/images/pc/header/zh.png')
  },
  {
    text: '繁体',
    value: 'zh_TW',
    img: require('@/assets/images/pc/header/tw.png')
  },
  {
    text: 'English',
    value: 'en_US',
    img: require('@/assets/images/pc/header/en.png')
  },
  {
    text: 'Malay',
    value: 'en_MAS',
    img: require('@/assets/images/pc/header/mas.png')
  },
  {
    text: 'Tiếng Việt',
    value: 'vi_VN',
    img: require('@/assets/images/pc/header/vn.png')
  },
];
const {locale} = useI18n()

const handleLang = (item) => {
  locale.value = item.value
  activeLang.value = item;
}
onMounted(() => {
  activeLang.value = actions.find(e => e.value === locale.value)
})
</script>

<style scoped>
.lang-text {
  cursor: pointer;
  display: flex;
  align-items: center;
}
</style>
<style>
.lang-dropdown {
  width: 142px;
  height: 200px;
}
</style>
