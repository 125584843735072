<template>
  <div class="sponsor" :class="lang">
<!--    <div class="nav">-->
<!--      <div @mouseover="mouseOver(index)"-->
<!--           @mouseleave="mouseLeave(index)"-->
<!--           :style="{backgroundColor :showIndex === index?'transparent':''}"-->
<!--           @click="toPages(item.path)" :class="index === 0?'nav-logo-active':'nav-logo'"-->
<!--           v-for="(item,index) in logoList" :key="index">-->
<!--        <img :src="item.imgUrl"/>-->
<!--        <transition name="mybox">-->
<!--          <div class="anmi" v-show="showIndex === index">-->
<!--            <div class="anmi-name">{{ item.name }}</div>-->
<!--            <div class="anmi-desc">{{ item.desc }}</div>-->
<!--          </div>-->
<!--        </transition>-->
<!--      </div>-->
<!--    </div>-->
    <div @wheel="handleWheel">
      <el-carousel class="ba-detail base" ref="baseCarousel" :options="options" id="fullpage" direction="vertical"
                   :autoplay="false" :loop="false" indicator-position="none" @change="change">
        <el-carousel-item v-for="(item,index) in list" :key="index"
                          :style="{background: index > 0?'url(' + bgImg +') no-repeat center center/cover':''}"
                          style="height: 100%">
          <div class="fp-tableCell">
            <div class="ba-detail-list-one" v-if="index === 0">
              <el-carousel
                  @mouseenter="autoplayHandler"
                  ref="carousel"
                  arrow="always"
                  height="100%"
                  :interval="3000" style="width: 100vw;height:100%">
                <el-carousel-item v-for="(banner,index) in item.banner" :key="index">
                  <img :src="banner.imgUrl[$i18n.locale] "/>
                </el-carousel-item>
              </el-carousel>
              <img src="@/assets/images/pc/vanlencia/arr.gif"
                   style="z-index:100;width: 40px;position: absolute;bottom:30px;left: 50%;transform: translateX(-50%)"/>
              <!--                        <img src="@/assets/images/pc/vanlencia/arr.gif" style="z-index:100;width: 40px;position: absolute;bottom:5px;left: 54.5%;"/>-->
            </div>
            <div class="ba-detail-list-item" :style="{backgroundImage: 'url(' + item.bgImg + ')'}"
                 v-else>
              <div class="title" :class="$i18n.locale">{{ item.title }}</div>
              <div v-if="index == 1">
                <div class="ba-detail-list-item-content">
                  <div>
                    <div class="item-video">
                      <videoPlay
                          width="100%"
                          height="100%"
                          src="//pic.li3zq.com/video/vanlencia3.mp4"
                          :poster="poster[$i18n.locale]"
                          v-bind="playerOptions"
                      />
                    </div>
                    <div class="item-article" :class="$i18n.locale" v-html="item.content">
                    </div>
                  </div>
                  <div class="ba-column" style="width:35%;align-items: end;">
                    <img class="item-img" src="@/assets/images/pc/vanlencia/activity1.jpg"/>
                    <img class="item-img" src="@/assets/images/pc/vanlencia/activity2.jpg"/>
                    <img class="item-img" src="@/assets/images/pc/vanlencia/activity3.jpg"/>
                  </div>
                </div>
              </div>
              <div v-if="index == 2">
                <div class="ba-detail-list-item-content">
                  <div>
                    <div class="item-video">
                      <el-carousel
                          ref="carousel"
                          @mouseenter="autoplayHandler"
                          arrow="always"
                          height="424px" :interval="3000">
                        <el-carousel-item v-for="(banner,index) in item.banner" :key="index">
                          <img :src="banner.imgUrl "/>
                        </el-carousel-item>
                      </el-carousel>
                    </div>
                  </div>
                  <div class="ba-column ba-time-line">
                    <div style="margin-bottom: 35px;" class="ba-flex" v-for="(item,index) in timeLine"
                         :key="index">
                      <div class="ba-flex" style="width:70px">
                        <img style="width:100%" :src="item.imgUrl"/>
                      </div>
                      <div style="width:227px;padding-left: 20px;"
                           class="ba-column ba-time-line-item">
                        <p class="ba-time">{{ item.time }}</p>
                        <div class="ba-p" style="color:#da7203;margin-left:0">
                          <span>{{ item.content }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="item-time-line-article" :class="$i18n.locale">
                  {{ item.content }}
                </div>
              </div>
              <div v-if="index == 3"><!-- 新闻 -->
                <div class="ba-detail-list-item-content"
                     style="padding-left: 10px;padding-right:10px;justify-content: center">
                  <div class="ba-new-img" style="width:1150px">
                    <div class="d-new-carousel">
                      <el-carousel @change="(value)=>{return onChange(value,item)}"
                                   ref="carousel"
                                   indicator-position="outside"
                                   arrow="always"
                                   @mouseenter="autoplayHandler"
                                   height="468px" :interval="3000">
                        <el-carousel-item v-for="(banner,index) in item.banner" :key="index">
                          <img :alt="banner.content" style="width:100%"
                               :src="banner.imgUrl[$i18n.locale] "/>
                        </el-carousel-item>
                      </el-carousel>
                    </div>
                    <div class="item-new-bottom">
                      <img :src="item.adImg"/>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="index == 4">
                <div class="ba-detail-list-item-content">
                  <div class="ba-news-img">
                    <div style="width:773px">
                      <el-carousel @change="(value)=>{return onChange(value,item)}"
                                   ref="carousel"
                                   arrow="never"
                                   indicator-position="none" height="434px" :interval="3000">
                        <el-carousel-item v-for="(banner,index) in item.banner" :key="index">
                          <img :src="banner.imgUrl"/>
                        </el-carousel-item>
                      </el-carousel>
                    </div>
                    <div class="item-news-article" style="margin-top:35px">
                      {{ item.banner[item.current].content }}
                    </div>
                  </div>
                  <div class="ba-column"
                       style="width:265px;height: 100%;align-items: end;border-left: 1px solid #f2f2f2;">
                    <img style="height:123px;width: 219px;"
                         @click="onChange(index,item,3)"
                         :class="index == item.current?'custom-indicator-a-img custom-indicator-a-img-active':'custom-indicator-a-img'"
                         :src="banner.imgUrl" v-for="(banner,index) in item.banner" :key="index"/>
                  </div>
                </div>
              </div>
              <div v-if="index == 5">
                <div class="ba-detail-list-item-content" style="padding-top:150px">
                  <div class="item-footboot-img">
                    <div>
                      <el-carousel @change="(value)=>{return onChange(value,item)}"
                                   ref="carousel"
                                   arrow="never"
                                   indicator-position="none" height="321" :interval="3000">
                        <el-carousel-item v-for="(banner,index) in item.banner" :key="index">
                          <img :src="banner.imgUrl[$i18n.locale] "/>
                        </el-carousel-item>
                      </el-carousel>
                    </div>
                    <div class="item-footboot-article">
                      <div>
                        {{ item.banner[item.current].name }}
                      </div>
                      {{ item.banner[item.current].content }}
                    </div>
                  </div>
                  <div class="footboot-img">
                    <img @click="onChange(index,item,4)"
                         :class="index == item.current?'custom-indicator-boot-img custom-indicator-boot-img-active':'custom-indicator-boot-img'"
                         :src="banner.imgUrl[$i18n.locale]" v-for="(banner,index) in item.banner"
                         :key="index"/>
                  </div>
                </div>
              </div>
              <div v-if="index == 6">
                <div class="ba-detail-list-item-content" style="padding-left:100px;padding-right: 100px;">
                  <div class="item-phone">
                    <div>
                      <img src="@/assets/images/pc/vanlencia/app.png"/>
                    </div>
                  </div>
                  <div class="ba-column ba-about-box">
                    <div class="ba-about">
                      <div class="item-title">{{ $t('v1') }}</div>
                      <div class="item-desc">{{ $t('v2') }}</div>
                      <div class="ba-flex item-g" :class="$i18n.locale">
                        <div class="ba-flex item-g-i" v-for="(item,index) in glist" :key="index">
                          <div class="item-g-icon">
                            <img :src="item.icon"/>
                          </div>
                          <div class="item-g-content ba-column" style="color:#da7203">
                            <span class="item-g-content-title">{{ item.title }}</span>
                            <span>{{ item.desco }}</span>
                            <span>{{ item.desct }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="ba-flex ba-about-link">
                        <img class="ba-about-link-img" src="@/assets/images/linklogo.png"/>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="PAGCOR">
                  <img src="@/assets/images/pc/vanlencia/PAGCOR.png">
                </div>
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div>
      <ul class="aside" id="aside">
        <li class="aside-line" style="margin-bottom:40px"></li>
        <li
            class="aside-cr"
            @click="change(index)"
            v-for="(item,index) in list" :key="index"
            :data-menuanchor="'page' +  index "
            :class="pageIndex === index?'active':''">
          <a class="aside-a" @click="$refs.baseCarousel.setActiveItem(index);pageIndex=index"></a>
        </li>
        <li class="aside-line" style="margin-top:40px"></li>
      </ul>
    </div>
  </div>
</template>
<script>
import { videoPlay } from 'vue3-video-play'
import 'vue3-video-play/dist/style.css' 

export default {
  components: {videoPlay},
  computed: {
    lang() {
      return this.$i18n.locale
    }
  },
  data() {
    return {
      poster: {
        zh_CN: new URL('@/assets/poster/poster2.jpg', import.meta.url),
        zh_TW: new URL('@/assets/poster/poster2.jpg', import.meta.url),
        en_US: new URL('@/assets/poster/poster2.jpg', import.meta.url),
        en_MAS: new URL('@/assets/poster/poster2.jpg', import.meta.url),
        vi_VN: new URL('@/assets/poster/poster2.jpg', import.meta.url),
      },
      playerOptions: {
        controlBar: {fullscreenToggle: false},
        // 其他配置...
      },
      showIndex: -1,
      timeLine: [
        {
          imgUrl: new URL("@/assets/images/pc/vanlencia/f1.png", import.meta.url),
          time: this.$t('v6'),
          content: this.$t('v7')
        },
        {
          imgUrl: new URL("@/assets/images/pc/vanlencia/f3.png", import.meta.url),
          time: this.$t('v8'),
          content: this.$t('v9')
        },
        {
          imgUrl: new URL("@/assets/images/pc/vanlencia/f2.png", import.meta.url),
          time: this.$t('v10'),
          content: this.$t('v11')
        },
        {
          imgUrl: new URL("@/assets/images/pc/vanlencia/f4.png", import.meta.url),
          time: this.$t('v12'),
          content: this.$t('v13')
        },
      ],
      logoList: [
        {
          path: "1",
          name: this.$t('h1'),
          desc: this.$t('des1'),
          imgUrl: new URL("@/assets/images/pc/vanlencia/dortmundLOGO.png", import.meta.url),
        },
        {
          path: "2",
          name: this.$t('h2'),
          desc: this.$t('des2'),
          imgUrl: new URL("@/assets/images/pc/dortmund/dortmundLOGO.png", import.meta.url),
        },
      ],
      bgImg: new URL("@/assets/images/pc/vanlencia/bg.webp", import.meta.url),
      pageIndex: 0,
      list: [
        {
          banner: [
            {
              imgUrl: {
                zh_CN: new URL("@/assets/images/pc/vanlencia/banner.jpg", import.meta.url),
                zh_TW: new URL("@/assets/images/pc/vanlencia/TW_banner.jpg", import.meta.url),
                en_US: new URL("@/assets/images/pc/vanlencia/EN_banner.jpg", import.meta.url),
                en_MAS: new URL("@/assets/images/pc/vanlencia/MAS_banner.jpg", import.meta.url),
                vi_VN: new URL("@/assets/images/pc/vanlencia/VN_banner.jpg", import.meta.url),
              },
            },
            {
              imgUrl: {
                zh_CN: new URL("@/assets/images/pc/vanlencia/banner-pc1.jpg", import.meta.url),
                zh_TW: new URL("@/assets/images/pc/vanlencia/banner-pc1.jpg", import.meta.url),
                en_US: new URL("@/assets/images/pc/vanlencia/banner-pc1.jpg", import.meta.url),
                en_MAS: new URL("@/assets/images/pc/vanlencia/banner-pc1.jpg", import.meta.url),
                vi_VN: new URL("@/assets/images/pc/vanlencia/banner-pc1.jpg", import.meta.url),
              },
            },
            {
              imgUrl: {
                zh_CN: new URL("@/assets/images/pc/vanlencia/banner-pc2.jpg", import.meta.url),
                zh_TW: new URL("@/assets/images/pc/vanlencia/banner-pc2.jpg", import.meta.url),
                en_US: new URL("@/assets/images/pc/vanlencia/banner-pc2.jpg", import.meta.url),
                en_MAS: new URL("@/assets/images/pc/vanlencia/banner-pc2.jpg", import.meta.url),
                vi_VN: new URL("@/assets/images/pc/vanlencia/banner-pc2.jpg", import.meta.url),
              },
            },
            {
              imgUrl: {
                zh_CN: new URL("@/assets/images/pc/vanlencia/banner-pc3.jpg", import.meta.url),
                zh_TW: new URL("@/assets/images/pc/vanlencia/banner-pc3.jpg", import.meta.url),
                en_US: new URL("@/assets/images/pc/vanlencia/banner-pc3.jpg", import.meta.url),
                en_MAS: new URL("@/assets/images/pc/vanlencia/banner-pc3.jpg", import.meta.url),
                vi_VN: new URL("@/assets/images/pc/vanlencia/banner-pc3.jpg", import.meta.url),
              },
            },
          ],
        },//1 轮播
        {
          title: this.$t('vt1'),
          bgImg: new URL("@/assets/images/pc/vanlencia/bg.png", import.meta.url),
          content: this.$t('vp3'),
        },//2 活动现场
        {
          title: this.$t('vt2'),
          bgImg: new URL("@/assets/images/pc/vanlencia/bg.png", import.meta.url),
          banner: [
            {
              imgUrl: new URL("@/assets/images/pc/vanlencia/banner1.jpg", import.meta.url),

            },
            {
              imgUrl: new URL("@/assets/images/pc/vanlencia/banners1.jpg", import.meta.url),

            },
            {
              imgUrl: new URL("@/assets/images/pc/vanlencia/banners2.jpg", import.meta.url),
            },
            {
              imgUrl: new URL("@/assets/images/pc/vanlencia/banners3.jpg", import.meta.url),
            },
            {
              imgUrl: new URL("@/assets/images/pc/vanlencia/banners4.jpg", import.meta.url),

            },
          ],
          content: this.$t('vp4')
        },//3 俱乐部
        {
          title: this.$t('vt3'),
          bgImg: new URL("@/assets/images/pc/vanlencia/bg.png", import.meta.url),
          adImg: new URL("@/assets/images/pc/vanlencia/newlogo.jpg", import.meta.url),
          banner: [
            {
              imgUrl: {
                zh_CN: new URL("@/assets/images/pc/vanlencia/ZH_new1.jpg", import.meta.url),
                zh_TW: new URL("@/assets/images/pc/vanlencia/TW_new1.jpg", import.meta.url),
                en_US: new URL("@/assets/images/pc/vanlencia/EN_new1.jpg", import.meta.url),
                en_MAS: new URL("@/assets/images/pc/vanlencia/MAS_new1.jpg", import.meta.url),
                vi_VN: new URL("@/assets/images/pc/vanlencia/VN_new1.jpg", import.meta.url),
              },
            },
            {
              imgUrl: {
                zh_CN: new URL("@/assets/images/pc/vanlencia/ZH_new2.jpg", import.meta.url),
                zh_TW: new URL("@/assets/images/pc/vanlencia/TW_new2.jpg", import.meta.url),
                en_US: new URL("@/assets/images/pc/vanlencia/EN_new2.jpg", import.meta.url),
                en_MAS: new URL("@/assets/images/pc/vanlencia/MAS_new2.jpg", import.meta.url),
                vi_VN: new URL("@/assets/images/pc/vanlencia/VN_new2.jpg", import.meta.url),
              },
            },
            {
              imgUrl: {
                zh_CN: new URL("@/assets/images/pc/vanlencia/ZH_new3.jpg", import.meta.url),
                zh_TW: new URL("@/assets/images/pc/vanlencia/TW_new3.jpg", import.meta.url),
                en_US: new URL("@/assets/images/pc/vanlencia/EN_new3.jpg", import.meta.url),
                en_MAS: new URL("@/assets/images/pc/vanlencia/MAS_new3.jpg", import.meta.url),
                vi_VN: new URL("@/assets/images/pc/vanlencia/VN_new3.jpg", import.meta.url),
              },
            }
          ],
          current: 0,
        },//4 媒体报道
        {
          title: this.$t('vt4'),
          bgImg: new URL("@/assets/images/pc/vanlencia/bg.png", import.meta.url),
          banner: [
            {
              imgUrl: new URL("@/assets/images/pc/vanlencia/wonderful1.png", import.meta.url),
              name: "",
              content: this.$t('v5')
            },
            {
              imgUrl: new URL("@/assets/images/pc/vanlencia/wonderful2.png", import.meta.url),
              name: "",
              content: this.$t('v5')
            },
            {
              imgUrl: new URL("@/assets/images/pc/vanlencia/wonderful3.png", import.meta.url),
              name: "",
              content: this.$t('v5')
            },
            {
              imgUrl: new URL("@/assets/images/pc/vanlencia/wonderful4.png", import.meta.url),
              name: "",
              content: this.$t('v5')
            },
          ],
          current: 0,
        },// 5经常瞬间
        {
          title: this.$t('vt5'),
          height: '535px',
          bgImg: new URL("@/assets/images/pc/vanlencia/bg.png", import.meta.url),
          banner: [
            {
              imgUrl: {
                zh_CN: new URL("@/assets/images/pc/vanlencia/ZH_footboot1.png", import.meta.url),
                zh_TW: new URL("@/assets/images/pc/vanlencia/ZH_footboot1.png", import.meta.url),
                en_US: new URL("@/assets/images/pc/vanlencia/EN_footboot1.png", import.meta.url),
                en_MAS: new URL("@/assets/images/pc/vanlencia/EN_footboot1.png", import.meta.url),
                vi_VN: new URL("@/assets/images/pc/vanlencia/EN_footboot1.png", import.meta.url),
              },
              name: this.$t('v16'),
              content: this.$t('v17')
            },
            {
              imgUrl: {
                zh_CN: new URL("@/assets/images/pc/vanlencia/ZH_footboot2.png", import.meta.url),
                zh_TW: new URL("@/assets/images/pc/vanlencia/ZH_footboot2.png", import.meta.url),
                en_US: new URL("@/assets/images/pc/vanlencia/EN_footboot2.png", import.meta.url),
                en_MAS: new URL("@/assets/images/pc/vanlencia/EN_footboot2.png", import.meta.url),
                vi_VN: new URL("@/assets/images/pc/vanlencia/EN_footboot2.png", import.meta.url),
              },
              name: this.$t('v18'),
              content: this.$t('v19')
            },
            {
              imgUrl: {
                zh_CN: new URL("@/assets/images/pc/vanlencia/ZH_footboot3.png", import.meta.url),
                zh_TW: new URL("@/assets/images/pc/vanlencia/ZH_footboot3.png", import.meta.url),
                en_US: new URL("@/assets/images/pc/vanlencia/EN_footboot3.png", import.meta.url),
                en_MAS: new URL("@/assets/images/pc/vanlencia/EN_footboot3.png", import.meta.url),
                vi_VN: new URL("@/assets/images/pc/vanlencia/EN_footboot3.png", import.meta.url),
              },
              name: this.$t('v20'),
              content: this.$t('v21'),
            },
            {
              imgUrl: {
                zh_CN: new URL("@/assets/images/pc/vanlencia/ZH_footboot4.png", import.meta.url),
                zh_TW: new URL("@/assets/images/pc/vanlencia/ZH_footboot4.png", import.meta.url),
                en_US: new URL("@/assets/images/pc/vanlencia/EN_footboot4.png", import.meta.url),
                en_MAS: new URL("@/assets/images/pc/vanlencia/EN_footboot4.png", import.meta.url),
                vi_VN: new URL("@/assets/images/pc/vanlencia/EN_footboot4.png", import.meta.url),
              },
              name: this.$t('v22'),
              content: this.$t('v23'),
            },
            {
              imgUrl: {
                zh_CN: new URL("@/assets/images/pc/vanlencia/ZH_footboot5.png", import.meta.url),
                zh_TW: new URL("@/assets/images/pc/vanlencia/ZH_footboot5.png", import.meta.url),
                en_US: new URL("@/assets/images/pc/vanlencia/EN_footboot5.png", import.meta.url),
                en_MAS: new URL("@/assets/images/pc/vanlencia/EN_footboot5.png", import.meta.url),
                vi_VN: new URL("@/assets/images/pc/vanlencia/EN_footboot5.png", import.meta.url),
              },
              name: this.$t('v24'),
              content: this.$t('v25'),
            },
          ],
          current: 0,
        }, //6球星闪耀
        {
          title: this.$t('vt6'),
          bgImg: new URL("@/assets/images/pc/vanlencia/bg.png", import.meta.url),
        },//7 业界最佳
      ],
      glist: [
        {
          title: this.$t('v26'),
          icon: new URL("@/assets/images/pc/vanlencia/icon-1.png", import.meta.url),
          desco: this.$i18n.locale == 'zh_CN' || this.$i18n.locale == 'zh_TW' ? this.$t('v27') : '',
          desct: this.$i18n.locale == 'zh_CN' || this.$i18n.locale == 'zh_TW' ? this.$t('v28') : '',
        },
        {
          title: this.$t('v29'),
          icon: new URL("@/assets/images/pc/vanlencia/icon-2.png", import.meta.url),
          desco: this.$i18n.locale == 'zh_CN' || this.$i18n.locale == 'zh_TW' ? this.$t('v30') : '',
          desct: this.$i18n.locale == 'zh_CN' || this.$i18n.locale == 'zh_TW' ? this.$t('v31') : '',
        },
        {
          title: this.$t('v32'),
          icon: new URL("@/assets/images/pc/vanlencia/icon-3.png", import.meta.url),
          desco: this.$i18n.locale == 'zh_CN' || this.$i18n.locale == 'zh_TW' ? this.$t('v33') : '',
          desct: this.$i18n.locale == 'zh_CN' || this.$i18n.locale == 'zh_TW' ? this.$t('v34') : '',
        },
        {
          title: this.$t('v35'),
          icon: new URL("@/assets/images/pc/vanlencia/icon-4.png", import.meta.url),
          desco: this.$i18n.locale == 'zh_CN' || this.$i18n.locale == 'zh_TW' ? this.$t('v36') : '',
          desct: this.$i18n.locale == 'zh_CN' || this.$i18n.locale == 'zh_TW' ? this.$t('v37') : '',
        }
      ],
      options: {
        licenseKey: 'YOUR_KEY_HERE',
        sectionsColor: ['#08255f', '#08255f', '#08255f', '#08255f', '#08255f', '#08255f', '#08255f'],
        anchors: ['page0', 'page1', 'page2', 'page3', 'page4', 'page5', 'page6'],
        slideSelector: '#aside',
        sectionSelector: 'section',
        scrollOverflow: false,
        scrollBar: false,
        beforeLoad: this.change2,
        menu: '#aside',
        credits: {
          enabled: false
        }
      },
      lastScrollTime: 0
    }
  },
  mounted() {

  },
  methods: {
    handleWheel(event) {
      const currentTime = new Date().getTime();
      if (currentTime - this.lastScrollTime > 1000) {
        console.log(event)
        if (event.deltaY > 0) {
          this.$refs.baseCarousel.next();
        } else {
          this.$refs.baseCarousel.prev();
        }
        this.lastScrollTime = currentTime;
      }
    },
    autoplayHandler() {
      this.$refs.carousel.forEach((element, index) => {
        this.$refs.carousel[index].handleMouseEnter = () => {
        };
      });
    },
    mouseOver(index) {
      this.showIndex = index
    },
    mouseLeave() {
      this.showIndex = -1
    },
    toPages(path) {
      this.$emit('change', path)
      // window.location.href = path
    },
    change(e) {
      this.pageIndex = e
    },
    change2(e, f) {
      console.log(e, f.index)
      this.$nextTick(() => {
        this.pageIndex = f.index
      })
    },
    onChange(index, item, key) {
      item.current = index;
      if (key >= 0) {
        this.$refs.carousel[key].setActiveItem(index)
      }
    }
  }
}
</script>
<style lang="css">
::-webkit-scrollbar {
  display: none;
}
</style>
<style scoped>
@import "@/assets/css/reset.css";

.fp-tableCell {
  display: flex !important;
  align-items: center;
  height: 100%;
}

.sponsor {
  overflow: hidden;
  height: 100%;
  font-size: 100%;
}

.sponsor .aside {
  position:  fixed;
  top: 0;
  right: 60px;
  z-index: 20;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.aside-line {
  width: 1px;
  height: 35%;
  background-color: rgba(255, 255, 255, 0.3);
}

.aside-a {
  width: 100%;
  display: block;
  height: 100%;
}

.sponsor .aside .aside-cr {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 10px 0;
  background: #ffffff;
  -webkit-transition: all .3s;
  transition: all .3s;
  cursor: pointer
}

.sponsor .aside .active {
  /* height: 24px; */
  position: relative;
  background-image: url("@/assets/images/icon.png");
  width: 24px;
  height: 24px;
  background-color: transparent;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.sponsor .aside li:last-child {
  margin-bottom: 0
}

.sponsor .list-container {
  position: absolute;
  width: 100%;
  height: 100%
}

.sponsor .list-container .list-bg {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 9;
  background: #fff
}

.nav {
  background-color: rgba(255, 255, 255, 0.4);
  position: fixed;
  left: 2%;
  top: 33%;
  height: 20%;
  width: 60px;
  z-index: 99;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}

.nav-logo-active {
  background-color: rgba(255, 255, 255, 0.6);
  width: 70px;
  height: 70px;
  border-radius: 10px;
  margin-left: 30px;
  position: relative;
}

.nav-logo:hover {
  background-color: transparent !important;
  width: 70px;
  height: 70px;
  border-radius: 10px;
  margin-left: 30px;
  animation: fade .5s;
  animation-timing-function: ease-in-out;
  transition: all .5s linear;
}

.nav-logo {
  position: relative;
  cursor: pointer;
  width: 60px;
  height: 60px;
  padding: 5px;
  margin-bottom: 5px;
  transition: all .5s linear;
}

/* 给过渡的name加样式 */

.mybox-enter-active {
  transition: all 1s ease;
}

.mybox-leave-active,
.mybox-enter {
  width: 0px !important;
}

.mybox-leave,
.mybox-enter-active {
  /* width: 255px; */
  animation: bou 1s;
  animation-timing-function: ease-in-out;
}

.aactive {
  animation: fade .5s;
}

@keyframes fade {
  0% {
    width: 50px;
    height: 50px;
  }
  100% {
    width: 70px;
    height: 70px;
  }
}

@keyframes bou {
  0% {
    width: 0px;
  }
  70% {
    width: 260px;
    height: 85px;
  }
  85% {
    width: 245px;
    height: 75px;
  }
  100% {
    width: 250px;
    height: 80px;
  }
}

.anmi {
  background: linear-gradient(to right, rgba(0, 122, 255, .1), rgb(0, 122, 255, 1));
  top: -5px;
  position: absolute;
  border-radius: 15px;
  left: -10px;
  width: 250px;
  height: 80px;
  overflow: hidden;
  padding-left: 90px;
}

.anmi-name {
  margin-top: 18px;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: 'PingFang SC';
  font-size: 16px;
  color: #ffffff;
}

.anmi-desc {
  margin-top: 2px;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: 'PingFang SC';
  font-size: 12px;
  color: #ffffff;
}

.nav-logo-active img {
  width: 100%;
  height: 100%;
}

.nav-logo img {
  width: 100%;
  height: 100%;
}

.nav-logo img {
  width: 100%;
  height: 100%;
}

.ba-detail {
  display: flex;
  flex-direction: column;
  height: 100%;
}

:deep .ba-detail.base > .el-carousel__container {
  height: calc(100vh - 80px);
}

.ba-detail-swipe {
  height: 100%;
}

.ba-detail-swipe-item {
  width: 100%;
  height: 100%;
}

.ba-detail-swipe-item img {
  width: 100%;
  height: 100%;
}

.section {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.ba-detail-list {
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.ba-detail-list-item {
  height: 700px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 1210px;
  margin: 0 auto;
  position: relative;
}

.ba-detail-list-item .title {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 40%);
  color: #FFFFFF;
  font-size: 30px;
}

.ba-detail-list-item .title.en_MAS,
.ba-detail-list-item .title.en_US {
  top: 10px;
}

.ba-detail-list-one {
  position: relative;
  width: 100%;
  height: 100%;
}

.ba-detail-list-one img {
//height: 100%; width: 100%;
}

.ba-detail-list-item-content {
  padding-top: 100px;
  padding-left: 64px;
  padding-right: 64px;
  display: flex;
  justify-content: space-between;
}

.item-video {
  width: 756px;
  height: 424px;
  border-radius: 10px;
  overflow: hidden;
  background-color: #959595;
}

.item-video video {
  width: 100%;
  height: 100%;
}

.item-video img {
  width: 100%;
  height: 100%;
}

.item-article {
  margin-top: 15px;
  padding: 0 5px;
  color: rgba(51, 51, 51, 1);
  font-size: 14px;
  line-height: 24px;
  color: #000;
  font-family: 'PingFang SC';
  overflow: hidden;
  overflow-y: scroll;
  padding-right: 35px;
  white-space: pre-line;
}

.item-article.en_MAS,
.item-article.en_US {
  margin-top: 20px;
  line-height: 18px;
}

.item-article::-webkit-scrollbar {
  display: none;
}

.item-title {
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  font-family: "Microsoft YaHei";
}

.item-desc {
  text-align: center;
  color: rgba(51, 51, 51, 1);
  font-size: 16px;
  margin-top: 10px;
}

.item-g {
  padding: 0 50px;
  justify-content: space-between;
}

.item-g.en_MAS,
.item-g.en_US {
  padding: 0 10px;
}

.ba-flex {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.ba-column {
  display: flex;
  flex-direction: column;
}

.item-g-icon {
  width: 60px;
  height: 60px;
}

.item-g-icon img {
  width: 100%;
  height: 100%;
}

.item-g-i {
  width: 50%;
  margin: 27px 0;
}

:deep .el-carousel--horizontal {
  height: 100%;
}

.item-g-content {
  margin-left: 9px;
  color: #981008;
  font-size: 14px;
  font-family: 'PingFang SC';
}

.item-g-content-title {
  font-size: 21px;
  margin-bottom: 2px;
}

.ba-about {
  width: 100%;
}

.ba-about-link {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ba-about-link-img {
  margin-top: 25px;
  width: 80%;
}

.ba-list-swipe-item {
  width: 100%;
  height: 200px;
}

.custom-indicator-o {
  margin: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.item-name {
  font-size: 20px;
  margin: 5px 0;
  color: rgba(51, 51, 51, 1);
  font-family: 'PingFang SC';
}

.ba-list-swipe-item-a {
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
  height: 180px;
}

.custom-indicator-a {
  margin-top: 10px;
  white-space: nowrap;
  overflow: hidden;
  overflow-x: auto;
}

.custom-indicator-a::-webkit-scrollbar {
  display: none;
}

.custom-indicator-a-img {
  width: 295px;
  display: inline-block;
  border: 4px solid #ffffff;
  height: 167px;
  border-radius: 4px;
  margin-bottom: 10px;
  overflow: hidden;
}

.footboot-img {
  width: 511px;
  display: flex;
  flex-wrap: wrap;
  padding-left: 37px;
}

.custom-indicator-a-img img {
  width: 100%;
  height: 100%;
}

.custom-indicator-a-img-active {
  border: 4px solid #ffd801;
  scale: 1.1;
}

.custom-indicator-boot-img {
  width: 226px;
  display: inline-block;
  border: 4px solid #ffffff;
  height: 127px;
  border-radius: 6px;
  margin-bottom: 10px;
  overflow: hidden;
  margin-left: 10px;
}

.custom-indicator-boot-img img {
  width: 100%;
  height: 100%;
}

.custom-indicator-boot-img-active {
  border: 4px solid #ffd801;
  scale: 1.1
}

.custom-indicator-o-item {
  height: 5px;
  width: 6%;
  background-color: #e0a09e;
  border-radius: 10px;
  margin: 0 3px;
}

.custom-indicator-o-item-active {
  background-color: #c2433d !important;
}

.custom-indicator-l-item {
  height: 5px;
  width: 6%;
  background-color: #accfeb;
  border-radius: 10px;
  margin: 0 3px;
}

.custom-indicator-d-item-active {
  background-color: #ce880e !important;
}

.custom-indicator-d-item {
  height: 5px;
  width: 6%;
  background-color: #e5c386;
  border-radius: 10px;
  margin: 0 3px;
}

.custom-indicator-l-item-active {
  background-color: #599fdb !important;
}

.custom-indicator-f-item {
  height: 5px;
  width: 6%;
  background-color: #e5c386;
  border-radius: 10px;
  margin: 0 3px;
}

.custom-indicator-f-item-active {
  background-color: #ce880f !important;
}

.item-news {
  margin-top: 5px;
  font-size: 12px;
  line-height: 20px;
  overflow: hidden;
  overflow-y: scroll;
}

.van-swipe__indicator {
  width: 15px !important;
  height: 3px !important;
  border-radius: 3px !important;
}

.van-swipe__indicators {
  bottom: 15px !important;
}

.item-img {
  width: 295px;
  height: 166px;
  margin-bottom: 15px;
  border-radius: 10px;
  overflow: hidden;
}

.item-img img {
  width: 100%;
  height: 100%;
}

.item-phone {
  width: 605px;
  display: flex;
  justify-content: center;
}

.item-phone img {
  width: 400px;
  height: 387px;
}

.ba-about-box {
  width: 605px;
}

.PAGCOR {
  width: 1080px;
  margin: 0 auto;
  margin-top: 45px !important;
}

.PAGCOR img {
  width: 100%;
}

.ba-time {
  font-size: 16px;
  font-family: 'PingFang SC';
  color: rgba(102, 102, 102, 1)
}

.line {
  width: 10px;
  height: 3px;
  display: block;
  margin-right: 10px;
}

.ba-p {
  display: flex;
  align-items: center;
  font-family: 'PingFang SC';
  font-size: 16px;
  margin-left: 10px;
}

.ba-time-line {
  min-width: 326px;
  max-width: 356px;
  padding-left: 29px;
  align-items: start;
  line-height: 25px;
}

.ba-time-line-item {

}

.item-list-img-article {
  height: 130px;
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 27px;
  padding-right: 29px;
  color: rgba(51, 51, 51, 1);
  font-family: 'PingFang SC';
}

.item-time-line-article {
  padding: 0 64px;
  margin-top: 10px;
  font-size: 16px;
  line-height: 27px;
  color: rgba(51, 51, 51, 1);
  font-family: 'PingFang SC';
}

.item-time-line-article.en_US,
.item-time-line-article.en_MAS {
  margin-top: 18px;
  line-height: 20px;
}

.ba-list-img {
  width: 326px;
  height: 100%;
  align-items: end;
}

@font-face {
  //font-family: "PingfangBold";
  //src: url("@/assets/font/PingFangBold.woff2");
}

.item-footboot-img {
  width: 571px;
}

.item-footboot-img img {
  width: 100%;
  height: 321px;
}

.item-footboot-article {
  font-size: 16px;
  margin-top: 25px;
  line-height: 24px;
  color: rgba(102, 102, 102, 1);
  font-family: 'PingFang SC';
}

.item-l-article {
  font-size: 14px;
  line-height: 24px;
  color: rgba(51, 51, 51, 1);
  font-family: 'PingFang SC';
  margin-top: 25px;
}

.ba-news-img {
  width: 832px;
}

.ba-news-img img {
  width: 773px;
  height: 430px;
}

.item-news-article {
  width: 780px;
  font-size: 16px;
  color: rgba(102, 102, 102, 1);
  font-family: 'PingFang SC';
}

.item-card-article {
  text-align: center;
  font-size: 16px;
  color: rgba(51, 51, 51, 1);
  font-family: 'PingFang SC';
}

.item-new-bottom {
  margin-top: 5px
}

</style>
<style>
.card-carousel .el-carousel__button {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #599fdb;
}

.card-carousel :deep .el-carousel__arrow--left {
  width: 50px;
  height: 50px;
  background-image: url("@/assets/images/pc/leicester/arrow-left.png");
  background-size: 100% 100%;
  background-color: transparent;
  left: 115px;
  top: 44%;
}

.card-carousel .el-carousel__container .is-active {
  width: 640px;
  transform: translateX(221.5px) scale(1) !important;
}

.card-carousel .el-carousel__arrow i {
  opacity: 0;
}

.card-carousel .el-carousel__arrow--right {
  width: 50px;
  height: 50px;
  background-image: url("@/assets/images/pc/leicester/arrow-left.png");
  background-size: 100% 100%;
  background-color: transparent;
  right: 115px;
  top: 40%;
  transform: rotate(180deg);
  -ms-transform: rotate(180deg); /* IE 9 */
  -moz-transform: rotate(180deg); /* Firefox */
  -webkit-transform: rotate(180deg); /* Safari 和 Chrome */
  -o-transform: rotate(180deg); /* Opera */
}

.d-new-carousel .el-carousel__arrow--left {
  left: 0px
}

.d-new-carousel .el-carousel__arrow--right {
  right: 0px
}

.d-new-carousel img {
  padding: 0 50px;
  margin: 0 auto;
}

.ba-detail-list-one .el-carousel__arrow--left {
  left: 150px;
}

.ba-detail-list-one .el-carousel__arrow--right {
  right: 150px;
}

.c-new-carousel img {
  height: 100%;
}

.c-new-carousel .el-carousel__button {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #981008;
}

.d-new-carousel .el-carousel__button {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #da7203;
}

.l-new-carousel .el-carousel__button {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #599fdb;
}

.el-carousel__arrow {
  background-color: rgba(0, 0, 0, .5) !important;
}

.vi_VN .item-time-line-article{
  font-size: 14px;
  line-height: 20px;
}
</style>
