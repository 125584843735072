export default {
    v1: '专业的原生娱乐APP',
    v2: '高效率 最安全 最极致的用户体验',
    v3: '<b>我们非常高兴地宣布，PP88与瓦伦西亚俱乐部达成了战略合作协议。</b><br/>作为两个领域中的领军者，我们相信这次合作将为球迷们带来更加难忘的体验。作为全球知名的体育品牌，PP88一直以来致力于提供最优质的产品和服务，为运动员和球迷们创造无限可能。瓦伦西亚俱乐部则是西班牙足球的传奇之一，拥有众多忠诚的球迷和杰出的球队成绩。我们相信，PP88与瓦伦西亚俱乐部的合作将为足球界带来新的突破和机遇。我们期待着与瓦伦西亚俱乐部的合作，共同为球迷们创造更加激动人心的体验，为足球运动的发展贡献我们的力量。PP88与瓦伦西亚俱乐部，共创辉煌！',
    v4: '瓦伦西亚足球俱乐部，成立于1919年3月18日，位于西班牙的第三大城市瓦伦西亚，现参加西班牙足球甲级联赛，瓦伦西亚是一支有着悠久历史的老牌球队。瓦伦西亚是欧洲闻名的劲旅之一，绝大部分时间都是在西甲中度过的，自从1930/31赛季夺得西乙冠军升上西甲之后，长时间参加西甲联赛，直到1985/86赛季意外降级，其后即以西乙冠军身份重返西甲，自此球队一直参加西甲联赛。60年代为球队最光辉的时刻，1961/62第四届国际城市博览会杯，球队攻入33个进球，创造了国际城市博览会杯比赛的进球最高纪录，当中包括8强10:3狂胜布达佩斯MTK，决赛7:3横扫巴塞罗那。第二年，蝉联了国际城市博览会杯的冠军。',
    v5: '瓦伦西亚曾赢得6次西甲冠军、8次西班牙国王杯冠军、3次欧洲足协杯冠军、1次欧洲杯赛冠军杯冠军和2次欧洲超霸杯。瓦伦西亚是西班牙传统五强之一，瓦伦西亚也是G-14成员之一。',
    v6: '西班牙足球甲级联赛',
    v7: '6次冠军',
    v8: '西班牙国王杯',
    v9: '8次冠军',
    v10: '西班牙超级杯',
    v11: '1次冠军',
    v12: '欧洲联盟杯',
    v13: '1次冠军',
    v14: '欧洲超级杯冠军',
    v15: '2次冠军',
    v16: '埃丁森·卡瓦尼 Edinson Roberto Cavani Gómez',
    v17: '出生于1987年2月14日，是一名乌拉圭职业足球运动员，司职前锋，现效力于西甲球队瓦伦西亚。',
    v18: '沙姆·卡斯蒂列霍 Samu Castillejo',
    v19: '出生于1995年1月18日，是一位西班牙足球运动员。在场上的位置是右边锋。现效力于西甲球队瓦伦西亚。他曾代表西班牙国家青年足球队参赛。',
    v20: '穆塔尔·迪亚卡比 Mouctar Diakhaby',
    v21: '出生于1996年12月19日，是一名职业足球运动员，司职中后卫，现效力于西甲俱乐部瓦伦西亚。出生于法国。',
    v22: '安德烈·阿尔梅达 Domingos André Ribeiro Almeida',
    v23: '出生于2000年5月30日，葡萄牙职业足球运动员，司职中场，效力于西甲球队瓦伦西亚。',
    v24: '埃尔文·科梅尔特 Eray Ervin Cömert',
    v25: '出生于1998年2月4日，是一名瑞士职业足球运动员，担任西甲俱乐部瓦伦西亚的后卫。',
    v26: '更安全',
    v27: '独家网络技术',
    v28: '超强防御劫持',
    v29: '更丰富',
    v30: '丰富的游戏产品',
    v31: '您想要的我们都有',
    v32: '更稳定',
    v33: '强大的技术团队',
    v34: '提供最稳定的产品',
    v35: '更私密',
    v36: '三重数据加密',
    v37: '保护您的数据安全',

    d3: '2019年5月，BA88体育平台和德甲多特蒙德俱乐部达成战略合作，本次合作是BA88体育迈向国际化的一大步，也是年轻的BA88体育平台与老牌德甲豪门的一次激情合作。我们的合作目标是提高BA88体育品牌效应并在全球范围内推广德甲联赛。当一支新锐力量致力于打造一流产品、与顶尖合作伙伴、塑造杰出品牌形象，它已准备好向上攀升，主宰未来。相信双方此次的合作定会让彼此的前途更加坦荡无阻、一片光明。',
    d4: '多特蒙德足球俱乐部，位于德国北莱茵-威斯特法伦州多特蒙德市的著名足球俱乐部，绰号「大黄蜂」。二战后崛起的多特蒙德和同处北威州鲁尔工业区（鲁尔区）的沙尔克04之间的比赛因紧邻的地理位置、相同的工人阶层文化而火爆异常，被称为「鲁尔区德比（鲁尔德比、矿区德比）」；球队在国内近些年成绩非常好，和拜仁慕尼黑之间的比赛也被不少球迷称作「德国国家德比」。多特蒙德曾获得1次欧洲冠军联赛冠军，1次欧洲优胜者杯冠军，1次丰田杯冠军和8次德国顶级联赛冠军。',
    d5: '2012年多特蒙德再次获得德甲冠军，并以81分成为德甲史上最高分的冠军。同时也在德国足协杯决赛以5:2击败拜仁慕尼黑夺冠，是俱乐部历史上首次成为国内双冠王。',
    d6: '2011年',
    d7: '德国足球甲级联赛，获得冠军',
    d8: '2012年',
    d9: '德国足球甲级联赛，成功卫冕冠军',
    d10: '2012/13赛季欧洲冠军联赛',
    d11: '德国足球甲级联赛，对阵拜仁慕尼黑，惜败，获得亚军',
    d12: '2014年',
    d13: '德国超级杯，击败拜仁慕尼黑获得冠军',
    d14: '马尔科·罗伊斯（Marco Reus）',
    d15: '1989年5月31日出生于德国多特蒙德，德国足球运动员，场上司职中场/前锋，效力于德甲的多特蒙德足球俱乐部，亦担任球队队长',
    d16: '乔瓦尼·雷纳（Giovanni Reyna）',
    d17: '2002年11月13日出生于英格兰达勒姆，美国足球运动员，场上司职中场，现效力于德甲多特蒙德足球俱乐部',
    d18: '尼科·舒尔茨（Nico Schulz）',
    d19: '1993年4月1日出生于柏林，是一名德国职业足球运动员，场上位置为左后卫，现效力于德甲多特蒙德足球俱乐部',
    d20: '费利克斯·帕斯拉克（Felix Passlack）',
    d21: '1998年5月29日出生于德国波特洛普，德国职业足球运动员，场上司职右后卫，现效力于德甲的多特蒙德足球俱乐部',
    d22: '托马斯·默尼耶（Thomas Meunier）',
    d23: '1991年9月12日出生于比利时圣奥德，比利时足球运动员，司职右后卫/翼卫，效力于德甲多特蒙德足球俱乐部',
    p3: '品牌赞助',
    p4: '本公司PP88体育所有产品与客户服务、软件系统、网络架构等相关业务事宜，皆由菲律宾政府认证机构所授权和监管，我们是一家深受广大在线玩家信赖的在线娱乐公司，我们提供了完整的在线娱乐游戏服务给我们的广大会员，并且我们也拥有国际级的娱乐信息专家及市场策略专家，可以让会员在游戏使用上更加方便，让娱乐项目能够专业化，我们坚持以公正、安全的信念为客户在网上打造别树一幟的娱乐品牌。我们备有完整的监督机制及严肃的处理规定，以确保所有会员的游戏娱乐都能在公平、公正、公开的状态下进行，我们使用了极好的安全技术特性，能在玩家和游戏之间的信息传递上毫无漏洞，复杂的算法来进行数据加密，为了就是希望能保护玩家的所有账户数据和游戏结构正确、公平与公正。为了让我们尊贵的客户可以最方便、最快速地得到所有关于线上游戏与会员权益等相关问题，我们特别设立24小时客户服务中心，以最亲切、最礼貌的服务宗旨来为玩家解答所有问题，不管是任何疑问，推广意见，赞扬或投诉，我们的客服中心都欢迎您随时在线光临。',
    p5: '媒体报道 品牌合作',
    p6: '各大媒体平台争相报道PP88体育与瓦伦西亚俱乐部的签约盛况，这些报道的出版进一步提升了PP88体育平台在世界上的品牌影响力。',
    p7: '合法经营 政府监管',
    p8: '关于PAGCOR',
    p9: '菲律宾博彩执照—PAGCOR（Philippine Amusement and Gaming Corporation）PAGCOR是菲律宾有合法经营权的权威组织。',
    p10: 'PAGCOR证书',

    h1: '瓦伦西亚俱乐部',
    h2: '多特蒙德俱乐部',
    h3: '品牌介绍',

    h4:'2023年5月，BA88体育平台和多特蒙德俱乐部达成战略合作，本次合作是BA88体育迈向国际化的一大步，也是年轻的BA88体育平台与西甲豪门的一次激情合作。我们的合作目标是提高BA88体育品牌效应并在全球范围内推广西甲联赛。当一支新锐力量致力于打造一流产品、与顶尖合作伙伴、塑造杰出品牌形象，它已准备好向上攀升，主宰未来。相信双方此次的合作定会让彼此的前途更加坦荡无阻、一片光明。',
    h5:"多特蒙德足球俱乐部，成立于1919年3月18日，位于西班牙的第三大城市多特蒙德，现参加西班牙足球甲级联赛，多特蒙德是一支有着悠久历史的老牌球队。多特蒙德是欧洲闻名的劲旅之一，绝大部分时间都是在西甲中度过的，自从1930/31赛季夺得西乙冠军升上西甲之后，长时间参加西甲联赛，直到1985/86赛季意外降级，其后即以西乙冠军身份重返西甲，自此球队一直参加西甲联赛。60年代为球队最光辉的时刻，1961/62第四届国际城市博览会杯，球队攻入33个进球，创造了国际城市博览会杯比赛的进球最高纪录，当中包括8强10:3狂胜布达佩斯MTK，决赛7:3横扫巴塞罗那。第二年，蝉联了国际城市博览会杯的冠军。",
    d55:'2012年多特蒙德再次获得德甲冠军，并以81分成为德甲史上最高分的冠军。同时也在德国足协杯决赛以5:2击败拜仁慕尼黑夺冠，是俱乐部历史上首次成为国内双冠王。',

    vt1:'活动现场',
    vt2:'瓦伦西亚俱乐部',

    vt3:'媒体报道',
    vt4:'精彩瞬间',
    vt5:'球星闪耀',
    vt6:'业界最佳',
    vt7:'球队成就',
    des1:'亚洲区独家合作伙伴',
    des2:'官方合作伙伴',

    vp3: '<b>我们非常高兴地宣布，PP88与瓦伦西亚俱乐部达成了战略合作协议。</b><br/>作为全球知名的体育品牌，PP88一直以来致力于提供最优质的产品和服务，为运动员和球迷们创造无限可能。我们相信，PP88与瓦伦西亚俱乐部的合作将为足球界带来新的突破和机遇。共同为球迷们创造更加激动人心的体验，为足球运动的发展贡献我们的力量。PP88与瓦伦西亚俱乐部，共创辉煌！',
    vp4: '瓦伦西亚足球俱乐部，成立于1919年3月18日，现参加西班牙足球甲级联赛，瓦伦西亚是一支有着悠久历史的老牌球队。自从1930/31赛季夺得西乙冠军升上西甲之后，长时间参加西甲联赛，60年代为球队最光辉的时刻，1961/62第四届国际城市博览会杯，球队攻入33个进球，创造了国际城市博览会杯比赛的进球最高纪录，当中包括8强10:3狂胜布达佩斯MTK，决赛7:3横扫巴塞罗那。第二年，蝉联了国际城市博览会杯的冠军。',

    hp4:'2023年5月，BA88体育平台和多特蒙德俱乐部达成战略合作。我们的合作目标是提高BA88体育品牌效应并在全球范围内推广西甲联赛。当一支新锐力量致力于打造一流产品、与顶尖合作伙伴。相信双方此次的合作定会让彼此的前途更加坦荡无阻、一片光明。',
    hp5:"多特蒙德足球俱乐部，位于德国北莱茵-威斯特法伦州多特蒙德市的著名足球俱乐部，绰号「大黄蜂」。二战后崛起的多特蒙德和同处北威州鲁尔工业区（鲁尔区）的沙尔克04之间的比赛因紧邻的地理位置、相同的工人阶层文化而火爆异常，被称为「鲁尔区德比（鲁尔德比、矿区德比）」；球队在国内近些年成绩非常好，和拜仁慕尼黑之间的比赛也被不少球迷称作「德国国家德比」。多特蒙德曾获得1次欧洲冠军联赛冠军，1次欧洲优胜者杯冠军，1次丰田杯冠军和8次德国顶级联赛冠军。",
    vt0:'品牌升级',
    vd2:'多特蒙德俱乐部',
    hp6:'PP88品牌从BA88升级而来<br/>延承其精髓，融入更多创新，为您带来全新体验。',

    mobile1:'PP88集团',
    mobile2:'首页',
    mobile3:'平台概述',
    mobile4:'赞助',
    mobile5:'公益慈善',
    mobile6:'优惠奖励',
    mobile7:'游戏种类',
    mobile8:'责任博彩',
    mobile9:'用户体验',

    pc3:'平台概述',
    pc4:'赞助',
    pc5:'公益慈善',
    pc6:'优惠奖励',
    pc7:'游戏种类',
    pc8:'责任博彩',
    pc9:'用户体验',

    home1:'PP88体育拥有颁发的合法执照，是受国际行业协会认可的合法公司。进行注册并娱乐前，请确保您年满18周岁！',
    home2:'版权所有 ©2020-2023 PP88保留所有权',
    home3:'PP88公益送福利',
    home4:'社会贡献 公益活动',
    home6:'成长历程  崛起之路',
    home7:'PP88娱乐集团',
    home8:'PP88是最值得信賴的在線娛樂城，在亞洲地區擁有数百萬用戶。不僅在香港，我們也被認為是亞洲頂級的在線賭場之一。加入我們的在線博彩網站非常簡單，只需在網站上註冊賬號，即可開始享受您喜愛的在線賭場遊戲。',
    home10:'BA88品牌在過去已經贏得了廣大用戶的喜愛和信任，它的成就不僅體現在市場表現上，也體現在其高質量的合作夥伴關係上。 BA88曾是多特蒙德俱樂部在亞太地區的讚助商，這顯然反映了該品牌的廣泛影響力和其在行業內的重要地位。',
    home11:'关于PP88',
    home12:'總的來說，NewCenturyGamingGroup通過其運營的PP88，BA88，OB88以及PP88SPORTS等品牌，為全球的用戶提供了無與倫比的博彩體驗，同時也充分展示了該公司的創新精神，以及其在追求用戶滿意度和業務成功方面的堅定決心。',
    home13:'多元游戏',
    home14:'多种游戏  体验顺畅',
    home15:'用户体验',
    home16:'服务优势  口碑巨佳',
    home17:'平均存款时间',
    home18:'合作支付平台',
    home19:'平均取款时间',
    home20:'合作游戏平台',
    home21:'极速存取转款',
    home22:'最新技术自主研发的财务处理系统，多种入款渠道，自动免转钱包，精简出款流程，真正做到极速存，取，转。',
    home23:'海量赛事种类',
    home24:'每天为您提供足球、篮球、电竞、网球、斯诺克等千场精彩体育赛事，更有真人、彩票、棋牌、电子游戏等多种娱乐方式可供选择。',
    home25:'加密安全管理',
    home26:'独家开发，采用128位加密技术和严格的安全管理体系，多种智能防盗措施，会员账户24小时全方位防护，会员资金得到最为完善的保障。',
    home27:'多端口选择',
    home28:'自主研发了全套终端应用，更有精心打造的IOS，Android原生app，让您随时随地关注赛事热点，轻松休闲畅享娱乐投注。',
    home29:'用户评价',
    home30:'好评如潮 信誉良好',
    home31:'提款速度超级快',
    home32:'pp88的提款速度超级快，1分钟左右我的提款就到帐了，这是我在其它平台从来没有试过的，非常给力',
    home33:'无法想象的超高返水',
    home34:'pp88是我用过最好的平台！他们的返水是我接触过的平台最好的了。不愧是号称业界最高返水的平台！',
    home35:'pp88，值得信赖',
    home36:'我一直在玩ae的百家乐，开始还担心，后来我开了ae官网对比pp88的结果，全部一样的，其他对比也是，公平公正值得信任。',
    home37:'快速响应的客户服务值得称赞',
    home38:'上次我的账户出了一些问题，联系上他们的客服后，问题在短时间内就得到了解决。实打实的5星好評!',
    home39:'一定要转来pp88！',
    home40:'真的很开心转到pp88这个平台上，赔率高，奖励又丰富。眼见为实，一定要亲自体验！',
    home41:'他们让我感觉自己是真正的vip!',
    home42:'他们为vip提供了远超期望的优质服务，还不时给予独家奖励和福利。我也就此成为了pp88的忠实会员！',
    home43:'意想不到的惊喜',
    home44:'那天我玩slot中了个大奖，但我不知道有一个活动可以加领88.88%的奖金，pp88发站内短信通知我，我爱你！',
    home45:'你无法想象的高赔率！',
    home46:'pp88是我试过最好的平台！他们有着最高的赔付率和丰厚的奖励。',
    home47:'我首选的加密货币赌场',
    home48:'我可以在pp88上很顺利的存入和提取加密货币。其他平台并没有加密货币这个选项。',
    home49:'百玩不厌',
    home50:'pp88是所有平台中提供最多游戏选择的！体育博彩、真人赌场、电子游戏应有尽有。pp88让我百玩不厌。',
    home51:'关注我们',
    home52:'下乡送慈善物资',
    home53:'慈善公益现场',
    home54:'慈善受助方负责人',
    home55:'赠予慈善物资',
    home56:'慰问儿童',
    home57:'与受助方住持合影',
    home58:'慰问贫困人员',
    home59:'赠予公益物资',
    home60:'员工与留守儿童合影',
    home61:'PP88集团员工合影',
    home62: "行业最高赔率体育集结，赛事全覆盖，让球、大/小、15分钟进球、波胆/反波胆、独赢等玩法一应俱全；创新功能提前兑现，无需等待即可提前结算，止损机制让您百无禁忌；操作简单，开盘最快，秒速接单，最快结算，让您时刻享受激情！",
    home63: "全网最美荷官，各国佳丽倾情出演，独创娱乐&专业双模式，极致高清视觉盛宴，特色游戏种类：劲舞百家乐、VIP包桌、咪牌百家乐、美女主播等在线陪玩，多国语言任意切换，陪您共赴辉煌，使您流连忘返！",
    home64: "热门电竞场馆每日独家大量赛事滚球盘口，玩法多元、结算快捷，盘内赛事直播让您分秒精彩；具体赛事分析、数据统计、实时更新的战况记录，使您运筹帷幄之中决胜千里之外；更有小游戏独特玩法，激情不间断，比赛之余小憩更精彩！",
    home65: "热门电竞场馆每日独家大量赛事滚球盘口，玩法多元、结算快捷，盘内赛事直播让您分秒精彩；具体赛事分析、数据统计、实时更新的战况记录，使您运筹帷幄之中决胜千里之外；更有小游戏独特玩法，激情不间断，比赛之余小憩更精彩！",
    home66: "海量老虎机游戏汇聚，坚持打造精品；游戏采取精妙设计，创规则新玩法，超高爆率倍数，免费旋转，多重累积奖金，精美的3D画质，为您提供卓越极致的电子游戏体验！",
    home67: "多款捕鱼游戏极具特色，用于创新，3D动态场景炫酷技能，最高600倍赔率可4人同屏竞技，智能挂机自动锁定目标鱼类，为您打造卓越极致的代入感和身临其境的游戏体验！",
    home68: "多元化棋牌首创娱乐休闲新玩法在线抢红包，玩法新颖；抢庄牛牛、龙虎斗、21点、麻将、万人、电游、捕鱼等应有尽有，多款棋牌任君选、棋牌彰显胸怀，使您乐在其中！",
    home78: "创新玩法，超高赔率，快乐彩大小升跌指数，时时彩大小对吃，极速开彩结算，令您全身心的投入特色玩法，尽情体验每60秒/90秒开彩乐趣！",
    home69: 'APP下载',
    home70: "独家原生 支持全端",
    home71: "扫码下载APP iOS & IOS",
    home72: "现在注册以获得无限奖励、优惠和奖品。",
    home73: "合作伙伴",

    home74: "参加活动人员留影",
    home75: "送福利，送爱心",
    home76: "工作人员留影",
    home77: "发放慈善物资",

    platform1:'详情',
    platform2:'PP88是最值得信賴的在線娛樂城，在亞洲地區擁有数百萬用戶。不僅在香港，我們也被認為是亞洲頂級的在線賭場之一。加入我們的在線博彩網站非常簡單，只需在網站上註冊賬號，即可開始享受您喜愛的在線賭場遊戲。\n\nPP88是亞洲地區特別是香港地區一家知名的博彩品牌，是New Century Gaming Group旗下的一個子品牌，New Century Gaming Group 是一家具有前瞻性的在線博彩公司，擁有廣泛的業務範圍和豐富的品牌組合，包括PP88，BA88，O以及PP88 SPORTS等，數百萬會員遍布全球。\n\n其中，PP88品牌是從BA88品牌升級而來的(訪問: https://BA88.COM 可直接進入PP88) ，這標誌著New Century Gaming Group不斷追求創新和優化產品的堅定決心。',
    platform3:'PP88品牌',
    platform4:'BA88品牌在過去已經贏得了廣大用戶的喜愛和信任，它的成就不僅體現在市場表現上，也體現在其高質量的合作夥伴關係上。 BA88曾是多特蒙德俱樂部在亞太地區的讚助商，這顯然反映了該品牌的廣泛影響力和其在行業內的重要地位。\n\n通過升級為PP88，我們看到了New Century Gaming Group 的新視野和新戰略。這種品牌升級不僅代表了其對市場動態的敏銳洞察，也代表了其在提供用戶體驗和服務上的決心。\n\nNew Century Gaming Group 運營的各大品牌，包括PP88，為用戶提供了全方位的在線博彩體驗，從體育博彩到虛擬體育，從賽馬到在線賭場，再到撲克等在線遊戲。其豐富的產品線和高質量的服務使得公司在競爭激烈的博彩市場中脫穎而出，得到了廣大用戶的認可。',
    platform5:'總的來說，NewCenturyGamingGroup通過其運營的PP88，BA88，OB88以及PP88SPORTS等品牌，為全球的用戶提供了無與倫比的博彩體驗，同時也充分展示了該公司的創新精神，以及其在追求用戶滿意度和業務成功方面的堅定決心。\n\nPP88是否合法？\n目前，PP88的總部設在菲律賓首都馬尼拉，這是全球賭博業的中心。同時，PP88也在該國政府的監管下運營，該博彩網站還被該國政府認可為一家值得信賴、質量高且可靠的遊戲場所。\n\nPP88援用菲律賓博彩執照—PAGCOR（PhilippineAmusementandGamingCorporation），PAGCOR是菲律賓有合法經營權的權威組織。\n\nPP88還與世界頂級的網絡安全和審計系統IOVATION合作，確保您的賬戶安全隱私和信息保密。\n\nPAGCOR的運營在亞洲區域始終保持前端，也將為廣大持牌者提供更好發展前景，同時為亞太地區的博彩業務與世界博彩市場接軌提供穩定的橋樑。',
    charity1:'这里有一个\n美好的世界！',
    charity2:'我们相信每个微笑都有\n其背后的故事。',
    charity3:'我们致力于做正确的事。我们致力于成为一股向上向善的力量。因此，PP88员工和品牌一直以来坚持天天向善。',
    charity4:'我们的社会贡献',
    charity5:'我们的目标是努力改善所触达社区的健康与福祉。',
    charity6:'救灾帮扶',
    charity7:'助医扶残',
    charity8:'安老助孤',
    charity9:'PP88捐赠行动覆盖范围超过15个国家/地区的人们，为养老院、避难所、社区团体、食物银行等机构组织提供了帮助。',
    charity10:'至今，已有超过10,000名儿童通过我们的项目受益，其中80%的儿童在学业上有显著进步。',
    charity11:'我们已经帮助了5,000多名老人改善了他们的生活质量，其中70%的老人表示感到更加快乐和有社交联系。',
    charity12:'作为一家企业，我们有责任回馈需要帮助的人们。',
    charity13:'我们的目标，为弱势群体带来持久的正面改变。',
    charity14:'在这个快速变化的世界里，我们的品牌不仅仅追求商业成功，更承担起社会责任，将爱心传递给每一个需要帮助的角落。我们相信，即使是最小的善举，也能点亮希望的火花。',
    charity15:'感谢我们的合作伙伴\n他们的支持让我们的梦想成为可能。',
    charity16:'亚洲区官方合作伙伴',
    charity17: "我们相信每个微笑都有其背后的故事。",
    charity18: "这里有一个美好的世界！",

    game1:'热门游戏',
    game2:'品类齐全  火爆推荐',
    game3:'PP88是专业提供体育电竞、真人娱乐、彩票投注等的正规博彩网站，为每一位用户提供最完善的服务和最快乐的时光是我们的诉求和责任。而我们也强烈希望玩家在游戏过程中保持娱乐心态，PP88将带给您极致的用户体验、超本土化的个性服务和颠覆性的博彩产品，让所有玩家在PP88酣畅淋漓。',
    game4:'体育',
    game5:'真人',
    game6:'电竞',
    game7:'老虎机',
    game8:'彩票',
    game9:'捕鱼',
    game10:'棋牌',
    resp1:'PP88 是一家在亞洲著名的在線投注平台，持有菲律賓博彩執照—PAGCOR（Philippine Amusement and Gaming Corporation），是菲律賓有合法經營權的權威組織。 PP88經過驗證並獲得國際在線投注協會的許可證，承諾確保客戶的個人信息安全和保密。',
    resp2:'負責任博彩理念|PP88信譽造就品牌',
    resp3:'PP88是一家亞洲知名且備受吸引力的在線遊戲平台。\n該平台始終將玩家的利益和權益放在首位，同時提供一個吸引人、公正和健康的博彩環境。PP88作為一家負責任的博彩平台，已經為該公司樹立了品牌形象。\nPP88的負責任博彩理念\n提及博彩這兩個詞，人們往往只會想到其負面的方面。然而，實際情況並非如此，只要我們知道如何正確對待博彩。博彩實際上只是一種娛樂遊戲，可以幫助您在繁忙的工作後放鬆身心。\n如果過度沉迷於博彩，您將陷入迷茫並成為一個賭博成癮者。這已經成為一個需要解決的社會問題。\n因此，為了提供負責任的博彩環境，PP88會提出一系列問題供玩家在體驗平台之前進行自我評估。以下是在體驗平台之前，您需要回答的問題：\n您的博彩活動是否佔用了過多的工作或學習時間？\n您是否曾因博彩而破產？\n您輸掉後的心理恢復程度如何？\n您是否曾經動用家庭所有財產進行博彩活動？\n您是否曾經對博彩金額進行虛報？\n如果您輸掉了大量資金並且無法承擔這些債務，您是否考慮過從事非法活動或作弊？\n如果您贏得了大筆金額，您是否感到貪婪並變得對博彩活動比工作或家庭更上癮？\n如果您曾有過對上述問題的思考，那麼您必須調整自己的心態、狀態和習慣。只有這樣，玩家才能意識到如何進行健康的博彩，不貪婪於勝利，不受挫於失敗。',
    resp4:'PP88負責任博彩規則',
    resp5:'PP88承諾始終自信並確保滿足早期設定的三項標準：誠實、透明和合法。此外，參與者需要積極合作並嚴格遵守由該平台設定的要求。\n為了建立一個負責任的博彩平台，PP88制定了以下規則：\n年齡限制：PP88的會員必須年滿18歲才能註冊並登錄賬戶以體驗遊戲。\n每個參與者只能創建一個遊戲賬戶進行投注。如果發現您有多個賬戶，平台將封鎖您的賬戶，並取消您之前的所有獲勝注單。\n在參與PP88負責任博彩過程中，玩家必須意識到保密個人信息的重要性，如賬戶名、登錄密碼、用戶名、關聯銀行賬戶等。這些信息被確定為不得向外界透露。\n絕對禁止欺詐行為。如果發現在博彩過程中作弊，平台有權拒絕登錄，並有權永久停用您的遊戲賬戶。\n平台保留拒絕登錄和參與所有已發布遊戲的權利，如果發現有欺騙行為或提供不准確的信息。\n在以下情況下，人為因素導致錯誤或系統遇到故障時，平台將作出最終決定。\n如果網站因客觀原因（如災害或黑客入侵）而發生故障，解決方案是玩家應始終備份數據以供跟踪、檢索和必要時投訴使用。\n如果不幸發生爭議，玩家應立即檢查自己的賬戶信息，最好在投注結束之前。如果發現異常情況，可以立即聯繫平台或客戶服務部門以解決問題。',
    resp6:'6條關於有責任的PP88投注的建議：',
    resp7:'在了解了PP88有責任的投注信息和規則之後，該平台還為玩家提供以下6個建議：\nPP88有責任的投注只是一種在線娛樂投注遊戲。玩家應該把它當作一種娛樂方式，絕對不要沉迷其中並立即摒棄贏回投注的念頭當輸掉投注時。\n有責任的PP88投注：玩家必須明確了解遊戲規則和玩法。此外，玩家可以向有經驗的人或專家尋求意見，以提高投注的勝率。\n有責任的PP88投注：尊敬的玩家們應該始終保持清醒和敏銳的頭腦，以減少損失。\n有責任的PP88投注：玩家必須有良好的資金管理策略。風險是不可避免的，但如何限制風險取決於玩家自己。\n有責任的PP88投注：將責任放在首位，限制參與投注的時間，以免影響工作和日常生活。\n有責任的PP88投注：玩家必須自我監督和管理時間與金錢，確保與個人收入相平衡。\n有責任的PP88投注絕對不建議玩家過度沉溺於在線遊戲，無論是PP88還是其他任何投注網站。通過確立有責任的投注環境，使得所有參與者都非常滿意並對該平台產生了信任感。',

    footer1: "本网站由Westward Way Tech N.V.公司运营，注册编号：168306，注册地址：Parkeerplaats Dienst Openbare Scholen  本网站经营牌照号：N.V.#1168/JAZ，由库拉索政府",
    footer2: "授权及监管的Gaming Service Provider颁发。本网站运营相关的管理、支付、及支持服务等事宜，均由正规合法公司提供。",
    footer3: "进行注册并娱乐前，请确保您年满18周岁！  博娱易上瘾，请注意节制。",
    footer4: " ©2023 PP88 版权所有"
}
