<template>
    <MobileApp v-if="isMobile()"></MobileApp>
    <PcApp v-else></PcApp>
</template>

<script>
import PcApp from "@/views/pc/PcApp.vue";
import MobileApp from "@/views/mobile/MobileApp.vue";

export default {
    name: 'App',
    components: {
        MobileApp,
        PcApp,
    },
    methods: {
        isMobile() {
            let flag = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
            return flag;
        }
    }
}
</script>

<style>
</style>
