<template>
  <div>
    <template v-for="(value,key) in dataList" :key="key">
      <home-title :title="value.title" :desc="value.desc" class="pt-14px"></home-title>
      <template v-if="key === 'part_hot'">
        <div class="flex overflow-auto w-full">
          <div v-for="(banner_item,index) in value.dataList" :key="index" class="text-center mr-6px">
            <img :src="banner_item.img" class="h-125px"/>
          </div>
        </div>
        <div class="bg-#1B1E2F text-#97A7B6 my-10px px-20px py-10px text-12px rounded-10px">
          {{ value.content }}
        </div>
      </template>
      <template v-else-if="key === 'part_game'">
        <game-nav @change="(imgIndex) => changeTo(imgIndex)"></game-nav>
        <van-swipe ref="mySwiper" :show-indicators="false">
          <van-swipe-item v-for="(sub_item,index) in value.dataList" :key="index">
            <img :src="sub_item.img" class="w-full"/>
          </van-swipe-item>
        </van-swipe>
      </template>
    </template>
  </div>
</template>
<script setup>
import GameNav from "@/views/mobile/GameNav.vue";
import {Swipe as VanSwipe, SwipeItem as VanSwipeItem} from 'vant'
import {computed, ref} from "vue";
import HomeTitle from "@/views/mobile/HomeTitle.vue";
import {useI18n} from "vue-i18n";
const {t} = useI18n()
const mySwiper = ref()
const dataList = computed(()=>{
  return {
    part_game: {
      title: t('home13'),
      desc: t('home14'),
      dataList: [
        {name: 'xxx', img: require('@/assets/images/mobile/game/game_sport_banner.webp')},
        {name: 'xxx', img: require('@/assets/images/mobile/game/game_casino_banner.webp')},
        {name: 'xxx', img: require('@/assets/images/mobile/game/game_esport_banner.webp')},
        {name: 'xxx', img: require('@/assets/images/mobile/game/game_slot_banner.webp')},
        {name: 'xxx', img: require('@/assets/images/mobile/game/game_lottery_banner.webp')},
        {name: 'xxx', img: require('@/assets/images/mobile/game/game_fish_banner.webp')},
        {name: 'xxx', img: require('@/assets/images/mobile/game/game_chess_banner.webp')},
      ]
    },
    part_hot: {
      title: t('game1'),
      desc: t('game2'),
      content: t('game3'),
      dataList: [
        {name: 'xxx', img: require('@/assets/images/mobile/game/hot1.png')},
        {name: 'xxx', img: require('@/assets/images/mobile/game/hot2.png')},
        {name: 'xxx', img: require('@/assets/images/mobile/game/hot3.png')},
        {name: 'xxx', img: require('@/assets/images/mobile/game/hot4.png')},
        {name: 'xxx', img: require('@/assets/images/mobile/game/hot5.png')},
        {name: 'xxx', img: require('@/assets/images/mobile/game/hot6.png')},
        {name: 'xxx', img: require('@/assets/images/mobile/game/hot7.png')},
        {name: 'xxx', img: require('@/assets/images/mobile/game/hot8.png')},
        {name: 'xxx', img: require('@/assets/images/mobile/game/hot9.png')},
        {name: 'xxx', img: require('@/assets/images/mobile/game/hot10.png')},
        {name: 'xxx', img: require('@/assets/images/mobile/game/hot11.png')},
        {name: 'xxx', img: require('@/assets/images/mobile/game/hot12.png')},
        {name: 'xxx', img: require('@/assets/images/mobile/game/hot13.png')},
        {name: 'xxx', img: require('@/assets/images/mobile/game/hot14.png')},
        {name: 'xxx', img: require('@/assets/images/mobile/game/hot15.png')},
        {name: 'xxx', img: require('@/assets/images/mobile/game/hot16.png')},
        {name: 'xxx', img: require('@/assets/images/mobile/game/hot17.png')},
        {name: 'xxx', img: require('@/assets/images/mobile/game/hot18.png')},
        {name: 'xxx', img: require('@/assets/images/mobile/game/hot19.png')},
        {name: 'xxx', img: require('@/assets/images/mobile/game/hot20.png')},
      ]
    }
  }
})


function changeTo(index) {
  mySwiper.value[0].swipeTo(index)
}
</script>
