<template>
    <div class="flex">
        <div class="flex justify-around relative">
            <video 
                :key="activeVideo.poster"
                :src="activeVideo.video" 
                :poster="activeVideo.poster" 
                playsinline
                preload="metadata" 
                :autoplay="true" 
                :muted="true"
                controls 
                class="w-866px b-rd-18px mr-40px">
            </video>
            <div class="video-list">
                <div v-for="(item, index) in videoList" :key="index" class="mb-16px relative" :class="{'active': activeVideo.value === item.value}" @click="activeVideo = item">
                    <img :src="item.poster" class="w-100%">
                    <div class="play"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { ref } from 'vue';

const videoList = ref([
    {
        poster: require('@/assets/images/pc/charity/charity-16.webp'),
        video: 'https://pic.li3zq.com/video/public-spirited2.mp4',
        value: '1'
    },
    {
        poster: require('@/assets/images/pc/charity/charity-24.webp'),
        video: 'https://pic.li3zq.com/video/public-spirited3.mp4',
        value: '2'
    },
    {
        poster: require('@/assets/images/pc/charity/charity-15.webp'),
        video: 'https://pic.li3zq.com/video/public-spirited1.mp4',
        value: '3'
    },
])

const videoNum = ref(0)
const activeVideo = ref(videoList.value[videoNum.value])
</script>

<style lang="less" scoped>
.video-list{
    width: 266px;
}
.video-list > div{
    cursor: pointer;
    position: relative;
    &.active::after,
    &:hover::after{
        content: '';
        width: calc(100% - 2px);
        height: calc(100% - 6px);
        border: 2px solid #FFB25A;
        border-radius: 6px;
        position: absolute;
        top: 0;
        left: 0;
    }
    &::before{
        content: '';
        width: 36px;
        height: 36px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-image: url(@/assets/images/pc/icon-play.svg);
    }
    &.active::before,
    &:hover::before{
        background-image: url(@/assets/images/pc/icon-play-hover.svg);
    }

}
.video-list > div:last-child{
    margin-bottom: 0;
}

.play{
    width: 36px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>
  