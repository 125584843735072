<template>
    <Title :title="$t('game1')" :subTitle="$t('game2')" class="mt-54px" />

    <swiper
        :slidesPerView="8"
        :spaceBetween="30"
        :navigation="true"
        :pagination="{
            clickable: true,
        }"
        :modules="modules"
        class="mySwiper hotGame-swiper">
        <swiper-slide v-for="item in 20" :key="item">
            <img class="w-146px" :src="require('@/assets/images/pc/game/game-' + item + '.webp')" >
        </swiper-slide>
    </swiper>
</template>

<script setup>
import Title from '../../components/title.vue'
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

const modules = [Navigation];
</script>

<style lang="less" scoped>
@import '../../style/mySwiper.less';

.hotGame-swiper{
    padding-top: 40px;
}
.hotGame-swiper :deep(.swiper-button-next), 
.hotGame-swiper :deep(.swiper-button-prev){
    top: 24px;
}
</style>