<template>
  <van-nav-bar v-if="!hiddenTitle" :title="t('mobile1')" :left-arrow="urlParams.from === 'mobile'" @click-left="goBack">
    <template #right>
      <lang-item></lang-item>
    </template>
  </van-nav-bar>
  <div class="content bg-#131524">
    <van-tabs v-model:active="activeTab" line-width="10vw">
      <van-tab v-for="(item,index) in dataList" :key="index" :name="item.name" :title="item.title"></van-tab>
    </van-tabs>
    <component :is="realActiveTab.component" :class="realActiveTab.class"
               class="max-h-[calc(100%-44px)] overflow-auto"/>
  </div>
</template>

<script setup>
import 'vant/lib/index.css';
import {NavBar as VanNavBar, Tab as VanTab, Tabs as VanTabs} from "vant";
import VanlenciaTeam from "@/views/mobile/VanlenciaTeam.vue";
import qs from "qs";
import {computed, onMounted, ref} from "vue";
import FirstPage from "@/views/mobile/FirstPage.vue";
import PlatformPage from "@/views/mobile/PlatformPage.vue";
import DiscountPage from "@/views/mobile/DiscountPage.vue";
import GamePage from "@/views/mobile/GamePage.vue";
import ServePage from "@/views/mobile/ServePage.vue";
import ResponsePage from "@/views/mobile/ResponsePage.vue";
import CharityPage from "@/views/mobile/CharityPage.vue";
import LangItem from "@/views/mobile/LangItem.vue";
import {useI18n} from "vue-i18n";

const activeTab = ref('home')
const realActiveTab = computed(() => {
  return dataList.value.find((e) => e.name === activeTab.value)
})
const hiddenTitle = ref(false)
const {t} = useI18n()
const dataList = computed(() => {
  let dynamic = []
  if (urlParams.value?.from !== 'mobile') {
    dynamic.push({name: 'discount', title: t('mobile6'), component: DiscountPage, class: 'px-13px'})
  }
  return [
    {name: 'home', title: t('mobile2'), component: FirstPage, class: 'px-13px'},
    {name: 'platform', title: t('mobile3'), component: PlatformPage, class: 'px-13px'},
    {name: 'sponsor', title: t('mobile4'), component: VanlenciaTeam, class: 'ba-detail dortmund'},
    {name: 'charity', title: t('mobile5'), component: CharityPage, class: ''},
    ...dynamic,
    {name: 'game', title: t('mobile7'), component: GamePage, class: 'px-13px'},
    {name: 'response', title: t('mobile8'), component: ResponsePage, class: 'px-13px'},
    {name: 'serve', title: t('mobile9'), component: ServePage, class: 'px-13px'},
  ]
})

function goBack() {
  window.history.back();
}

const urlParams = ref('')
onMounted(() => {
  urlParams.value = qs.parse(window.location.search.substring(1));
  if (urlParams.value.app) {
    hiddenTitle.value = true;
  }
  if (urlParams.value.target) {
    activeTab.value = urlParams.value.target
  }
});

// function getUrlParams3(url) {
//   // \w+ 表示匹配至少一个(数字、字母及下划线), [\u4e00-\u9fa5]+ 表示匹配至少一个中文字符
//   let pattern = /(\w+|[\u4e00-\u9fa5]+)=(\w+|[\u4e00-\u9fa5]+)/ig;
//   let result = {};
//   url.replace(pattern, ($, $1, $2) => {
//     result[$1] = $2;
//   })
//   return result
// }
</script>
<style scoped>
:deep .van-nav-bar__content .van-icon {
  color: #FFFFFF !important;
}

:deep .van-swipe__indicator {
  background-color: #747ca7
}
</style>
<style>
@import "@/assets/css/style.css";

:root {
  --color-black: #1b1e2f;
  --color-gray: #9faab4;
  --color-gray_2: #494b59;
  --color-gray_3: #97a7b6;
  --color-active: #d2943a;
  --color-black_2: #131524;
  --color-white: #ffffff;
  --van-nav-bar-title-text-color: #97A7B6;
  --van-nav-bar-background: #1b1e2f;
  --van-border-color: #1b1e2f;
  --van-tabs-nav-background: #1b1e2f;
  --van-tab-active-text-color: #d2943a;
  --van-tabs-bottom-bar-color: #d2943a;
  --van-tab-font-size: 3.3vw;
  --van-nav-bar-height: 44px;
  --van-tab-text-color: #97a7b6;
  --van-tabs-bottom-bar-width: 60px;
  --van-tabs-bottom-bar-height: 2px;
  --van-popover-dark-background: #2b2f46;
  --van-gray-7: #393e5b;
}

html {
  overflow: hidden;
}

html,
body {
  //font-family: 'PingfangBold'; height: 100%;
}

#app {
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

@font-face {
  //font-family: "PingfangBold";
  //src: url("@/assets/font/PingFangBold.woff2");
}
</style>
<style scoped>
.top {
  position: fixed;
  top: 0;
  z-index: 1;
  padding-top: 2.64vw;
  width: 100%;
  height: 22.836vw;
  background: var(--color-black);
  box-sizing: border-box;
  overflow: hidden;
}

.top.noTitle {
  padding-top: 1.64vw;
  height: 12.836vw;
}

.top.noTitle .title {
  display: none;
}

.top.noTitle .back {
  display: none;
}

.top.noTitle .tabs {
  margin-top: 1.808vw;
}

.top .title {
  color: var(--color-gray);
  font-size: 4.224vw;
  text-align: center;
}

.top .back {
  position: absolute;
  top: 3vw;
  padding-left: 4vw;
  width: 12vw;
  height: 12vw;
}

.top .tabs {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 5.808vw;
  width: 100%;
  box-sizing: border-box;
}

.top .tabs .tab {
  flex-shrink: 0;
  position: relative;
  color: var(--color-gray);
  font-size: 3.3vw;
}

.top .tabs .tab.active {
  color: var(--color-active);
  font-weight: bold;
}

.top .tabs .tab.active:after {
  content: ' ';
  position: absolute;
  bottom: -2.64vw;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
  height: 0.528vw;
  border-radius: 0.528vw;
  background: var(--color-active);
}

.content {
  height: calc(100% - 44px);
  overflow: auto
}

.content.noTitle {
  height: calc(100% - 12.836vw);
  overflow: auto
}

img[lazy="loading"] {
  height: 1px;
}

.item-new-bottom {
  margin-top: 8px;
  display: flex;
  justify-content: center;
}

.item-new-bottom img {
  width: 80%;
}

</style>
